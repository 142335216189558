.edit-link-dialog-overlay {
  z-index: 2000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}

.edit-link-dialog {
  width: 100%;
  height: 100%;
}

.edit-link-dialog-content {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 10px 15px 0 #00000029;
  -moz-box-shadow: 0 10px 15px 0 #00000029;
  box-shadow: 0 10px 15px 0 #00000029;
  border-radius: 0.625rem;
  overflow: hidden;

  .dialog-content {
    background: #F8F8F8;
    padding: 20px 30px;
    height: calc(100% - 24px);

    .round-button {
      @extend .h3, .bold, .shadow-1, .bg-white, .mh3, .flex, .items-center, .justify-center;
      min-width: 110px;
    }

    input, input:hover, input:focus {
      border: none;
    }

    .input-value {
      width: 3.5rem;
      height: 1.75rem;
      border-radius: 1rem;
      -webkit-appearance: none;
      -webkit-box-shadow: 0 3px 6px 0 #00000029;
      -moz-box-shadow: 0 3px 6px 0 #00000029;
      box-shadow: 0 3px 6px 0 #00000029;
      background: white;

      input {
        width: 100%;
        height: 100%;
        font-size: 0.625rem;
        color: #070025;
        min-width: unset;
        background: none;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .edit-link-dialog {
    .overlay {
      background: #00000029 !important;
    }
  }
  .edit-link-dialog-content {
    p {
      font-size: 10px;
      line-height: 1.4;
    }

    .dialog-content {
      padding: 10px 20px;

      >div:first-child {
        flex-direction: column;
        p {
          margin-bottom: 0.5rem;
        }
      }

      >div:last-child {
        margin-top: 0.5rem;
      }

      .button {
        height: 20px;
        line-height: 20px;
        font-size: 9px;
        min-width: 80px;
        padding: 0 10px !important;
      }

      .input-value {
        height: 20px;
        width: 100%;

        input {
          min-height: 28px;
        }
      }
    }
  }
}