.wizard-overlay {
  @extend .absolute, .absolute--fill, .pointer-none;
  z-index: 1;
}

.wizard {
  @extend .h-100, .w-100;

  .command-input {
    @extend .relative, .overflow-hidden, .h3, .flex, .items-center, .bw1, .b--solid, .b-gray, .bl-0, .br-0, .bb-0;

    input {
      @extend .bw0, .bg-none, .w-100, .focus-outline-0, .f9;
    }

    img {
      @extend .absolute, .right-0, .top-0, .bottom-0, .ma-auto, .z-1;
    }
  }

  img {
    @extend .pointer-none;
  }
}

.minimized-wizard {
  @extend .absolute, .left-1, .bottom-1, .bg-white, .br-pill, .bw1, .b--solid, .b-gray, .z-1, .pv3, .ph5, .semi-bold;

  img {
    @extend .mr4;
  }
}

.wizard-rnd {
  @extend .fixed, .top-0, .left-0, .pointer-auto, .flex, .flex-column, .z-1;
  border-radius: 20px;
  background: #EEECFF;


  &.wizard-tip {
    @extend .bg-white, .bw1, .b--solid, .b-gray, .br4;

    .wizard-header {
      @extend .pl1;

      > div:first-child {
        width: 36px;
        height: 36px;
        border-radius: 9999px !important;
        border-width: 1px !important;

        > img:last-child {
          @extend .dn;
        }
      }

      > div:nth-child(2) {
        @extend .black, .bold;

        > img {
          opacity: 0.1;
        }
      }
    }

    .wizard-content {
      @extend .bw0;
    }
  }

  &.wizard-hide-tip-header {
    background: none;
  }

  &.wizard-hide-header {

    .wizard-content {
      @extend .br4, .h-100;
    }
  }
}

.wizard-header {
  @extend .flex, .items-center, .w-100;
  height: 40px;

  > div:first-child {
    @extend .relative, .bw1, .b--solid, .b-gray, .br-pill, .bb-0, .br--top, .bg-white, .h-100, .flex, .items-center, .justify-center, .z-1, .pointer;
    transform: translate(0, 1px);
    width: 40px;

    > img:last-child {
      @extend .absolute, .bottom-0;
      right: -20px;
    }
  }

  > div:nth-child(2) {
    @extend .flex-grow-1, .f7, .semi-bold, .flex, .justify-between, .items-center, .ph3, .h-100, .blue, .pointer;
    font-family: Poppins, sans-serif;
  }
}

.wizard-content {
  @extend .w-100, .flex, .flex-column, .justify-between, .relative, .bg-white, .bw1, .b--solid, .b-gray, .br4, .ph4, .pt4, .pb2;
  font-family: 'Open Sans', sans-serif;
  border-top-left-radius: 0;
  height: calc(100% - 40px);

  .hint {
    @extend .relative, .pv4, .ph1;
  }

  .title {
    @extend .f7, .semi-bold, .mv2;
    font-family: Poppins, sans-serif;
  }

  .play-button {
    @extend .br-pill;

    img {
      min-width: 28px;
      min-height: 28px;
    }
  }

  .angle {
    @extend .br-pill, .bg-blue-20, .f10, .absolute, .top-0, .bottom-0, .ma-auto, .h2, .w3, .flex, .justify-center, .items-center;
    left: 10rem;
  }

  .gumball-actions {
    @extend .flex, .flex-wrap, .justify-center, .items-center, .b-gray, .b--solid, .bw1, .bt-0, .bl-0, .br-0, .pb3;

    .image-button:not(.digital-material) {
      @extend .ph2, .pv1;
    }

    .image-button.digital-material {
      @extend .mh2, .mv1;
    }
  }

  .color-value-preview {
    @extend .br2;
    min-width: 110px;
    min-height: 110px;
    max-width: 110px;
    max-height: 110px;
  }

  .backdrop-thumbnail {
    @extend .fit-cover, .br2, .bw1, .b--solid, .b-gray, .overflow-hidden;
    max-width: 260px;
    min-width: 260px;
    max-height: 150px;
    min-height: 150px;
  }

  .env-map-type-combo-box {
    @extend .bg-white, .w-40;

    > .options {
      @extend .bg-white;
      height: 4.5rem;
    }
  }

  .backdrop-image-combo-box {
    @extend .bg-white, .w-60;

    > .options {
      @extend .bg-white;
      height: 6rem;
    }
  }

  .file-upload {
    width: 100%;
  }

  .screenshot-thumbnail {
    @extend .button, .br3, .relative, .overflow-hidden;
    width: 8rem;
    height: 6rem;

    > img:first-child {
      @extend .absolute, .absolute--fill, .ma-auto, .z-2, .dn;
    }

    > img:nth-child(2) {
      @extend .absolute, .absolute--fill, .ma-auto, .fit-cover;
    }

    &:hover {
      > img:first-child {
        @extend .db;
      }
    }
  }

  .material-options {
    @extend .flex, .items-center;

    > p {
      @extend .gray, .mh3;
    }

    button {
      @extend .button, .f10, .bg-none, .pa0, .gray;

      &.active {
        @extend .black;
      }
    }
  }

  .crash-warning {
    @extend .br-pill, .w-100, .pa2, .flex, .justify-center, .relative;
    background: rgba(255, 136, 136, 0.2);

    img:first-child {
      @extend .absolute, .left-1, .top-0, .bottom-0, .ma-auto;
    }

    img:last-child {
      @extend .absolute, .right-1, .top-0, .bottom-0, .ma-auto;
    }
  }

  .check-box {
    @extend .button, .bg-none, .flex, .items-center, .pa0;

    > div:first-child {
      @extend .h1, .w1, .br2, .bw1, .b--solid, .b-black, .mr2;
    }

    &.checked {
      > div:first-child {
        @extend .relative;

        > div {
          @extend .absolute, .bg-blue, .br1;
          left: 2px;
          right: 2px;
          top: 2px;
          bottom: 2px;
        }
      }
    }
  }

  .parameter-settings {
    @extend .flex, .flex-column, .items-start;

    > * {
      @extend .mb2;
    }
  }

  .axis-button {
    @extend .button, .w3, .h2, .br-pill, .bg-gray-60, .mh1;

    &.active {
      @extend .bw1, .b--solid, .b-blue;
    }

    &.x-axis {
      background: #FFD3D3CC;
    }

    &.y-axis {
      background: #B5FFF2;
    }

    &.z-axis {
      background: #EACFFF;
    }
  }

  .digital-material-button {
    @extend .flex, .flex-column, .items-center, .br-pill, .bw1, .b--solid, .b-black;

    > img {
      @extend .br-pill;

      width: 40px;
      height: 40px;
      transform: scale(1.35);
    }

    &:hover {
      @extend .b-blue;
    }
  }

  .error {
    @extend .b-red, .bw1, .b--solid;
  }

  .symmetry-toggle {
    .on-button {
      min-width: 52px;
    }
    .off-button {
      min-width: 40px;
    }
  }

  .wizard-section {
    @extend .flex, .flex-column, .bw1, .b--solid, .b-black-40, .br-0, .bl-0, .bb-0;
  }
}

.wizard-button {
  @extend .bg-none, .br3, .bw1, .focus-outline-0, .semi-bold, .pointer, .b-black, .f9, .pv1, .ph3;

  &:hover {
    @extend .b-blue, .blue;
  }

  img {
    @extend .mr2;
  }
}

.wizard-link {
  @extend .bg-none, .br3, .bw1, .focus-outline-0, .semi-bold, .pointer, .b-black, .f9, .pv1, .ph3;

  &:hover {
    @extend .b-blue, .blue;
  }
}

.wizard-sub-link {
  @extend .f9, .pv1, .ph3, .br-pill, .bw1, .focus-outline-0, .bg-white, .semi-bold, .pointer, .b-black;

  &:hover, &.active {
    @extend .blue, .b-blue;
  }
}

.wizard-link-button {
  @extend .underline, .button, .semi-bold, .flex, .items-center, .justify-center, .bg-none, .blue;
}

.wizard-check-group {
  @extend .b-blue, .flex, .justify-center, .items-center, .bw1, .b--solid, .b-blue, .br-pill;
  padding: 1px;

  &.disabled {
    @extend .b-gray;
  }
}

.wizard-check {
  @extend .wizard-link, .br-pill;
  border-width: 0 !important;

  &:hover {
    @extend .bg-blue-20;
  }

  &.checked {
    @extend .bg-blue, .white;

    &:hover {
      @extend .bg-blue-80;
    }
  }

  &.disabled {
    @extend .pointer-none, .black-20;
  }
}

.digital-material {
  @extend .w1, .h1, .br-pill, .bw1, .b--solid, .pa0;
  border-color: #7E8BFF;

  img {
    transform: scale(1.3);
  }
}