.shared-root {
  @extend .vh-100, .w-100, .relative;

  .form-text-input {
    width: 20rem;

    > div {
      @extend .b-gray;
    }
  }

  .shared-control {
    @extend .right-0, .top-0, .z-2, .fixed, .br5, .br--bottom, .br--left, .bw1, .b--solid, .b-gray, .bt-0, .br-0, .flex, .items-center, .bg-white;

    button {
      @extend .button, .bg-none, .pa0, .w2, .h2, .ma2;
    }
  }

  .login-popup {
    @extend .fixed, .right-0, .left-0, .bottom-2, .ma-auto, .overflow-hidden, .br-pill, .bg-white, .pv5, .ph7, .flex, .justify-center, .items-center, .shadow-2, .f10;
    opacity: 0.75;
    width: 30rem;
  }
}