.sculpt-panel {
  @extend .relative, .h-100, .bg-white, .z-2, .flex, .justify-center;


  .tool-buttons {
    @extend .flex;
  }

  .tool-button {
    @extend .button, .pa0, .bg-none, .flex, .flex-column, .items-center;
    width: 60px;

    > div {
      > img {
        @extend .fit-cover, .bw3, .b--solid, .b-transparent, .br-pill;
        min-width: 2rem;
        min-height: 2rem;
        max-width: 2rem;
        max-height: 2rem;
      }
    }

    > p {
      @extend .f10;
    }

    &.selected {
      img {
        @extend .b-blue;
      }
    }
  }
}