.hero-footer {
  @extend .w-90, .flex, .justify-between, .items-start, .z-1, .ph4, .pt9, .pb11, .bw1, .b--solid, .b-black, .bb-0, .bl-0, .br-0;
  max-width: 80rem;
  font-family: 'Open Sans', sans-serif;

  @extend .flex-column-reverse-m, .flex-column-reverse-ns, .items-center-m, .items-center-ns;

  a {
    @extend .no-underline, .black;
  }
}

@media only screen and (max-width: 60em) {
  .hero-footer {
    :nth-child(1) {
      order: 1;
    }

    :nth-child(2) {
      order: 3;
    }

    :nth-child(3) {
      order: 4;
    }

    :nth-child(4) {
      order: 5;
    }

    :nth-child(5) {
      order: 2;
    }
  }
}