.profile-root {
  @extend .w-100, .min-h-100, .flex, .flex-column;

  .profile-header {
    @extend .bg-green;
    min-height: 15rem;
  }

  .left-pane {
    @extend .w9, .relative, .flex, .flex-column, .items-center;

    .profile-avatar {
      @extend .absolute, .br-pill, .overflow-hidden, .left-0, .right-0, .ma-auto;
      top: -4rem;
      width: 8rem;
      height: 8rem;
    }

    .activity-status {
      @extend .flex, .justify-around;

      > div {
        @extend .flex, .flex-column, .items-center;

        > p:first-child {
          @extend .f6, .semi-bold;
        }

        > p:last-child {
          @extend .f10;
        }
      }
    }

    .social-links {
      > * {
        @extend .mh2;

        img {
          @extend .h2, .w2;
        }
      }
    }

    .inputable {

      pre {
        @extend .tc;
      }

      input, textarea {
        @extend .focus-outline-0, .bw1, .bg-none, .b-black, .bt-0, .bl-0, .br-0;
        box-sizing: border-box;
        resize: none;

        &::placeholder {
          @extend .gray;
        }
      }

      textarea {
        @extend .w-100, .h5;
      }
    }

    .empty-text {
      > p {
        @extend .gray, .i;
      }
    }
  }

  .right-pane {
    .profile-inner-header {
      @extend .flex, .justify-center, .f10, .semi-bold, .mv3;

      > * {
        @extend .mh2;
      }

      > .active {
        @extend .blue;
      }
    }

    .public-masonry-holder {
      @extend .pa0;

      > div {
        width: 100%;
      }
    }
  }
}