.admin-root {
  @extend .min-vh-100, .pv2, .flex, .flex-column;

  .nav-buttons {
    @extend .flex, .justify-center, .items-center, .br-pill, .bw1, .b--solid, .b-black, .f10;

    a {
      @extend .br-pill, .bw0, .focus-outline-0, .flex, .justify-center, .items-center, .pv3, .ph5, .black, .pointer, .no-underline;

      >img {
        margin-right: 8px;
      }
    }

    .active-nav-button {
      @extend .semi-bold, .white, .bg-black;
    }

    .action-buttons {
      @extend .absolute, .flex, .items-center;
      right: 1%;
    }
  }

  .data-grid {
    @extend .flex-grow-1, .relative;
    width: 98%;

    > div {
      @extend .absolute, .absolute--fill;
    }

    div.preview {
      @extend .flex, .justify-center, .overflow-hidden, .br-pill, .shadow-2, .ma-auto;
      width: 34px;
      height: 34px;

      > img {
        @extend .fit-cover;
      }
    }

    .ag-cell {
      @extend .flex,.items-center;
      padding: 0 5px;
    }

    .ag-header-cell {
      padding: 0 2px;
      border-left: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE;
    }

    .ag-header-cell-label {
      @extend .justify-center;
    }

    p.size-huge {
      color: red;
    }

    p.size-large {
      color: deeppink;
    }

    p.size-big {
      color: yellowgreen;
    }

    p.order-number {
      color: #262BD5;
    }
  }
}