.toggle-box {
  @extend .br-pill, .bg-gray-60, .flex;

  .on-button, .off-button {
    @extend .button, .br-pill, .h2, .ph4, .flex, .items-center, .justify-center;
  }

  &.on .on-button, &.off .off-button {
    @extend .bg-blue, .white;

    img {
      filter: invert(1) brightness(1000%);
      -webkit-filter: invert(1) brightness(1000%);
      -moz-filter: invert(1) brightness(1000%);
    }
  }
}