.slider {
  @extend .flex, .items-center, .f11;

  input, input:hover, input:focus {
    @extend .bw0;
  }

  input.input-value {
    @extend .focus-outline-0, .w-100, .h-100, .f10, .bg-none, .tc, .bg-gray, .br-pill, .bw1, .b--solid, .b-gray;
    min-width: 3rem;
    height: 1.125rem;

    &:hover {
      @extend .b-blue;
    }
  }

  .slider-min {
    @extend .tc, .mr2;
    min-width: 2rem;
  }

  .slider-max {
    @extend .tc, .ml2;
    min-width: 2rem;
  }
}
