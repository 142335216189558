@keyframes blink {
  0% {
    opacity: .0;
  }
  33% {
    opacity: 1;
  }
  100% {
    opacity: .0;
  }
}

span.loading-dot-1, span.loading-dot-2, span.loading-dot-3 {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

span.loading-dot-2 {
  animation-delay: .5s;
}

span.loading-dot-3 {
  animation-delay: 1s;
}
