.editor-emoji-select {
  display: inline-block;
  width: 22px;
  height: 22px;
  position: relative;
}

.editor-emoji-select-button, .editor-emoji-select-button-pressed {
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.5em;
  background: unset;
  border: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-emoji-select-button:focus, .editor-emoji-select-button-pressed:focus {
  outline: 0;
  /* reset for :focus */
}

.editor-emoji-select-popover {
  left: -44px;
  bottom: 22px;
  padding: 0;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
}

.editor-emoji-select-popover-closed {
  display: none;
}

.editor-emoji-select-popover-title {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.editor-emoji-select-popover-groups {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 16em;
  height: 10em;
}

.editor-emoji-select-popover-group {
  padding: 0 .5em;
}

.editor-emoji-select-popover-group:first-child .editor-emoji-select-popover-group-title {
  display: none;
}

.editor-emoji-select-popover-group-title {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.editor-emoji-select-popover-group-list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.editor-emoji-select-popover-group-item {
  width: 2.5em;
  height: 2.5em;
}

.editor-emoji-select-popover-tone-select {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.editor-emoji-select-popover-tone-select-list {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: -webkit-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.editor-emoji-select-popover-tone-select-item {
  width: 2.5em;
  height: 2.5em;
}

.editor-emoji-select-popover-tone-select-item:first-child {
  border-right: 1px solid #e0e0e0;
}

.editor-emoji-select-popover-entry, .editor-emoji-select-popover-entry-focused {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.editor-emoji-select-popover-entry-focused {
  background-color: #efefef;
}

.editor-emoji-select-popover-entry-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.editor-emoji-select-popover-nav {
  margin: 0;
  padding: 0 .5em;
  display: -webkit-box;
  display: flex;
  width: 16em;
  list-style: none;
}

.editor-emoji-select-popover-nav-item {
  width: 2.5em;
  height: 2.5em;
}

.editor-emoji-select-popover-nav-entry, .editor-emoji-select-popover-nav-entry-active {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.editor-emoji-select-popover-nav-entry-active {
  color: #42a5f5;
}

.editor-emoji-select-popover-scrollbar {
  position: absolute;
  right: 0;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
  transition: opacity .4s;
}

.editor-emoji-select-popover-scrollbar-thumb {
  background-color: #000;
  border-radius: .125em;
  cursor: pointer;
}

.editor-emoji-select-popover-groups:hover .editor-emoji-select-popover-scrollbar {
  opacity: .3;
}

.editor-emoji-select-popover-groups .editor-emoji-select-popover-scrollbar:hover,
.editor-emoji-select-popover-groups .editor-emoji-select-popover-scrollbar:active {
  opacity: .6;
}
.editor-emoji {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.editor-emoji-suggestions-entry, .editor-emoji-suggestions-entry-focused {
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.editor-emoji-suggestions-entry:active {
  background-color: #cce7ff;
}

.editor-emoji-suggestions-entry-focused {
  background-color: #e6f3ff;
}

.editor-emoji-suggestions-entry-text {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.editor-emoji-suggestions-entry-icon {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}

.editor-emoji-suggestions {
  border: 1px solid #eee;
  //margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  max-height: 200px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px -4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(1) translate(0, -200px) !important;
  transform: scale(1) translate(0, -200px) !important;
}
