.toast {
  @extend .tc;
  border-radius: $spacing-3 !important;
  min-height: 3rem !important;

  button {
    display: none;
  }

  div {
    color: $color-black-100 !important;
  }
}

.toast-alert {
  background: $color-white-100 !important;

  div {
    color: $color-red-100 !important;
  }
}