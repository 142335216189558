.dashboard-root {
  .dashboard-pane {
    @extend .pt12;
    height: unset !important;
    position: relative !important;
    min-height: 30rem;
  }

  .left-pane {
    @extend .mh5, .overflow-hidden;

    .dashboard-nav-link {
      @extend .link, .flex, .items-center, .mb6;

      p {
        @extend .no-underline, .semi-bold, .f10, .tl;
      }

      div {
        @extend .flex, .items-center, .justify-center;
        min-width: 2rem;
      }

      &.active {
        @extend .blue;
      }
    }
  }

  .filter-sort-options {
    @extend .flex, .items-center, .justify-between, .w-100, .pb5, .ml1;

    .filter-buttons {
      button {
        @extend .bg-white, .flex, .justify-center, .items-center, .h3, .mh3;
        opacity: 0.3;

        p {
          @extend .dn, .ml2, .ttc;
        }

        &:hover {
          p {
            @extend .blue;
          }
        }
      }

      button.selected {
        opacity: 1;

        p {
          @extend .db;
        }
      }
    }
  }
}