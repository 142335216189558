.create-root {
  @extend .relative;

  .create-viewer {
    @extend .vh-100, .w-100, .flex, .flex-column, .pointer-auto, .relative, .overflow-hidden;

    .create-pane {
      .top-pane, .top-left-pane, .top-right-pane, .control-pane {
        @extend .absolute--fill, .overflow-hidden;
        position: absolute !important;
      }

      .minor-view-pane {
        @extend .absolute--fill;
        position: absolute !important;
      }

      .top-left-pane {
        .top-right {
          @extend .absolute, .right-0, .top-0, .z-2, .flex;

          .headers {
            @extend .flex, .bg-white, .bw1, .b--solid, .b-gray, .bt-0, .br-0, .br--bottom, .br--left, .br4;

            > * {
              width: 40px;
              height: 32px;

              button {
                @extend .button, .bg-none, .flex, .justify-center, .items-center, .w-100, .h-100;
              }
            }
          }

        }
      }
    }
  }

  .camera-control {
    top: 0;
    left: 100px;
  }

  .control-pane {
    @extend .flex, .flex-column, .overflow-hidden, .bg-white;

    .headers {
      @extend .flex, .bg-white;

      > * {
        width: 16.6%;
        height: 30px;

        button {
          @extend .button, .bg-none, .br4, .bw1, .b--solid, .b-gray, .bt-0, .bl-0, .br-0, .br--top, .flex, .justify-center, .items-center, .w-100, .h-100;

          &.active {
            @extend .bw1, .bb-0;
            margin: 0 -1px 0 -1px;
          }
        }
      }
    }

    > *:not(.headers) {
      @extend .flex-grow-1;
    }
  }

  .snap-frame {
    @extend .fixed, .absolute--fill, .bw3, .b--solid, .b-blue, .pointer-none, .z-2;
  }

  .public-frame {
    @extend .fixed, .absolute--fill, .bw3, .b--solid, .b-green, .pointer-none, .z-2;
  }

  .server-render-frame {
    @extend .absolute, .right-2, .bottom-2, .z-2, .pointer-none, .flex, .items-end, .tc;

    p {
      @extend .white, .semi-bold;
      text-shadow: -1px 0 $color-black-100, 0 1px $color-black-100, 1px 0 $color-black-100, 0 -1px $color-black-100;
    }

    p:first-child {
      @extend .f8, .w6;
    }

    p:last-child {
      @extend .f10, .ml4, .w4;
    }

    &.render-error {
      p {
        @extend .red;
      }
    }

    &.render-warning {
      p {
        @extend .orange;
      }
    }
  }
}