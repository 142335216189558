
.lux-data-form {
  .raw-input {
    @extend .f10, .mv1;

    textarea {
      min-height: 10rem;
    }
  }

  .data-frame {
    @extend .bw1, .b-gray, .b--solid, .br3, .pl2, .pv2, .mv1, .mr1;

    .data-frame-header {
      @extend .flex, .pointer;

      > p {
        @extend .f10, .semi-bold, .ml2;
      }

      &.expanded {
        @extend .mb2;
      }
    }
  }

  .string-input-field, .number-input-field, .option-input-field, .color-input-field {
    @extend .flex, .items-center, .w-100, .f11, .ph2;

    > *:first-child {
      @extend .w-30;
    }

    > *:last-child {
      @extend .flex-grow-1;
    }

    > input {
      @extend .input;
    }

    .color-picker {
      @extend .relative;
    }
  }
}