.search-text-input {
  @extend .bg-gray-20, .br-pill, .flex, .items-center, .overflow-hidden;

  > div {
    @extend .flex, .justify-center, .items-center;
    width: 1.75rem;
    height: 1.75rem;
  }

  > input {
    @extend .bw0, .bg-none, .flex-grow-1, .focus-outline-0, .f10;

    &::placeholder {
      @extend .f10;
    }
  }

  &.expand-on-hover {
    > input {
      @extend .dn;
    }

    &.expanded {
      > input {
        @extend .db;
      }
    }

    &:hover {
      > input {
        @extend .db;
      }
    }
  }
}