.minor-view {
  @extend .absolute, .absolute--fill;

  > div:first-child {
    @extend .absolute, .absolute--fill, .overflow-hidden;

    .top-left {
      @extend .absolute, .top-1, .left-1, .flex;
    }

    .top-right {
      @extend .absolute, .top-1, .right-1;

      > p {
        @extend .br-pill, .bg-blue-20, .f9, .mh2, .lh-2, .tc;
        min-width: 4rem;
      }
    }

    .bottom-left {
      @extend .absolute, .bottom-1, .left-1;
    }

    .bottom-right {
      @extend .absolute, .bottom-1, .right-1;
    }
  }

  .overflow-top {
    @extend .absolute, .top--2, .right-0, .left-0, .ma-auto;

    > * {
      @extend .absolute, .ma-auto, .w1, .left-0, .right-0;
    }
  }

  .viewport-menu {
    > :not(:first-child) {
      @extend .flex-column, .br2, .bg-white, .bw1, .b--solid, .b-gray, .pa2, .top-0;
      left: 1rem;
      right: -4rem;

      button {
        @extend .bw0, .bg-none, .focus-outline-0, .pointer;

        p {
          @extend .f9, .lh-3;
        }

        &:hover {
          p {
            @extend .blue;
          }
        }
      }
    }
  }
}