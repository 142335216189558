.confirm-dialog {
  @extend .absolute, .left-0, .right-0, .top-2, .ma-auto, .bg-white, .br3, .shadow-1, .z-999, .focus-outline-0;
  max-width: 30%;

  .confirm-button {
    @extend .button, .bg-red, .tiny, .white, .f11, .ml3, .ph4, .flex, .items-center, .pointer;
  }

  .cancel-button {
    @extend .button, .bg-green, .tiny, .white, .f11, .ml3, .ph4, .flex, .items-center, .pointer;
  }
}

.confirm-dialog-overlay {
  @extend .absolute, .absolute--fill, .bg-gray-t60, .z-1000;
}
