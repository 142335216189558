.combo-box {
  @extend .bg-gray-20, .f10, .ph3, .pointer, .relative, .bw1, .b--solid, .b-gray;
  border-radius: 0.75rem;

  > .current {
    @extend .h2,.flex, .items-center, .semi-bold;

    > p {
      @extend .no-wrap, .flex-grow-1;
    }
  }

  > .options {
    @extend .dn, .absolute, .flex-column, .z-1, .bg-gray-20, .left-0, .right-0, .overflow-hidden, .bw1, .b--solid, .b-gray;
    border-radius: 0 0 0.75rem 0.75rem;
    margin: -1px;
    height: 10rem;

    .combo-option {
      @extend .pv1, .ph4;

      &.selected {
        @extend .bg-blue-20;
      }

      &:hover {
        @extend .bg-gray-60;
      }
    }
  }

  &.expanded {
    border-radius: 0.75rem 0.75rem 0 0;

    > .options {
      @extend .flex;
    }
  }
}