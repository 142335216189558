.button {
  @extend .br3, .bw0, .focus-outline-0, .semi-bold, .pointer;

  &.tiny {
    height: 1.75rem;
  }

  &.small {
    height: 2.25rem;
  }

  &.medium {
    height: 3rem;
  }

  &.large {
    height: 3.5rem;
  }

  &.bg-blue {
    @extend .hover-bg-blue-80;
  }
}

.invert-button {
  @extend .br-pill, .bw1, .focus-outline-0, .white, .semi-bold, .pointer;

  &.bg-blue {
    @extend .b-blue;

    &:hover {
      @extend .bg-white, .b-blue, .blue;
    }
  }
}

.invert-flip-button {
  @extend .br-pill, .bw1, .focus-outline-0, .bg-white, .semi-bold, .pointer;

  &.blue {
    @extend .b-blue;

    &:hover {
      @extend .bg-blue, .white;
    }
  }
}

.image-button {
  @extend .pointer, .bw0, .bg-none, .focus-outline-0, .flex, .items-center, .justify-center, .pa0;

  > .hover {
    @extend .dn;
  }

  &.has-hover {
    &:hover {
      > .hover {
        @extend .db;
      }

      > .default {
        @extend .dn;
      }
    }
  }
}

.link-button {
  @extend .button, .no-underline, .semi-bold, .flex, .items-center, .justify-center, .black;

  &:active {
    @extend .black;
  }
}

.shuffle-button {
  @extend .button, .br3, .pv2;
  background: linear-gradient(180deg, rgba(80, 76, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(270.64deg, rgba(136, 255, 234, 0.4) 48.49%, rgba(255, 255, 255, 0) 102.57%), rgba(91, 76, 255, 0.25);
}

.round-button {
  @extend .button, .br-pill;
}

.link {
  @extend .no-underline, .semi-bold, .tc, .black;
}

.blue-link {
  @extend .br3, .bw0, .focus-outline-0, .pa0, .underline, .pointer, .blue, .bg-none;
}

.hover-popup {
  @extend .relative, .flex, .justify-center, .items-center;

  > :not(:first-child) {
    @extend .dn;
  }

  &:hover {
    > :not(:first-child) {
      @extend .flex, .absolute;
    }
  }
}

.input {
  @extend .bw1, .bg-none, .focus-outline-0, .b-black, .bt-0, .bl-0, .br-0, .pa0, .lh-3;
}