#editor-cube {
  @extend .pointer-none, .ma6, .pa0, .absolute, .left-0, .top-0, .z-2, .o-70;
  width: 60px;
  height: 60px;
  // background-color: #fff; /* or transparent; will show through only if renderer alpha: true */
  // border: 1px solid black; /* or none; */
}

.editor-3d {
  @extend .absolute;
  z-index: -9999;
  opacity: 0.01;

  > * {
    @extend .absolute;
  }
}

.editor-cube-top-arrow, .editor-cube-bottom-arrow, .editor-cube-right-arrow, .editor-cube-left-arrow {
  @extend .absolute, .focus-outline-0, .pa0, .flex, .justify-center, .items-center, .br4, .bg-white-t60, .z-2, .bw1, .b--solid, .b-gray;
  width: 1.125rem;
  height: 1.125rem;
}

.editor-cube-top-arrow {
  top: 4px;
  left: 40px;
}

.editor-cube-bottom-arrow {
  top: 76px;
  left: 40px;
}

.editor-cube-right-arrow {
  top: 40px;
  left: 76px;
}

.editor-cube-left-arrow {
  top: 40px;
  left: 4px;
}

.model-selection-box {
  @extend .fixed, .bw1, .b--solid, .b-blue-60, .bg-blue-t40;
}

//#control-canvas {
//  @extend .absolute, .absolute--fill, .z-2, .pointer-none;
//}
//
//#env-map-canvas {
//  @extend .absolute, .absolute--fill, .z--1, .pointer-none;
//}
//

.overlay-canvas {
  @extend .absolute, .pointer-none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.T_INP_0, .T_INP_1, .T_INP_2, .A_INP_0, .A_INP_1, .A_INP_2, .INPUT_OFFSET {
  @extend .text-input, .black, .tc;
  font-size: $font-size-7 !important;
  text-shadow: -1px 0 $color-white-100, 0 1px $color-white-100, 1px 0 $color-white-100, 0 -1px $color-white-100;
}

.T_INP_0, .A_INP_0 {
  background: #FFD3D380;
}

.T_INP_1, .A_INP_1 {
  background: #B5FFF280;
}

.T_INP_2, .A_INP_2 {
  background: #EACFFF80;
}

.INPUT_ANNOTATION {
  @extend .text-input, .bg-white, .black;
  min-width: 8rem;
}

