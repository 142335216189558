.data-form {
  .raw-input {
    @extend .w-100;

    pre {
      word-wrap: break-word;
    }

    textarea {
      @extend .focus-outline-0, .bw1, .bg-none, .b-black, .w-100, .h-100;
      box-sizing: border-box;
      resize: none;

      &::placeholder {
        @extend .gray;
      }
    }
  }
}