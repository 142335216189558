.image-option-box {
  @extend .bg-gray, .flex, .items-center, .pointer, .br-pill;

  .image-option {
    @extend .relative;

    > img {
      @extend .fit-cover, .br-pill, .overflow-hidden;
      min-height: 1.5rem;
      min-width: 1.5rem;
      max-height: 1.5rem;
      max-width: 1.5rem;
    }

    p {
      @extend .dn, .absolute, .left--2, .right--2, .ma-auto, .tc, .f11;
      top: 28px;
    }

    &.selected {
      > img {
        @extend .bw2, .b--solid, .b-blue, .br-pill;
      }

      p {
        @extend .db;
      }
    }
  }

  .image-option:not(:last-child) {
    @extend .mr2;
  }
}