.directory-card {
  @extend .relative, .flex, .flex-column, .justify-center, .items-center, .br2 , .bw1, .b--solid, .b-gray, .pa1, .ma3, .pointer;
  width: 6rem;
  height: 6rem;

  &:hover {
    .directory-title {
      @extend .blue;
    }

    .delete-directory {
      @extend .db;
    }
  }

  .delete-directory {
    @extend .absolute, .button, .bg-none, .dn, .z-1;
    left: -2px;
    top: 2px;

    img {
      width: 1rem;
      height: 1rem;
    }
  }

  .project-count {
    @extend .absolute, .bg-white, .br-pill, .flex, .justify-center, .items-center;
    right: 10px;
    top: 10px;
    width: 17px;
    height: 17px;

    > div {
      @extend .white, .br-pill, .bg-blue, .flex, .justify-center, .items-center;
      width: 15px;
      height: 15px;

      p {
        @extend .f14;
      }
    }
  }

  .directory-title {
    @extend .w-100, .tc, .f10, .z-1, .flex, .items-end, .justify-center;

    p {
      @extend .no-wrap, .lh-3;
    }

    input {
      @extend .w-100, .focus-outline-0, .bw1, .bg-none, .b-black, .bt-0, .bl-0, .br-0;
    }
  }

  .drag-holder {
    position: absolute;
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -8px;
  }
}

.new-directory-card {
  @extend .relative, .flex, .flex-column, .justify-center, .items-center, .br2 , .bw1, .b--solid, .b-gray, .pa3, .ma3, .pointer;
  width: 6rem;
  height: 6rem;

  >img:last-child {
    @extend .dn;
  }

  &:hover {
    >img:first-child {
      @extend .dn;
    }

    >img:last-child {
      @extend .db;
    }
  }
}