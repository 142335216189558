.chat-messages-theme-create {
  .chat-speech {
    @extend .ma0, .pv0, .ph3;

    .chat-balloon {
      @extend .relative, .flex-grow-1, .pv1, .ph0;
      max-width: unset;

      &.on-sequence {
        margin-left: 44px;

        .chat-content {
          @extend .ma0;
        }
      }

      .chat-user {
        @extend .f10, .bold, .black-40;
      }

      .chat-time {
        @extend .f11, .bold, .black-20;
      }

      .chat-content {
        @extend .mt2, .f10, .lh-2, .fw4;
      }

      .chat-edit-actions {
        @extend .absolute, .top-0, .bottom-0, .ma-auto, .pv0, .ph3, .flex-row, .items-center, .bg-white-t60;
        right: -0.5rem;
        backdrop-filter: blur(5px);
        max-height: 30px;
      }
    }

    &:hover {
      @extend .bg-gray-t20;
    }
  }

  .chat-reply {
    .show-more-reply {
      @extend .db, .f10, .bold, .gray-40, .ml5;
    }
  }

  .chat-new-mark {
    @extend .relative;

    p {
      @extend .f10, .normal, .tr, .red;
    }

    > div {
      @extend .absolute, .w-100, .bg-red, .top-0, .bottom-0, .ma-auto;
      height: 1px;
      right: 30px;
    }
  }
}

.rich-editor-theme-create {
  margin-top: 10px;

  > div:last-child {
    @extend .bg-gray-t20;
    border-radius: 0 0 10px 10px;

    .rich-text-input {
      @extend .bg-unset, .f10, .ma3;
      height: 4.5rem;

      .placeholder {
        @extend .f10, .gray, .left-0;
      }
    }

    .rich-edit-tools {
      @extend .bg-unset, .bw1, .b--solid, .b-gray-t20;

      .rich-edit-button {
        @extend .bg-unset;
        width: 22px;
        height: 22px;
      }

      .send-button {
        @extend .ml-auto;
      }
    }
  }
}