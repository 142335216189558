.dashboard-header {
  @extend .fixed, .flex, .w-100, .justify-center, .z-2, .bg-white;
  top: 40px;

  .nav-links {
    @extend .flex, .items-center, .justify-center, .f10, .semi-bold;

    > div {
      @extend .bg-gray;
      width: 1px;
      height: 1rem;
    }

    > a {
      @extend .gray, .no-underline, .tc, .pv2, .ph2, .br-pill;
    }

    > a.active {
      @extend .black;
    }
  }

}