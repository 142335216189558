.avatar {
  @extend .flex, .justify-center, .items-center, .br-pill, .overflow-hidden;
  min-width: $chat-avatar-size;
  min-height: $chat-avatar-size;
  max-width: $chat-avatar-size;
  max-height: $chat-avatar-size;
}

.chat-highlight {
  @extend .shadow-1, .br5;
}

.chat-messages {
  @extend .flex, .flex-column, .justify-end, .mt2;
}

.chat-speech {
  @extend .flex, .ma3;

  .chat-balloon {
    @extend .flex, .flex-column, .ml3;
    max-width: 80%;

    .chat-user {
      @extend .f10, .bold, .mr3;
    }

    .chat-time {
      @extend .f10;
      color: #919191;
    }
  }

  .chat-content {
    @extend .f10, .normal;

    p {
      @extend .f10, .normal;
    }

    .chat-action-texts {
      @extend .flex, .mt1;

      > * {
        @extend .br4, .bg-gray-t20, .flex, .items-center, .pointer, .mr1, .bw1, .b--solid, .b-gray-t40;
        padding: 1px 4px;

        > p:first-child {
          @extend .lh-2, .mr3;
          margin-bottom: -2px;
        }

        > p:last-child {
          @extend .f11;
        }
      }
    }

    .chat-attachment {
      @extend .flex, .justify-center, .items-center, .relative, .br3, .shadow-1, .bg-white, .ma1, .pointer;
      height: $chat-attachment-size;
      width: $chat-attachment-size;

      .download-button {
        @extend .dn, .justify-center, .items-center, .absolute, .absolute--fill, .ma-auto, .br3, .bg-gray-t40, .pointer;

        img {
          filter: invert(1) brightness(1000%);
          -webkit-filter: invert(1) brightness(1000%);
          -moz-filter: invert(1) brightness(1000%);
        }
      }

      > div {
        @extend .flex, .justify-center, .items-center, .br3, .overflow-hidden;
        height: $chat-attachment-size;
        width: $chat-attachment-size;

        > img {
          @extend .h-100, .w-100, .fit-cover;
        }
      }

      &:hover {
        .download-button {
          @extend .flex;
        }
      }
    }
  }

  .chat-actions {
    @extend .pt1;

    a {
      @extend .flex, .items-center;
      height: 19px;
    }

    p {
      @extend .f10;
    }
  }

  .chat-edit-actions {
    @extend .dn;

    > a {
      @extend .relative;

      > div {
        @extend .absolute, .right-0;
        bottom: 20px;
      }
    }

    > *:not(:first-child) {
      @extend .ml2;
    }
  }

  &:hover {
    .chat-edit-actions {
      @extend .flex;
    }
  }
}

.chat-typing-users {
  @extend .pv0, .ph3;

  > div {
    @extend .br4, .bg-gray-t20;

    > div {
      transform: scale(0.25);
      margin: -12px -42px;
    }
  }

  > p {
    @extend .f11;
    margin-left: 5px;
  }
}

.chat-reply {
  margin-left: 50px;

  .show-more-reply {
    @extend .db, .f10, .bold, .blue, .ml3, .pointer;
  }
}
