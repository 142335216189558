.view-type-options {
  @extend .flex, .flex-column;

  .view-type-item {
    @extend .flex, .items-center, .justify-between, .br-pill, .pl3, .pr1, .pv1, .pointer;

    > div:first-child {
      @extend .flex, .items-center;

      > div:first-child {
        @extend .flex, .items-center;
        min-width: 1.5rem;
        min-height: 1.5rem;
      }
    }

    > div:last-child {
      @extend .flex, .h2, .w2, .items-center, .justify-center, .br-pill, .bg-blue-40, .ml3, .f9, .semi-bold, .pointer, .hidden;
    }

    &:hover {
      @extend .bg-blue-20;

      > div:last-child {
        @extend .visible;
      }
    }
  }
}
