.library {
  @extend .relative, .h-100, .bg-white, .z-2;

  .library-item {
    @extend .flex, .flex-column, .justify-end, .items-center, .pa2, .pointer;
    height: 40px;
    width: 40px;

    .tooltip-target {
      @extend .flex, .flex-column, .justify-end, .items-center, .w-100, .h-100;
    }

    img:hover {
      transform: scale(1.125);
    }

    .library-item-image {
      max-height: 32px;
      @extend .w-100, .br3, .flex-grow-1;
    }

    &.wide {
      width: 60px;
    }

    &:not(.wide) {
      clip-path: circle(16px at center);

      img {
        clip-path: circle(16px at center);
      }
    }

    &.border img {
      @extend .bw1, .b-gray, .b--solid;
    }

    &.public-project-item {
      @extend .pa1;
      clip-path: none;

      .library-item-image {
        @extend .br1, .w-100, .h-100, .fit-cover;
        clip-path: none;
      }
    }
  }

  .add-dm-button, .add-backdrop-button {
    @extend .button, .bg-none, .flex, .justify-center, .items-center;
    width: 40px;
  }

  .library-list {
    @extend .flex, .items-center;
    height: 50px;
    margin-top: 5px;

    .file-upload {
      @extend .bw1, .b--solid, .b-gray, .bl-0, .bt-0, .bb-0, .ph2, .relative;
      width: 100px;
    }

    .library-list-section {
      @extend .bw1, .b--solid, .b-gray, .bl-0, .bt-0, .bb-0, .ph2;

      > div {
        @extend .flex, .flex-column, .bw1, .br2, .b--solid, .b-white;

        > div {
          @extend .flex;

          .library-item {
            min-width: 40px;
          }

          .expand-button {
            @extend .button, .bg-none, .ph1;
            min-width: 1rem;
          }
        }

        > p {
          @extend .dn, .blue, .f10, .nt2, .tc, .pointer;
        }

        &:hover {
          @extend .b-blue;

          > p {
            @extend .db;
          }
        }
      }
    }
  }

  .library-expanded-section {
    @extend .flex, .pt4, .ph4;

    .search-text-input-container {
      @extend .flex, .flex-column, .pt4;
    }

    > div:last-child {
      @extend .flex-grow-1, .mh6;
    }
  }

  .library-expanded-section > div:last-child, .library-grid {
    @extend .flex, .flex-wrap;

    .library-item {
      clip-path: none;
    }
  }

  .library-grid {
    @extend .flex, .justify-center, .w-100;
    padding: 20px 0 10px 0;

    > div {
      @extend .flex, .flex-column, .justify-center, .items-center;
      width: calc(100% - 10rem);

      > div {
        width: 100%;
      }

      .public-masonry-holder {
        @extend .pa0;

        > div {
          @extend .w-100;
        }
      }

      .public-card {
        @extend .pa1;

        img {
          transform: scale(0.75);
        }

        .preview {
          @extend .mb3;

          .preview-cover {
            @extend .pt3;

            .play-import-conntainer {
              img {
                @extend .ma0;
              }
            }

            .title {
              @extend .f10, .pt1;
            }

            .username {
              @extend .f12, .pt1;
            }
          }
        }

        .actions {
          > * {
            @extend .ma0;
          }
        }

        .user-icon {
          @extend .shadow-1;
          bottom: 0.5rem;

          img {
            transform: none;
          }
        }
      }
    }

    .popular-posts-section > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }

    .library-grid-section {
      @extend .pa3;
      min-width: 33%;
      max-width: 33%;
      min-height: 10rem;

      > div {
        @extend .flex, .flex-column, .pa3, .bw1, .br4, .b--solid, .b-white;
        min-height: 9rem;

        > button:first-child {
          @extend .flex, .bw0, .pa0, .bg-none, .focus-outline-0, .items-center, .pointer, .h2;

          > img {
            @extend .mr2;
          }

          > img:not(:first-child) {
            @extend .dn;
          }

          .title {
            @extend .flex-grow-1, .f9, .bold, .tl, .w8;
          }

          > p:last-child {
            @extend .ml5, .self-end, .dn, .tr, .f10;
          }
        }

        > div:last-child {
          @extend .flex, .flex-wrap;
        }

        &:hover {
          @extend .b-blue;

          > button:first-child {
            @extend .blue;

            > img:first-child {
              @extend .dn;
            }

            > img:not(:first-child) {
              @extend .db;
            }

            > p:last-child {
              @extend .db;
            }
          }
        }
      }
    }
  }


  .trash-drop {
    @extend .fixed, .bg-white, .right-2, .bottom-2, .br3, .flex, .items-center, .justify-center, .z-1;
    height: 32px;
    width: 32px;

    &.large {
      @extend .bg-gray;
      height: 64px;
      width: 64px;

      img {
        transform: scale(2);
      }
    }
  }

  .back-button {
    @extend .fixed, .bg-white, .left-3, .bottom-3, .z-1;
  }

}

.thumbnail-holder {
  max-height: 150px;
  min-height: 150px;
}

.expand-buttons {
  @extend .absolute, .bg-white, .left-0, .right-0, .ma-auto, .flex, .items-center, .justify-around, .z-1, .br-pill, .bw1, .b-gray, .b--solid, .bb-0, .br--top;
  top: -18px;
  width: 5rem;
  height: 18px;

  button {
    @extend .button, .bg-none;
  }
}