.project-card {
  @extend .flex, .flex-column, .select-disable, .ma3;

  &:hover {
    .project-title, .last-modified-date {
      @extend .blue;
    }
  }

  .preview {
    @extend .flex, .items-center, .justify-center, .w-100, .flex-grow-1, .pointer, .relative, .br3, .overflow-hidden, .mb2;
    width: 184px;
    height: 146px;

    .preview-cover {
      @extend .absolute, .absolute--fill, .white, .flex-column, .justify-center, .items-center, .br3, .tc, .dn;
      background: linear-gradient(1.44deg, rgba(13, 0, 50, 0.65) 0%, rgba(0, 0, 0, 0) 21%);

      .actions {
        @extend .absolute, .left-0, .right-0, .ma-auto, .z-1, .flex, .justify-center;
        bottom: 0.25rem;

        > * {
          @extend .mh2;
        }
      }
    }

    .operation {
      @extend .absolute, .absolute--fill, .bg-white-t60, .f12, .semi-bold, .black, .flex, .justify-center, .items-center;
    }

    .unread {
      @extend .absolute, .z-1, .bg-white-t60, .justify-center, .items-center, .br-pill, .top-1, .right-1, .dn;
      width: 13px;
      height: 13px;

      > div {
        @extend .bg-red, .br-pill;
        width: 9px;
        height: 9px;
      }
    }

    .opened {
      @extend .absolute, .br2, .bg-black-t20, .pv0, .top-4, .left-1, .ph1, .z-1, .f12, .semi-bold, .white, .dn;
      height: 20px;
    }

    .infos {
      @extend .absolute, .z-1, .bg-black-t20, .br2, .top-1, .left-1, .f12, .semi-bold, .white, .pv0, .ph1, .dn;
      height: 20px;

      div {
        @extend .flex, .justify-center, .items-center, .pr1;
      }
    }

    &:hover {
      .preview-cover, .opened, .unread, .infos {
        @extend .flex;
      }
    }
  }

  .drag-holder {
    position: absolute;
    left: -8px;
    right: -8px;
    top: -8px;
    bottom: -24px;
  }

  .project-title {
    @extend .f10, .z-1, .flex, .items-end;
    max-width: 140px;

    p {
      @extend .no-wrap;
    }

    input {
      @extend .w-100, .focus-outline-0, .bw1, .bg-none, .b-black, .bt-0, .bl-0, .br-0;
    }
  }

  .last-modified-date {
    @extend .f11, .pt2;
  }
}

.go-up-card {
  @extend .br2, .bw1, .b--solid, .b-gray, .overflow-hidden, .relative, .w3, .h3;

  >a {
    @extend .absolute, .absolute--fill, .flex, .items-center, .justify-center;
  }
}

.new-project-card {
  @extend .ma3;
  width: 184px;
  height: 170px;

  > div {
    @extend .bg-gray-t20, .flex, .justify-center, .items-center, .br3, .overflow-hidden, .pointer, .relative;
    height: 146px;

    >img:nth-child(2) {
      @extend .dn;
    }

    .operation {
      @extend .absolute, .absolute--fill, .bg-white-t60, .f12, .semi-bold, .black, .flex, .justify-center, .items-center, .z-1;
    }
  }

  &:hover {
    > div {
      >img:first-child {
        @extend .dn;
      }

      >img:nth-child(2) {
        @extend .db;
      }
    }
  }

}