
.DraftEditor-root {
  max-width: 100%;
  min-width: 100%;
  cursor: text;

  .DraftEditor-editorContainer {
    @extend .h-100;
  }
}

.rich-edit-tools {
  @extend .bg-white-t60, .pa3;
  border-radius: 10px 0 0 0;

  .rich-edit-button {
    @extend .bw0, .pa0, .focus-outline-0, .flex, .justify-center, .items-center, .br-pill, .bg-white-t60, .pointer;
    width: 22px;
    height: 22px;
  }
}

.rich-edit-attachments {
  .chat-ready-attachment {
    @extend .flex, .justify-center, .items-center, .relative, .br2, .shadow-1, .bg-white, .pointer;
    height: 34px;
    width: 34px;
    margin: 2px;

    .close-button {
      @extend .dn, .bw0, .pa0, .focus-outline-0, .justify-center, .items-center, .absolute, .absolute--fill, .br2, .bg-black-t60, .pointer, .w-100, .h-100;

      img {
        filter: invert(1) brightness(1000%);
        -webkit-filter: invert(1) brightness(1000%);
        -moz-filter: invert(1) brightness(1000%);
      }
    }

    > div {
      @extend .flex, .justify-center, .items-center, .br2, .overflow-hidden;
      height: 34px;
      width: 34px;

      > img {
        width: 70%;
        height: 70%;
      }
    }

    &:hover {
      .close-button {
        @extend .flex;
      }
    }
  }
}

.rich-text-input {
  @extend .bg-white-t60, .f9, .normal, .relative;
  min-height: 52px;

  .avatar {
    margin: 6px 6px 0 6px;
  }

  .placeholder {
    @extend .absolute, .gray, .f10, .h1, .lh-1;
    left: 51px;
  }
}