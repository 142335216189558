.parametric-pane {
  @extend .bg-bottom, .contain, .pa3;

  &.import-watermark {
    background-image: url('../../../images/icon/create/parametric/import-watermark.svg');
    background-origin: content-box;
  }

  > div:first-child {
    > img {
      height: 45px;
    }
  }

  .search-text-input {
    @extend .bg-white, .bw1, .b--solid, .b-gray, .flex-row-reverse;

    > input {
      @extend .ph4;
    }
  }

  .parametric-content {
    @extend .flex, .flex-column, .min-h-100, .absolute, .left-0, .right-0, .pb4;

    .slider {
      width: 260px;
    }

    .check-box {
      @extend .button, .bg-none, .flex, .items-center, .pa0, .f11;

      > div:first-child {
        @extend .br-pill, .bw1, .b--solid, .b-black, .mr2;
        height: 12px;
        width: 12px;
      }

      &.checked {
        > div:first-child {
          @extend .relative;

          > div {
            @extend .absolute, .bg-blue, .br-pill;
            left: 2px;
            right: 2px;
            top: 2px;
            bottom: 2px;
          }
        }
      }
    }

    .on-off-button {
      @extend .button, .bg-none, .f11, .gray, .pa0, .mr3, .mv1;

      &.active {
        @extend .black;
      }

      &:hover {
        @extend .blue;
      }
    }

    .text-input {
      @extend .f11, .pv1, .ph2, .bg-white;
    }

    .color-picker {
      @extend .flex, .items-center;
    }

    .circle-around {
      @extend .h2, .w2, .br-pill, .b--solid, .bw1, .b-black-40, .ma1, .flex, .justify-center;
    }

    .invert-icon {
      @extend .bg-black-40;
      img {
        filter: invert(1) brightness(1000%);
        -webkit-filter: invert(1) brightness(1000%);
        -moz-filter: invert(1) brightness(1000%);
      }
    }
  }

  .calc-setting-row:not(.locked) {
    .parametric-row-header:hover {
      .parametric-row-title {
        max-width: calc(100% - 10rem);
        margin-right: 6.5rem;
      }
    }
  }

  .annotate-setting-row:not(.locked) {
    .parametric-row-header:hover {
      .parametric-row-title {
        max-width: calc(100% - 7rem);
        margin-right: 3.5rem;
      }
    }
  }

  .parametric-row {
    @extend .flex, .flex-column, .bw1, .b--solid, .b-transparent, .br2;
    //height: 28px;

    &.selected {
      background: #593EFF10;
    }

    &.highlighted {
      background: #593EFF08;
    }

    &.scope {
      @extend .b-red;
    }

    .parametric-row-header {
      @extend .flex, .items-center, .pointer, .relative;
      padding: 2px;

      .parametric-backdrop-thumbnail {
        @extend .relative;
        min-height: 9px;

        > div {
          @extend .absolute, .absolute--fill;
        }
      }

      > button:nth-child(1) {
        @extend .button, .bg-none, .pa0, .w1, .h1;
      }

      > div:nth-child(2) {
        @extend .mr2, .flex, .justify-center, .items-center;
        width: 12px;

        img {
          @extend .br2, .overflow-hidden;
        }
      }

      > div:nth-child(3) {
        @extend .mr2, .flex, .justify-center, .items-center;
        width: 1rem;
      }

      > div:first-child, > div:nth-child(2) {
        @extend .flex, .items-center, .justify-center;
        max-width: 12px;
        min-width: 12px;
      }

      .parametric-row-title {
        @extend .f9, .flex-grow-1;
        line-height: 22px;

        p {
          @extend .no-wrap;
          line-height: 22px;
        }

        input {
          @extend .w-100, .focus-outline-0, .bw1, .bg-none, .b-black, .bt-0, .bl-0, .br-0;
        }
      }

      .parametric-row-actions {
        @extend .absolute, .right-0, .top-0, .bottom-0, .ma-auto, .dn;

        button {
          @extend .button, .bg-none, .ph1, .h-100;
          opacity: 0.5;
        }
      }

      .preview-drag-holder {
        @extend .absolute, .top-0, .left-4, .right-0, .bottom-0;
      }

      > *:not(.preview-drag-holder) {
        z-index: 1;
      }

      &:hover {
        background: #593EFF08;

        .parametric-row-title {
          > p {
            @extend .blue;
          }
        }

        .parametric-row-actions {
          @extend .flex, .items-center;

          button:hover {
            opacity: 1;
          }
        }
      }
    }

    .parametric-row-content {
      @extend .bw1, .b--solid, .b-transparent, .bb-0, .bl-0, .br-0, .pl5;

      .parametric-group {
        @extend .flex, .flex-column;

        .parametric-group-title {
          @extend .flex, .items-center, .h1;

          > p {
            @extend .blue, .f11, .semi-bold, .mr2, .pointer-none;
          }

          > div:last-child {
            @extend .dn;

            button {
              @extend .button, .bg-none, .ph1, .h-100;
              opacity: 0.5;
            }
          }

          &:hover {
            > div:last-child {
              @extend .flex, .items-center;

              button:hover {
                opacity: 1;
              }
            }
          }
        }
      }

      .parametric-slider {
        @extend .flex, .w-100, .z-1, .relative;

        .link-drop {
          @extend .absolute, .dn;
        }

        .input-value input {
          @extend .f10;
        }

        &:hover {
          .link-drop {
            @extend .db;
          }
        }
      }

      .parametric-group-buttons {
        @extend .flex, .mv2;

        button {
          @extend .button, .f11, .bg-none, .pa0, .black, .semi-bold, .mr4;

          &:hover {
            @extend .blue;
          }
        }
      }

      .parametric-prev-rows {
      }
    }

    &:hover, &.highlighted {
      @extend .b-blue;

      &.expanded {
        > div > .parametric-row-content {
          @extend .b-blue;
        }
      }
    }
  }

  .calc-hover {
    background: $color-gray-o20;
  }
}