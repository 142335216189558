.parameter-input {
  @extend .bg-white, .br-pill, .flex, .items-center, .pv1, .ph3, .overflow-hidden;

  > p {
    @extend .ttu, .f9;
  }

  > input {
    @extend .bw0, .bg-none, .focus-outline-0, .f9, .ml4, .w4, .tc;

    &::placeholder {
      @extend .f9;
    }
  }

  &.x-axis {
    background: #FFD3D3CC;
  }

  &.y-axis {
    background: #B5FFF2;
  }

  &.z-axis {
    background: #EACFFF;
  }
}