.render-card {
  @extend .relative, .flex, .flex-column, .justify-center, .items-center, .br2 , .bw1, .b--solid, .b-gray, .pa2, .pointer, .w-100;
  
  &:hover {
    .stop-render-button, .remove-render-button {
      @extend .db;
    }
  }

  .stop-render-button {
    @extend .absolute, .button, .bg-none, .dn, .z-1;
    right: 28px;
    top: 2px;
  }

  .remove-render-button {
    @extend .absolute, .button, .bg-none, .dn, .z-1;
    right: 2px;
    top: 2px;
  }

  .progress-bar {
    @extend .w-100;
    background: #5B4CFF30;
    height: 6px;

    > div {
      background: #5B4CFF;
    }
  }

  img {
    @extend .w-100, .fit-cover;
    max-height: 8rem;
  }

  .render-infos {
    @extend .absolute, .flex, .flex-column;
    left: 1rem;
    top: 1rem;

    p {
      @extend .f9, .semi-bold, .lh-3, .white;
      text-shadow: -1px 0 $color-black-100, 0 1px $color-black-100, 1px 0 $color-black-100, 0 -1px $color-black-100;
    }
  }
}