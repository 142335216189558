
.history-pane {
  @extend .h-100, .w-100, .flex, .flex-column, .pa3;

  .history-command-list {
    @extend .flex, .flex-column, .f9;

    .history-group-info {
      @extend .flex, .items-end, .pv1, .ph3, .mt2, .bw1, .b--solid, .b-gray, .bt-0, .bl-0, .br-0;

      > p:last-child {
        @extend .ml6, .bold, .f12, .lh-1, .black-40;
      }
    }

    .history-command {
      @extend .relative, .pa2;

      p {
        @extend .normal, .mr7;
      }

      > div {
        @extend .dn, .items-center, .absolute, .top-0, .right-0, .bottom-0;

        > button {
          @extend .button, .bg-none, .pa0, .mr3, .pointer;
        }
      }

      &:hover {
        @extend .bg-gray-20;

        > div {
          @extend .flex;
        }
      }
    }

    .past {
      p {
        @extend .black-60, .i;
      }
    }

    .current {
      p {
        @extend .flex, .bold;
      }

      > div {
        @extend .dn;
      }

      &:hover {
        > div {
          @extend .dn;
        }
      }
    }

    &.disabled {
      @extend .pointer-none;
      opacity: 0.3;
    }
  }
}
