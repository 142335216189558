.publish-menu, .annotate-measure-menu {
  @extend .z-2;

  > :not(:first-child) {
    @extend .flex-column, .items-start, .br2, .bg-white, .bw1, .b--solid, .b-gray, .pa2;
    left: 0;
    right: -6rem;
    top: 24px;

    > button {
      @extend .h2, .relative, .link-button;

      img {
        @extend .absolute, .left-0, .top-0, .bottom-0, .ma-auto;
      }

      p {
        @extend .f9, .lh-3, .ml7;
      }

      &:hover {
        @extend .blue;
      }
    }
  }
}

.screen-menu {
  @extend .z-2;

  > :not(:first-child) {
    @extend .flex-column, .items-start, .br2, .bg-white, .bw1, .b--solid, .b-gray, .pa2;
    left: 0;
    right: -6rem;
    top: 24px;

    > button {
      @extend .h2, .relative, .link-button, .bg-none, .w-100;
      justify-content: flex-start !important;

      p {
        @extend .f9, .lh-3;
      }

      &:hover {
        @extend .blue;
      }
    }
  }
}