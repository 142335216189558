.sign-in-root {
  @extend .vh-100, .w-100, .flex, .items-center, .bg-right, .contain, .relative;
  background-image: url('../../images/background/back1.png');
  background-color: #F1C4CF;

  .tips {
    @extend .absolute, .top-0, .left-0, .right-0;
    >div {
      @extend .bg-black-t40, .bw1, .bt-0, .bl-0, .br-0, .b--solid, .b-white, .flex, .items-center, .pl2, .pr4, .h4;

      > p {
        @extend .ml5, .white, .flex-auto, .semi-bold, .f9;
      }

      > a {
        @extend .pointer;
      }
    }
  }
}

.sign-form {
  @extend .relative, .br5, .pa9, .ml11, .flex, .flex-column, .overflow-hidden;
  background-color: #FFFFFF90;
  min-width: 500px;
}