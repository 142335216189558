.processing-popup {
  @extend .bg-white, .br3, .shadow-1, .pa5;

  .progress-bar {
    @extend .bg-black-20, .br2;
    width: calc(100% - 2rem);
    height: 0.25rem;
    min-width: 5rem;

    > p {
      @extend .bg-black, .br2, .h-100;
    }
  }
}

.preview-overlay {
  filter: blur(0.5rem);
  object-fit: cover;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.project-fade-in-anim {
  @extend .pointer-none, .z-1;
  -webkit-animation: fade-in 1s ease forwards;
  -moz-animation: fade-in 1s ease forwards;
  animation: fade-in 1s ease forwards;
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.project-fade-out-anim {
  @extend .pointer-none, .z-1;
  -webkit-animation: fade-out 2s ease forwards;
  -moz-animation: fade-out 2s ease forwards;
  animation: fade-out 2s ease forwards;
}

.project-fade-out-anim + div, .project-fade-in-anim + div {
  @extend .z-2;
}
