@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,900);

$big-user-icon-size: 3.125rem;
$small-user-icon-size: 1.5rem;
$chat-avatar-size: 2.1875rem;
$chat-attachment-size: 4.0625rem;

body {
  @extend .f8, .overflow-hidden;
  font-family: 'Open Sans', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

*:not(input):not(textarea) {
  @extend .select-disable;
}

.overlay {
  @extend .absolute, .z-999, .absolute--fill, .pointer-all;
}

.page-content {
  @extend .overflow-scroll;
  -ms-overflow-style: none;
}

.page-content::-webkit-scrollbar {
  @extend .dn;
}

div:focus {
  outline: 0 solid transparent;
}

h1, h2, h3, p, a {
  @extend .ma0, .lh-2, .select-disable;
}

pre {
  @extend .ma0;
  font-family: unset;
  font-size: unset;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}

.womp-cursor {
  @extend .fixed, .z-9999, .pointer-none, .hide-cursor;

  > img {
    filter: drop-shadow(0px 4px 4px #00000040);
  }

  > div {
    @extend .br3, .white, .f9, .bold, .ph2, .pv1, .ml4, .nt1;
    font-family: Poppins, sans-serif;
  }

  &.red-cursor {
    > div {
      background-color: #FF4848;
    }
  }

  &.blue-cursor {
    > div {
      background-color: #5403FF;
    }
  }
}