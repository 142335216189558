.top-progress-bar {
  @extend .fixed, .z-999, .top-0, .left-0, .ma-auto;
  right: 1%;

  @-webkit-keyframes expand-transform {
    from {transform: scaleX(0);}
    to {transform: scaleX(1);}
  }

  @-moz-keyframes expand-transform {
    from {transform: scaleX(0);}
    to {transform: scaleX(1);}
  }

  @keyframes expand-transform {
    from {transform: scaleX(0);}
    to {transform: scaleX(1);}
  }

  .bar-done {
    @extend .bg-blue;
    height: 4px;
    mix-blend-mode: difference;
  }

  .bar-progress {
    @extend .bg-blue, .w-100;
    height: 4px;
    mix-blend-mode: difference;
    transform-origin: top left;
    -webkit-animation: expand-transform 5s ease-out forwards;
    -moz-animation: expand-transform 5s ease-out forwards;
    animation: expand-transform 5s ease-out forwards;
  }
}
