.camera-control {
  @extend .bg-white, .flex, .flex-column, .f9, .ph2, .pb2;
  min-height: 2.5rem;
  min-width: 256px;

  .view-type-button {
    @extend .flex, .br-pill, .items-center, .ph3, .pv1, .pointer, .bw1, .b--solid, .b-white;

    &:hover {
      @extend .b-blue;
    }
  }

  .camera-angle-button {
    @extend .flex, .items-center;

    > img.hover {
      @extend .dn;
    }

    &:hover {
      > img.hover {
        @extend .db;
      }

      > img:not(.hover) {
        @extend .dn;
      }
    }
  }

  .hint-button {
    @extend .button, .flex, .justify-center, .items-center, .br-pill, .bw1, .b--solid, .b-black, .pv2, .mt4, .relative;

    img {
      @extend .absolute, .top-0, .bottom-0, .left-2, .ma-auto;
    }

    &:hover {
      @extend .blue, .b-blue;
    }
  }
}
