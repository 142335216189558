.tippy-tooltip-content {
  pointer-events: none;

  font-size: 0.625rem;
  line-height: 1rem;
  text-align: center;
}

.tooltip-lite {
  text-decoration: underline;
  cursor: pointer;
}

.tooltip-lite .react-tooltip-lite {
  cursor: default;
}

.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}

.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}

.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}

.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}

.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}

@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.rc-tooltip {
  opacity: 1 !important;
}

.rc-tooltip-noarrow {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}

.rc-tooltip-noarrow-hidden {
  display: none;
}

.rc-tooltip-noarrow-placement-top,
.rc-tooltip-noarrow-placement-topLeft,
.rc-tooltip-noarrow-placement-topRight {
  padding: 5px 0 9px 0;
}

.rc-tooltip-noarrow-placement-right,
.rc-tooltip-noarrow-placement-rightTop,
.rc-tooltip-noarrow-placement-rightBottom {
  padding: 0 5px 0 9px;
}

.rc-tooltip-noarrow-placement-bottom,
.rc-tooltip-noarrow-placement-bottomLeft,
.rc-tooltip-noarrow-placement-bottomRight {
  padding: 9px 0 5px 0;
}

.rc-tooltip-noarrow-placement-left,
.rc-tooltip-noarrow-placement-leftTop,
.rc-tooltip-noarrow-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-noarrow-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}

.rc-tooltip-noarrow-arrow {
  display: none;
}

.tooltip-target {
  &.disabled {
    opacity: 0.4;

    > * {
      @extend .pointer-none;
    }
  }
}

.tooltip {
  pointer-events: none;
  font-weight: 600;

  .rc-tooltip-inner, .rc-tooltip-noarrow-inner {
    background-color: $color-black-100;

    font-size: 0.625rem;
    line-height: 1rem;
    text-align: center;
  }

  &.rc-tooltip-placement-right, &.rc-tooltip-placement-rightTop, &.rc-tooltip-placement-rightBottom {
    .rc-tooltip-arrow {
      border-right-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-left, &.rc-tooltip-placement-leftTop, &.rc-tooltip-placement-leftBottom {
    .rc-tooltip-arrow {
      border-left-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-bottom, &.rc-tooltip-placement-bottomLeft, &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      border-bottom-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-top, &.rc-tooltip-placement-topLeft, &.rc-tooltip-placement-topRight {
    .rc-tooltip-arrow {
      border-top-color: $color-black-100 !important;
    }
  }
}

.tooltip-white {
  .rc-tooltip-inner, .rc-tooltip-noarrow-inner {
    color: $color-black-100 !important;
    background-color: $color-white-100 !important;
  }

  &.rc-tooltip-placement-right, &.rc-tooltip-placement-rightTop, &.rc-tooltip-placement-rightBottom {
    .rc-tooltip-arrow {
      border-right-color: $color-white-100 !important;
    }
  }

  &.rc-tooltip-placement-left, &.rc-tooltip-placement-leftTop, &.rc-tooltip-placement-leftBottom {
    .rc-tooltip-arrow {
      border-left-color: $color-white-100 !important;
    }
  }

  &.rc-tooltip-placement-bottom, &.rc-tooltip-placement-bottomLeft, &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      border-bottom-color: $color-white-100 !important;
    }
  }

  &.rc-tooltip-placement-top, &.rc-tooltip-placement-topLeft, &.rc-tooltip-placement-topRight {
    .rc-tooltip-arrow {
      border-top-color: $color-white-100 !important;
    }
  }
}

.tooltip {
  @extend .pointer-none;

  .rc-tooltip-inner {
    @extend .f11, .tc;
    background-color: $color-black-100 !important;
    line-height: 1rem;
  }

  &.rc-tooltip-placement-right, &.rc-tooltip-placement-rightTop, &.rc-tooltip-placement-rightBottom {
    .rc-tooltip-arrow {
      border-right-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-left, &.rc-tooltip-placement-leftTop, &.rc-tooltip-placement-leftBottom {
    .rc-tooltip-arrow {
      border-left-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-bottom, &.rc-tooltip-placement-bottomLeft, &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      border-bottom-color: $color-black-100 !important;
    }
  }

  &.rc-tooltip-placement-top, &.rc-tooltip-placement-topLeft, &.rc-tooltip-placement-topRight {
    .rc-tooltip-arrow {
      border-top-color: $color-black-100 !important;
    }
  }
}
