.camera-angle-slider {
  @extend .flex, .flex-column;
  min-width: 210px;
  max-width: 210px;

  .slider-track {
    @extend .flex-grow-1, .relative;
    width: 10rem;
    height: 1rem;

    div:first-child {
      @extend .absolute, .br-pill, .bg-blue-20, .ma-auto, .w-100, .absolute--fill;
      height: 0.25rem;
    }

    div:nth-child(2) {
      @extend .absolute, .br-pill, .bg-blue-20, .ma-auto, .left-0, .top-0, .bottom-0;
      height: 1rem;
      width: 2px;
      right: 84px;
    }

    img:nth-child(3) {
      @extend .absolute, .ma-auto, .left-0, .top-0;
      right: 84px;
      bottom: 40px;
    }

    div:nth-child(4) {
      @extend .absolute, .br-pill, .bg-blue, .ma-auto, .top-0, .bottom-0;
      height: 1rem;
      width: 4px;
    }
  }
}

.face-angle-slider {
  @extend .flex, .flex-column;
  min-width: 240px;
  max-width: 240px;

  .slider-track {
    @extend .flex-grow-1, .relative;
    width: 10rem;
    height: 1rem;

    div:first-child {
      @extend .absolute, .br-pill, .bg-blue-20, .ma-auto, .w-100, .absolute--fill;
      height: 0.25rem;
    }

    div:nth-child(2) {
      @extend .absolute, .br-pill, .bg-blue-20, .ma-auto, .left-0, .top-0, .bottom-0;
      height: 1rem;
      width: 2px;
      right: 84px;
    }

    img:nth-child(3) {
      @extend .absolute, .ma-auto, .left-0, .top-0;
      right: 84px;
      bottom: 40px;
    }

    div:nth-child(4) {
      @extend .absolute, .br-pill, .bg-blue, .ma-auto, .top-0, .bottom-0;
      height: 1rem;
      width: 4px;
    }
  }
}