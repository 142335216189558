.collaborator-list {
  .avatar {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border: 1px solid #180F5833;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 4px 0 #00000029;
    -moz-box-shadow: 0 0 4px 0 #00000029;
    box-shadow: 0 0 4px 0 #00000029;
  }

  .add-collaborator-button {
    pointer-events: auto;
    height: 40px;
    width: 40px;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFFA0;
    margin-right: 20px;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 4px 0 #00000029;
    -moz-box-shadow: 0 0 4px 0 #00000029;
    box-shadow: 0 0 4px 0 #00000029;
    cursor: pointer;

    >img {
      filter: drop-shadow(0 0 6px #00000029);
      -webkit-filter: drop-shadow(0 0 6px #00000029);
    }
  }

  >div:first-child {
    margin-bottom: 20px;

    >div {
      border-radius: 2rem;
      padding: 5px 15px 5px 5px;
      background: #FFFFFFA0;
      -webkit-appearance: none;
      -webkit-box-shadow: 0 3px 6px 0 #00000029;
      -moz-box-shadow: 0 3px 6px 0 #00000029;
      box-shadow: 0 3px 6px 0 #00000029;
      margin-bottom: 10px;

      > div:first-child {
        width: 10px;
        height: 10px;
        border-radius: 1rem;
        margin-right: 15px;
      }

      > p {
        flex-grow: 1;
        margin-right: 10px;
      }

      .collaboration-actions {
        display: flex;
        align-items: center;
        position: relative;
        pointer-events: all;

        >img {
          padding: 2px;
        }

        > div {
          display: none;
          position: absolute;
          margin: auto;
          bottom: 0;
          left: 18px;
          width: 140px;
          color: #180F58;
          background: #FFFFFFC0;
          backdrop-filter: blur(5px);
          -webkit-appearance: none;
          -webkit-box-shadow: 0 0 6px 0 #00000029;
          -moz-box-shadow: 0 0 6px 0 #00000029;
          box-shadow: 0 0 6px 0 #00000029;
          border-radius: 8px;
          padding: 4px;
          overflow: hidden;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
          }

          a:hover {
            background: #180F58;
            color: white;
          }
        }
      }

      .collaboration-actions:hover {
        > div {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .user-online {
    >div:first-child {
      background: #00FF90;
    }
  }

  .user-offline {
    >div:first-child {
      background: #FFD500;
    }
  }

  >div:nth-child(2) {
    display: flex;

    > div {
      pointer-events: auto;
      width: 30px;
      position: relative;

      > div {
        position: absolute;
        left: 28px;
        top: -2px;
        width: 9px;
        height: 9px;
        border-radius: 1rem;
      }
    }

    > div:last-child {
      width: 40px;
    }
  }
}