.header {
  @extend .flex, .justify-center, .items-center, .fixed, .bg-white, .w-100, .z-3;
  height: 2.5rem;

  > * {
    @extend .mh4;
  }

  .beta-mark {
    @extend .f12, .bold, .br-pill, .bw1, .b--solid, .b-black, .pv1, .ph2;
  }
}

.discover-header {
  @extend .flex, .justify-center, .items-center, .bg-white, .ph2;
  width: 30rem;
  height: 2.5rem;

  > * {
    @extend .mh4;
  }
}

.create-header {
  @extend .flex, .justify-center, .items-center, .bg-white;
  width: 34rem;
  height: 2.5rem;

  > * {
    @extend .mh3;
  }

  .project-title {
    @extend .f9, .semi-bold, .z-1, .flex, .items-end, .flex-grow-1;
    min-width: 6.5rem;
    max-width: 6.5rem;

    p {
      @extend .no-wrap;
    }

    input {
      @extend .w-100, .focus-outline-0, .bw1, .bg-none, .b-black, .bt-0, .bl-0, .br-0;
    }
  }

  .undo-redo {
    @extend .absolute, .bg-gray-60, .br4, .bw1, .b--solid, .b-gray, .br--bottom, .bt-0, .flex;
    top: 2.5rem;
    height: 1.5rem;

    > *:first-child {
      @extend .bw1, .b--solid, .b-gray, .bt-0, .bl-0, .bb-0;
    }
  }

  .womp-point {
    @extend .flex, .items-center, .bg-gray-20, .br-pill, .pa1, .f10;

    p {
      @extend .mh1;
    }
  }
}

.header, .discover-header, .create-header {
  .nav-links {
    @extend .flex, .br-pill, .bg-black, .bold, .f10;

    > a {
      @extend .white, .no-underline, .tc, .pv2, .ph5, .br-pill;
    }

    > a.active {
      @extend .bg-blue;
    }
  }

  .user-menu {
    > :not(:first-child) {
      @extend .flex-column, .br2, .bg-white, .bw1, .b--solid, .b-gray, .pa2;
      left: -4rem;
      right: -0.5rem;
      top: 24px;

      > * {
        @extend .pv1;

        p {
          @extend .f10, .lh-3, .w-100, .tl, .ml2;
        }

        &:hover {
          p {
            @extend .blue;
          }
        }
      }
    }
  }
}