.snap-mode-toggle {
  @extend .flex, .items-center, .br-pill, .mr2, .bg-white, .bw1, .b-gray, .b--solid;

  button {
    @extend .bg-none, .flex, .items-center, .bw0, .focus-outline-0, .pointer, .br-pill;
    min-height: 2rem;
    min-width: 2rem;

    &.active {
      @extend .bg-blue;
    }
  }

  .snap-mode-button {
    @extend .br-pill, .bg-white, .bw1, .b-gray, .b--solid;
  }
}
