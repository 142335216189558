.import-file-dialog-overlay {
  z-index: 2000;
  position: absolute;
  // left: calc(50% - 400px);
  left: 0;
  // right: 0;
  top: 0;
  // bottom: 0;
  width: 0;
  height: 0;
}

.import-file-dialog {
  width: 100%;
  height: 100%;
}

.import-file-dialog:focus {
  outline: none;
}

.import-file-dialog-content:focus {
  outline: none;
}

.import-file-dialog-content {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 10px 15px 0 #00000029;
  -moz-box-shadow: 0 10px 15px 0 #00000029;
  box-shadow: 0 10px 15px 0 #00000029;
  border-radius: 0.625rem;
  overflow: hidden;

  .dialog-content {
    background: #F8F8F8;
    padding: 20px 30px;
    height: calc(100% - 24px);

    .import-icon-container {
      width: 100%;
      height: 60px;
      border-radius: 5px;
    }

    .round-button {
      @extend .h3, .bold, .shadow-1, .bg-white, .mh3, .flex, .items-center, .justify-center;
      min-width: 110px;
    }

    .description {
      p {
        font-size: 12px;
      }
    }

    .button {
      line-height: 4;
      width: 9rem !important;
      height: 3rem !important;
    }
  }
}


@media only screen and (max-width: 768px) {
  .import-file-dialog {
    .overlay {
      background: #00000029 !important;
    }
  }
  .import-file-dialog-content {
    p {
      font-size: 10px;
      line-height: 1.4;
    }

    .dialog-content {
      padding: 10px 20px;

      .import-icon-container {
        width: 86px;
        height: 48px;
      }

      .description {
        p {
          font-size: 8px;
        }
      }

      .button {
        height: 20px !important;
        line-height: 20px;
        font-size: 9px;
        min-width: 80px;
        padding: 0 10px !important;
      }
    }
  }
}