.discover-root {
  @extend .relative;

  .discover-viewer {
    @extend .w-100, .flex, .flex-column, .pointer-auto, .relative, .overflow-hidden;
    height: calc(100vh - 8rem);
  }

  .camera-control {
    top: 0;
    left: 100px;
  }

  .info-flow-list {
    @extend .absolute, .left-2, .bottom-2, .z-1, .flex, .flex-column;
  }
}