.discover-control {
  @extend .bg-white;
  width: 20rem;

  > * {
    @extend .pa3;
  }

  > *:not(:first-child) {
    @extend .bw1, .bl-0, .br-0, .bb-0, .b--solid, .b-gray-60;
  }

  .user-icon {
    @extend .flex, .items-center, .justify-center, .br-pill, .overflow-hidden;

    max-height: $small-user-icon-size;
    max-width: $small-user-icon-size;
    min-height: $small-user-icon-size;
    min-width: $small-user-icon-size;
  }

  .public-control {
    > * {
      @extend .mh2;
    }
  }
}