
.hero-simple-root {
  @extend .vh-100, .w-100, .relative;
  font-family: Poppins, sans-serif;

  > video {
    @extend .absolute, .left-0, .right-0, .w-100, .h-100, .fit-cover;
  }

  .video-overlay {
    @extend .absolute, .absolute--fill;
    min-width: 100%;
    min-height: 100%;
    background: #FFFFFF80;
  }

  .loader {
    @extend .absolute, .absolute--fill, .flex, .items-center, .justify-center, .pointer-none, .z-1;
  }

  .edit {
    @extend .absolute, .absolute--fill, .flex, .flex-column, .pointer-none, .z-1;

    @extend .justify-between-l;
    @extend .justify-start-m;
    @extend .justify-start-ns;

    > div:first-child {
      @extend .flex, .flex-column, .w-100, .items-center;

      @extend .ph12-l, .pv7-l;
      @extend .pa6-m;
      @extend .ph6-ns, .pt10-ns, .pb6-ns, .mt2-ns;

      > div:last-child {
        @extend .pt10-m;
        @extend .pt5-ns;

        > p:first-child {
          @extend .semi-bold, .mb5;

          @extend .f4-l;
          @extend .f4-m;
          @extend .f5-ns;
        }

        > p:last-child {
          @extend .f8-l;
          @extend .f8-m;
          @extend .f9-ns;
        }
      }
    }
  }

  .product-hunt {
    @extend .absolute, .pointer-none, .z-1, .flex, .items-center, .pa1;

    @extend .right-2-l, .top-4-l;
    @extend .right-2-m, .top-2-m;
    @extend .right-1-ns;

    @media only screen and (max-width: 30em) {
      top: 2.5rem;
    }

    background: #FFFFFF4C;
    border-radius: 14px;
    font-family: Roboto, sans-serif;

    > p {
      @extend .f9, .pa3;

      @extend .dn-ns;
    }

    > a {
      @extend .link, .black, .flex, .pointer-auto, .flex, .items-center;

      @extend .pa2-l, .f10-l;
      @extend .pa2-m, .f10-m;
      @extend .pa1-ns, .f11-ns;

      border: 1px solid #DA552F;
      border-radius: 9px;

      > p {
        @extend .pr2;
      }
    }
  }

  .controls-l {
    @extend .absolute, .left-0, .right-0, .bottom-4, .ma-auto, .pointer-none, .justify-center, .items-center, .black-60, .z-1;

    @extend .flex-l;
    @extend .dn-m;
    @extend .dn-ns;

    > p {
      @extend .f9, .fw5, .mh4;
    }
  }

  .controls-m-ns {
    @extend .absolute, .left-0, .right-0, .bottom-0, .ma-auto, .pointer-none, .flex-column, .justify-center, .z-1;

    @extend .dn-l;
    @extend .flex-m;
    @extend .flex-ns;

    font-family: Poppins, sans-serif;

    > div:first-child {
      @extend .mb5;

      button {
        @extend .w5, .h5, .mh2, .f10, .br-pill, .bg-white-t40, .bold, .pointer-auto, .relative, .overflow-hidden;

        &:before, &:after {
          @extend .absolute, .w5, .h5, .absolute, .br-pill, .bg-blue;
          content: '';
          opacity: 0;
          transition: transform 0.75s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
          z-index: -1;
          transform: scale(0.5);
        }

        &:active {
          &:before, &:after {
            opacity: 0.5;
            transition: transform 0.1s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
          }

          &:before {
            transform: scale(0.7);
          }

          &:after {
            transform: scale(1);
          }
        }

        &.active {
          @extend .bg-blue, .white;

          &:before, &:after {
            @extend .bg-white;
          }
        }
      }

      button:first-child {
        img {
          transform: scale(2);
        }
      }

      button:last-child {
        @extend .f8, .pt2;
      }
    }

    > div:last-child {
      @extend .mb13;

      .slider {
        @extend .w8, .pointer-auto;

        .slider-min, .slider-max {
          @extend .dn;
        }

        .input-value {
          width: 2.5rem;
          height: 2.5rem;

          input {
            @extend .bg-white-t40, .blue, .bold, .b-blue, .f7;
          }
        }
      }
    }
  }

  .email-input {
    @extend .f8, .black, .b-black, .w8, .mh2, .input;

    &::placeholder {
      @extend .black-40;
    }
  }

  .checked-user {
    @extend .flex, .bg-white-t40, .br2, .ph8, .pv3;
  }
}
