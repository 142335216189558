.public-card {
  @extend .flex, .flex-column, .pa3;

  .preview {
    @extend .flex, .items-center, .justify-center, .w-100, .flex-grow-1, .pointer, .relative, .mb5;

    > div:first-child {
      @extend .absolute, .absolute--fill, .br3, .overflow-hidden;
    }

    .preview-cover {
      @extend .absolute, .absolute--fill, .white, .flex-column, .justify-center, .items-center, .br3, .tc, .dn, .pt6;
      background: linear-gradient(0deg, rgba(13, 0, 50, 0.79) 50%, rgba(255, 255, 255, 0) 102.23%), rgba(13, 0, 50, 0.13);
      backdrop-filter: blur(12px);

      .play-import-conntainer {
        @extend .flex;
        img {
          @extend .mh2;
        }
      }

      .actions {
        @extend .absolute, .right-1, .bottom-1, .flex, .items-center;
        background: rgba(13, 0, 50, 0.13);

        > * {
          @extend .mh2;
        }
      }

      .state {
        @extend .absolute, .right-0, .bottom--2, .flex, .items-center, .black;

        > p {
          @extend .f10, .semi-bold;
        }

        > img {
          @extend .ml3, .mr1;
        }
      }

      .title {
        @extend .f8, .semi-bold, .pt2;
      }

      .username {
        @extend .f10, .pt2;
      }
    }

    &:hover {
      .preview-cover {
        @extend .flex;
      }
    }
  }

  .user-icon {
    @extend .flex, .items-center, .justify-center, .br-pill, .overflow-hidden, .absolute, .shadow-2, .pointer;

    left: -0.125rem;
    bottom: 1rem;
  }
}