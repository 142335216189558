.form-text-input {
  @extend .relative;

  &.small {
    > div {
      height: 2.25rem;
    }
  }

  &.medium {
    > div {
      height: 3rem;
    }
  }

  &.large {
    > div {
      height: 3.5rem;
    }
  }

  > div {
    @extend .br3, .b-white, .bw2, .b--solid, .flex, .relative, .overflow-hidden;

    > input {
      @extend .bw0, .bg-none, .absolute, .absolute--fill, .pl10, .w-100, .focus-outline-0;
    }

    > div {
      @extend .w4, .flex, .items-center, .justify-center, .z-1;
    }
  }

  > p {
    @extend .red, .pa1, .absolute;
  }
}
