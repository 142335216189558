.select-user-dialog-overlay {
  @extend .z-999, .absolute, .absolute--fill;
}

.select-user-dialog {
  @extend .w-100, .h-100;
}

.select-user-dialog-content {
  @extend .bg-white, .w-100, .h-100, .shadow-3, .br3, .overflow-hidden;

  .dialog-content {
    @extend .bg-gray-t20, .pv6, .ph8;
    height: calc(100% - 2rem);

    .round-button {
      @extend .h3, .bold, .tc, .shadow-1, .bg-white, .mh3;
      min-width: 110px;
    }

    .user-search {
      .search-text {
        @extend .br-pill, .ma0, .lh-4, .ph5, .shadow-1, .bg-white, .tc, .w-100, .h3, .focus-outline-0;
      }

      input, input:hover, input:focus {
        @extend .bw0;
      }
      .search-text::placeholder {
        @extend .gray, .normal;
      }
    }

    .user-title {
      @extend .flex, .items-center, .shadow-1, .br-pill, .bg-white, .z-1, .relative, .mv1, .pl10, .pr3, .h2, .f9;
    }

    .user-avatar-thumb {
      @extend .br-pill, .bg-white, .shadow-1, .flex, .justify-center, .overflow-hidden, .absolute, .z-2, .h3, .w3;
    }
  }
}
