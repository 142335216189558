.bot-widget-overlay {
  @extend .absolute, .absolute--fill, .pointer-none;
  z-index: 1;
}

.bot-widget {
  @extend .h-100, .w-100;

  .command-input {
    @extend .relative, .overflow-hidden;

    input {
      @extend .bw0, .bg-none, .w-100, .focus-outline-0, .f10;
    }

    img {
      @extend .absolute, .right-0, .top-0, .bottom-0, .ma-auto, .z-1;
    }
  }

  img {
    @extend .pointer-none;
  }
}

.bot-widget-rnd {
  @extend .fixed, .top-0, .left-0, .pointer-auto;
  z-index: 1;
}

.bot-widget-content {
  @extend .w-100, .h-100, .flex, .flex-column, .justify-between, .relative, .bg-white, .bw1, .b--solid, .b-gray, .br3, .pa2;
  font-family: 'Open Sans', sans-serif;

  .bot-messages {
    @extend .flex, .flex-column, .pl4, .pr2, .pt3;

    .bot-message, .user-message {
      @extend .flex, .mv1;
      max-width: 80%;

      > div:first-child {
        @extend .flex, .justify-center, .items-center, .br-pill;
        max-width: 1.5rem;
        min-width: 1.5rem;
        max-height: 1.5rem;
        min-height: 1.5rem;
      }

      > div:last-child {
        @extend .pa4, .f10, .black, .br5, .semi-bold, .flex, .flex-column;

        > p:not(:last-child) {
          @extend .mb1;
        }
      }
    }

    .bot-message {
      @extend .self-start, .flex-row;

      > div:first-child {
        @extend .bg-green, .mr3;
      }

      > div:last-child {
        @extend .bg-green-20;
        border-top-left-radius: 0;
      }
    }

    .user-message {
      @extend .self-end, .flex-row-reverse;

      > div:first-child {
        @extend .bg-blue, .ml3;
      }

      > div:last-child {
        @extend .bg-blue-20;
        border-top-right-radius: 0;
      }
    }

    span.loading-dot-1, span.loading-dot-2, span.loading-dot-3 {
      animation-duration: 0.6s;
    }

    span.loading-dot-2 {
      animation-delay: .2s;
    }

    span.loading-dot-3 {
      animation-delay: .4s;
    }

  }
}

