.about-root {
  @extend .w-100, .flex, .flex-column, .items-center, .relative, .overflow-hidden;
  font-family: Poppins, sans-serif;

  .about-header {
    @extend .w-100, .ph7, .flex, .absolute, .left-0, .right-0, .ma-auto, .z-1;
  }

  .about-content {
    @extend .w-90, .flex;
    @extend .items-center-l;
    @extend .flex-column-m, .flex-column-ns, .pt12-m, .pt12-ns;
    height: calc(100vh - 180px);
    max-width: 80rem;

    > div:first-child {
      @extend .flex, .flex-column, .justify-center, .z-1;
      max-width: 560px;
    }

    > div:nth-child(2) {
      @extend .flex, .flex-grow-1, .items-center, .justify-center, .w-100;
      @extend .h-100-l;
    }
  }

  .hero-button {
    @extend .no-underline, .black, .br3, .focus-outline-0, .pointer, .bg-none, .pointer-auto, .bw1, .b--solid, .b-black, .pv2, .ph4;
    font-family: 'Open Sans', sans-serif;
  }

  > video {
    @extend .absolute, .left-0, .right-0, .w-100, .h-100, .fit-cover;
  }

  .womp-logo {
    @extend .flex;

    img {
      @extend .pointer-auto, .pointer;
    }

    p {
      @extend .f9, .semi-bold, .ml2;
      font-family: Poppins, sans-serif;
    }
  }
}

@media only screen and (max-width: 60em) {
  .about-root {
    .about-content {
      height: unset;
      max-width: unset;

      > div:first-child {
        max-width: unset;
      }

      > div:nth-child(2) {
        height: 20rem;
        min-width: unset;
        margin: 2rem 0;
      }
    }
  }
}