
.hero-root {
  @extend .vh-100, .w-100, .relative;
  font-family: Poppins, sans-serif;

  > video {
    @extend .absolute, .absolute--fill, .fit-cover;
    min-width: 100%;
    min-height: 100%;
  }

  .loader {
    @extend .absolute, .absolute--fill, .flex, .items-center, .justify-center, .pointer-none, .z-1;
  }

  .edit {
    @extend .absolute, .absolute--fill, .flex, .flex-column, .justify-between, .pointer-none, .z-1;
  }

  .controls {
    @extend .absolute, .right-4, .top-0, .bottom-0, .ma-auto, .pointer-none, .flex, .flex-column, .justify-center, .black-60;

    > p {
      @extend .f8, .semi-bold, .flex, .items-center;

      > span:first-child {
        min-width: 8rem;
      }

      > span:not(:first-child) {
        @extend .normal;
      }

      > span {
        @extend .f9, .db;
      }
    }
  }

  .points {
    @extend .f10, .semi-bold, .bg-white, .br3, .pv3, .ph5, .flex, .items-center;
  }

  .aspects {
    @extend .black-40;
  }
}