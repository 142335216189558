$spacing-0: 0;
$spacing-1: .25rem; //4
$spacing-2: .375rem; //6
$spacing-3: .5rem; //8
$spacing-4: .75rem; //12
$spacing-5: 1rem; //16
$spacing-6: 1.25rem; //20
$spacing-7: 1.5rem; //24
$spacing-8: 1.75rem; //28
$spacing-9: 2rem; //32
$spacing-10: 3rem; //48
$spacing-11: 4rem; //64
$spacing-12: 6rem; //96
$spacing-13: 8rem; //128
$spacing-14: 12rem; //192
$spacing-15: 16rem; //256

$font-size-1: 3.75rem; //60
$font-size-2: 2.625rem; //42
$font-size-3: 2.25rem; //36
$font-size-4: 1.75rem; //28
$font-size-5: 1.5rem; //24
$font-size-6: 1.25rem; //20
$font-size-7: 1.125rem; //18
$font-size-8: 1rem; //16
$font-size-9: .875rem; //14
$font-size-10: .75rem; //12
$font-size-11: .625rem; //10
$font-size-12: .5625rem; //9
$font-size-13: .5rem; //8
$font-size-14: .375rem; //6
$font-size-15: .25rem; //4

$color-black-100: #070025;
$color-black-o80: #373251;
$color-black-o60: #68647C;
$color-black-o40: #9897A8;
$color-black-o20: #C9C9D3;
$color-black-t80: #070025CC;
$color-black-t60: #07002599;
$color-black-t40: #07002566;
$color-black-t20: #07002533;

$color-blue-100: #593EFF;
$color-blue-o80: #7964FF;
$color-blue-o60: #998AFF;
$color-blue-o40: #B9AFFF;
$color-blue-o20: #D9D5FF;
$color-blue-t80: #593EFFCC;
$color-blue-t60: #593EFF99;
$color-blue-t40: #593EFF66;
$color-blue-t20: #593EFF33;

$color-red-100: #FF5A5A;
$color-red-o80: #FE7A7B;
$color-red-o60: #FD9A9C;
$color-red-o40: #FBBBBD;
$color-red-o20: #F1CCD4;

$color-orange-100: #FFD059;
$color-orange-o80: #FED97A;
$color-orange-o60: #FDE19B;
$color-orange-o40: #FBEABD;
$color-orange-o20: #FAF2DE;

$color-green-100: #58D7A9;
$color-green-o80: #78DEBA;
$color-green-o60: #98E5CB;
$color-green-o40: #B8ECDD;
$color-green-o20: #D9F4EE;

$color-gray-100: #E6E6E6;
$color-gray-o80: #EAEAEB;
$color-gray-o60: #EEEEF0;
$color-gray-o40: #F1F3F5;
$color-gray-o20: #F5F7FA;
$color-gray-t80: #E6E6E6CC;
$color-gray-t60: #E6E6E699;
$color-gray-t40: #E6E6E666;
$color-gray-t20: #E6E6E633;

$color-white-100: #FFFFFF;
$color-white-t80: #FFFFFFCC;
$color-white-t60: #FFFFFF99;
$color-white-t40: #FFFFFF66;
$color-white-t20: #FFFFFF33;

/* Modules */
/*

  BOX SIZING

*/
html, body, div, article, aside, section, main, nav, footer, header, form,
fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt,
dd, blockquote, figcaption, figure, textarea, table, td, th, tr,
input[type="email"], input[type="number"], input[type="password"],
input[type="tel"], input[type="text"], input[type="url"], .border-box { box-sizing: border-box; }
/*

   ASPECT RATIOS

*/
/* This is for fluid media that is embedded from third party sites like youtube, vimeo etc.
 * Wrap the outer element in aspect-ratio and then extend it with the desired ratio i.e
 * Make sure there are no height and width attributes on the embedded media.
 * Adapted from: https://github.com/suitcss/components-flex-embed
 *
 * Example:
 *
 * <div class="aspect-ratio aspect-ratio--16x9">
 *  <iframe class="aspect-ratio--object"></iframe>
 * </div>
 *
 * */
.aspect-ratio { height: 0; position: relative; }
.aspect-ratio--16x9 { padding-bottom: 56.25%; }
.aspect-ratio--9x16 { padding-bottom: 177.77%; }
.aspect-ratio--4x3 { padding-bottom: 75%; }
.aspect-ratio--3x4 { padding-bottom: 133.33%; }
.aspect-ratio--6x4 { padding-bottom: 66.6%; }
.aspect-ratio--4x6 { padding-bottom: 150%; }
.aspect-ratio--8x5 { padding-bottom: 62.5%; }
.aspect-ratio--5x8 { padding-bottom: 160%; }
.aspect-ratio--7x5 { padding-bottom: 71.42%; }
.aspect-ratio--5x7 { padding-bottom: 140%; }
.aspect-ratio--1x1 { padding-bottom: 100%; }
.aspect-ratio--1x1_1{ padding-bottom: 110%; }
.aspect-ratio--object { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; z-index: 100; }
/*

   IMAGES

*/
/* Responsive images! */
img { max-width: 100%; }
/*

   BACKGROUND SIZE

*/
/*
  Often used in combination with background image set as an inline style
  on an html element.
*/
.cover { background-size: cover !important; }
.contain { background-size: contain !important; }
/*

    BACKGROUND POSITION

    Base:
    bg = background

    Modifiers:
    -center = center center
    -top = top center
    -right = center right
    -bottom = bottom center
    -left = center left

 */
.bg-center { background-repeat: no-repeat; background-position: center center; }
.bg-top { background-repeat: no-repeat; background-position: top center; }
.bg-right { background-repeat: no-repeat; background-position: center right; }
.bg-bottom { background-repeat: no-repeat; background-position: bottom center; }
.bg-left { background-repeat: no-repeat; background-position: center left; }
/*

   OUTLINES

*/
.outline { outline: 1px solid; }
.outline-transparent { outline: 1px solid transparent; }
.outline-0 { outline: 0; }
.focus-outline-0 {
  &:focus {
    outline: 0;
  }
}
/*

    BORDERS

    Base:
      b = border

    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none

*/
.ba { border-style: solid; }
.bt { border-top-style: solid; }
.br { border-right-style: solid; }
.bb { border-bottom-style: solid; }
.bl { border-left-style: solid; }
.bn { border-style: none; border-width: 0; }
/*
   SPACING

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none

*/
.pa0 { padding: $spacing-0; }
.pa1 { padding: $spacing-1; }
.pa2 { padding: $spacing-2; }
.pa3 { padding: $spacing-3; }
.pa4 { padding: $spacing-4; }
.pa5 { padding: $spacing-5; }
.pa6 { padding: $spacing-6; }
.pa7 { padding: $spacing-7; }
.pa8 { padding: $spacing-8; }
.pa9 { padding: $spacing-9; }
.pa10 { padding: $spacing-10; }
.pa11 { padding: $spacing-11; }
.pa12 { padding: $spacing-12; }
.pa13 { padding: $spacing-13; }
.pa14 { padding: $spacing-14; }
.pa15 { padding: $spacing-15; }
.pl0 { padding-left: $spacing-0; }
.pl1 { padding-left: $spacing-1; }
.pl2 { padding-left: $spacing-2; }
.pl3 { padding-left: $spacing-3; }
.pl4 { padding-left: $spacing-4; }
.pl5 { padding-left: $spacing-5; }
.pl6 { padding-left: $spacing-6; }
.pl7 { padding-left: $spacing-7; }
.pl8 { padding-left: $spacing-8; }
.pl9 { padding-left: $spacing-9; }
.pl10 { padding-left: $spacing-10; }
.pl11 { padding-left: $spacing-11; }
.pl12 { padding-left: $spacing-12; }
.pl13 { padding-left: $spacing-13; }
.pl14 { padding-left: $spacing-14; }
.pl15 { padding-left: $spacing-15; }
.pr0 { padding-right: $spacing-0; }
.pr1 { padding-right: $spacing-1; }
.pr2 { padding-right: $spacing-2; }
.pr3 { padding-right: $spacing-3; }
.pr4 { padding-right: $spacing-4; }
.pr5 { padding-right: $spacing-5; }
.pr6 { padding-right: $spacing-6; }
.pr7 { padding-right: $spacing-7; }
.pr8 { padding-right: $spacing-8; }
.pr9 { padding-right: $spacing-9; }
.pr10 { padding-right: $spacing-10; }
.pr11 { padding-right: $spacing-11; }
.pr12 { padding-right: $spacing-12; }
.pr13 { padding-right: $spacing-13; }
.pr14 { padding-right: $spacing-14; }
.pr15 { padding-right: $spacing-15; }
.pb0 { padding-bottom: $spacing-0; }
.pb1 { padding-bottom: $spacing-1; }
.pb2 { padding-bottom: $spacing-2; }
.pb3 { padding-bottom: $spacing-3; }
.pb4 { padding-bottom: $spacing-4; }
.pb5 { padding-bottom: $spacing-5; }
.pb6 { padding-bottom: $spacing-6; }
.pb7 { padding-bottom: $spacing-7; }
.pb8 { padding-bottom: $spacing-8; }
.pb9 { padding-bottom: $spacing-9; }
.pb10 { padding-bottom: $spacing-10; }
.pb11 { padding-bottom: $spacing-11; }
.pb12 { padding-bottom: $spacing-12; }
.pb13 { padding-bottom: $spacing-13; }
.pb14 { padding-bottom: $spacing-14; }
.pb15 { padding-bottom: $spacing-15; }
.pt0 { padding-top: $spacing-0; }
.pt1 { padding-top: $spacing-1; }
.pt2 { padding-top: $spacing-2; }
.pt3 { padding-top: $spacing-3; }
.pt4 { padding-top: $spacing-4; }
.pt5 { padding-top: $spacing-5; }
.pt6 { padding-top: $spacing-6; }
.pt7 { padding-top: $spacing-7; }
.pt8 { padding-top: $spacing-8; }
.pt9 { padding-top: $spacing-9; }
.pt10 { padding-top: $spacing-10; }
.pt11 { padding-top: $spacing-11; }
.pt12 { padding-top: $spacing-12; }
.pt13 { padding-top: $spacing-13; }
.pt14 { padding-top: $spacing-14; }
.pt15 { padding-top: $spacing-15; }
.pv0 { padding-top: $spacing-0; padding-bottom: $spacing-0; }
.pv1 { padding-top: $spacing-1; padding-bottom: $spacing-1; }
.pv2 { padding-top: $spacing-2; padding-bottom: $spacing-2; }
.pv3 { padding-top: $spacing-3; padding-bottom: $spacing-3; }
.pv4 { padding-top: $spacing-4; padding-bottom: $spacing-4; }
.pv5 { padding-top: $spacing-5; padding-bottom: $spacing-5; }
.pv6 { padding-top: $spacing-6; padding-bottom: $spacing-6; }
.pv7 { padding-top: $spacing-7; padding-bottom: $spacing-7; }
.pv8 { padding-top: $spacing-8; padding-bottom: $spacing-8; }
.pv9 { padding-top: $spacing-9; padding-bottom: $spacing-9; }
.pv10 { padding-top: $spacing-10; padding-bottom: $spacing-10; }
.pv11 { padding-top: $spacing-11; padding-bottom: $spacing-11; }
.pv12 { padding-top: $spacing-12; padding-bottom: $spacing-12; }
.pv13 { padding-top: $spacing-13; padding-bottom: $spacing-13; }
.pv14 { padding-top: $spacing-14; padding-bottom: $spacing-14; }
.pv15 { padding-top: $spacing-15; padding-bottom: $spacing-15; }
.ph0 { padding-left: $spacing-0; padding-right: $spacing-0; }
.ph1 { padding-left: $spacing-1; padding-right: $spacing-1; }
.ph2 { padding-left: $spacing-2; padding-right: $spacing-2; }
.ph3 { padding-left: $spacing-3; padding-right: $spacing-3; }
.ph4 { padding-left: $spacing-4; padding-right: $spacing-4; }
.ph5 { padding-left: $spacing-5; padding-right: $spacing-5; }
.ph6 { padding-left: $spacing-6; padding-right: $spacing-6; }
.ph7 { padding-left: $spacing-7; padding-right: $spacing-7; }
.ph8 { padding-left: $spacing-8; padding-right: $spacing-8; }
.ph9 { padding-left: $spacing-9; padding-right: $spacing-9; }
.ph10 { padding-left: $spacing-10; padding-right: $spacing-10; }
.ph11 { padding-left: $spacing-11; padding-right: $spacing-11; }
.ph12 { padding-left: $spacing-12; padding-right: $spacing-12; }
.ph13 { padding-left: $spacing-13; padding-right: $spacing-13; }
.ph14 { padding-left: $spacing-14; padding-right: $spacing-14; }
.ph15 { padding-left: $spacing-15; padding-right: $spacing-15; }

.ma-auto { margin: auto; }
.ma0 { margin: $spacing-0; }
.ma1 { margin: $spacing-1; }
.ma2 { margin: $spacing-2; }
.ma3 { margin: $spacing-3; }
.ma4 { margin: $spacing-4; }
.ma5 { margin: $spacing-5; }
.ma6 { margin: $spacing-6; }
.ma7 { margin: $spacing-7; }
.ma8 { margin: $spacing-8; }
.ma9 { margin: $spacing-9; }
.ma10 { margin: $spacing-10; }
.ma11 { margin: $spacing-11; }
.ma12 { margin: $spacing-12; }
.ma13 { margin: $spacing-13; }
.ma14 { margin: $spacing-14; }
.ma15 { margin: $spacing-15; }
.ml-auto { margin-left: auto; }
.ml0 { margin-left: $spacing-0; }
.ml1 { margin-left: $spacing-1; }
.ml2 { margin-left: $spacing-2; }
.ml3 { margin-left: $spacing-3; }
.ml4 { margin-left: $spacing-4; }
.ml5 { margin-left: $spacing-5; }
.ml6 { margin-left: $spacing-6; }
.ml7 { margin-left: $spacing-7; }
.ml8 { margin-left: $spacing-8; }
.ml9 { margin-left: $spacing-9; }
.ml10 { margin-left: $spacing-10; }
.ml11 { margin-left: $spacing-11; }
.ml12 { margin-left: $spacing-12; }
.ml13 { margin-left: $spacing-13; }
.ml14 { margin-left: $spacing-14; }
.ml15 { margin-left: $spacing-15; }
.mr-auto { margin-right: auto; }
.mr0 { margin-right: $spacing-0; }
.mr1 { margin-right: $spacing-1; }
.mr2 { margin-right: $spacing-2; }
.mr3 { margin-right: $spacing-3; }
.mr4 { margin-right: $spacing-4; }
.mr5 { margin-right: $spacing-5; }
.mr6 { margin-right: $spacing-6; }
.mr7 { margin-right: $spacing-7; }
.mr8 { margin-right: $spacing-8; }
.mr9 { margin-right: $spacing-9; }
.mr10 { margin-right: $spacing-10; }
.mr11 { margin-right: $spacing-11; }
.mr12 { margin-right: $spacing-12; }
.mr13 { margin-right: $spacing-13; }
.mr14 { margin-right: $spacing-14; }
.mr15 { margin-right: $spacing-15; }
.mb-auto { margin-bottom: auto; }
.mb0 { margin-bottom: $spacing-0; }
.mb1 { margin-bottom: $spacing-1; }
.mb2 { margin-bottom: $spacing-2; }
.mb3 { margin-bottom: $spacing-3; }
.mb4 { margin-bottom: $spacing-4; }
.mb5 { margin-bottom: $spacing-5; }
.mb6 { margin-bottom: $spacing-6; }
.mb7 { margin-bottom: $spacing-7; }
.mb8 { margin-bottom: $spacing-8; }
.mb9 { margin-bottom: $spacing-9; }
.mb10 { margin-bottom: $spacing-10; }
.mb11 { margin-bottom: $spacing-11; }
.mb12 { margin-bottom: $spacing-12; }
.mb13 { margin-bottom: $spacing-13; }
.mb14 { margin-bottom: $spacing-14; }
.mb15 { margin-bottom: $spacing-15; }
.mt-auto { margin-top: auto; }
.mt0 { margin-top: $spacing-0; }
.mt1 { margin-top: $spacing-1; }
.mt2 { margin-top: $spacing-2; }
.mt3 { margin-top: $spacing-3; }
.mt4 { margin-top: $spacing-4; }
.mt5 { margin-top: $spacing-5; }
.mt6 { margin-top: $spacing-6; }
.mt7 { margin-top: $spacing-7; }
.mt8 { margin-top: $spacing-8; }
.mt9 { margin-top: $spacing-9; }
.mt10 { margin-top: $spacing-10; }
.mt11 { margin-top: $spacing-11; }
.mt12 { margin-top: $spacing-12; }
.mt13 { margin-top: $spacing-13; }
.mt14 { margin-top: $spacing-14; }
.mt15 { margin-top: $spacing-15; }
.mv-auto { margin-top: auto; margin-bottom: auto; }
.mv0 { margin-top: $spacing-0; margin-bottom: $spacing-0; }
.mv1 { margin-top: $spacing-1; margin-bottom: $spacing-1; }
.mv2 { margin-top: $spacing-2; margin-bottom: $spacing-2; }
.mv3 { margin-top: $spacing-3; margin-bottom: $spacing-3; }
.mv4 { margin-top: $spacing-4; margin-bottom: $spacing-4; }
.mv5 { margin-top: $spacing-5; margin-bottom: $spacing-5; }
.mv6 { margin-top: $spacing-6; margin-bottom: $spacing-6; }
.mv7 { margin-top: $spacing-7; margin-bottom: $spacing-7; }
.mv8 { margin-top: $spacing-8; margin-bottom: $spacing-8; }
.mv9 { margin-top: $spacing-9; margin-bottom: $spacing-9; }
.mv10 { margin-top: $spacing-10; margin-bottom: $spacing-10; }
.mv11 { margin-top: $spacing-11; margin-bottom: $spacing-11; }
.mv12 { margin-top: $spacing-12; margin-bottom: $spacing-12; }
.mv13 { margin-top: $spacing-13; margin-bottom: $spacing-13; }
.mv14 { margin-top: $spacing-14; margin-bottom: $spacing-14; }
.mv15 { margin-top: $spacing-15; margin-bottom: $spacing-15; }
.mh-auto { margin-left: auto; margin-right: auto; }
.mh0 { margin-left: $spacing-0; margin-right: $spacing-0; }
.mh1 { margin-left: $spacing-1; margin-right: $spacing-1; }
.mh2 { margin-left: $spacing-2; margin-right: $spacing-2; }
.mh3 { margin-left: $spacing-3; margin-right: $spacing-3; }
.mh4 { margin-left: $spacing-4; margin-right: $spacing-4; }
.mh5 { margin-left: $spacing-5; margin-right: $spacing-5; }
.mh6 { margin-left: $spacing-6; margin-right: $spacing-6; }
.mh7 { margin-left: $spacing-7; margin-right: $spacing-7; }
.mh8 { margin-left: $spacing-8; margin-right: $spacing-8; }
.mh9 { margin-left: $spacing-9; margin-right: $spacing-9; }
.mh10 { margin-left: $spacing-10; margin-right: $spacing-10; }
.mh11 { margin-left: $spacing-11; margin-right: $spacing-11; }
.mh12 { margin-left: $spacing-12; margin-right: $spacing-12; }
.mh13 { margin-left: $spacing-13; margin-right: $spacing-13; }
.mh14 { margin-left: $spacing-14; margin-right: $spacing-14; }
.mh15 { margin-left: $spacing-15; margin-right: $spacing-15; }

/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

*/
.na0 { margin: -$spacing-0; }
.na1 { margin: -$spacing-1; }
.na2 { margin: -$spacing-2; }
.na3 { margin: -$spacing-3; }
.na4 { margin: -$spacing-4; }
.na5 { margin: -$spacing-5; }
.na6 { margin: -$spacing-6; }
.na7 { margin: -$spacing-7; }
.na8 { margin: -$spacing-8; }
.na9 { margin: -$spacing-9; }
.na10 { margin: -$spacing-10; }
.na11 { margin: -$spacing-11; }
.na12 { margin: -$spacing-12; }
.na13 { margin: -$spacing-13; }
.na14 { margin: -$spacing-14; }
.na15 { margin: -$spacing-15; }
.nl0 { margin-left: -$spacing-0; }
.nl1 { margin-left: -$spacing-1; }
.nl2 { margin-left: -$spacing-2; }
.nl3 { margin-left: -$spacing-3; }
.nl4 { margin-left: -$spacing-4; }
.nl5 { margin-left: -$spacing-5; }
.nl6 { margin-left: -$spacing-6; }
.nl7 { margin-left: -$spacing-7; }
.nl8 { margin-left: -$spacing-8; }
.nl9 { margin-left: -$spacing-9; }
.nl10 { margin-left: -$spacing-10; }
.nl11 { margin-left: -$spacing-11; }
.nl12 { margin-left: -$spacing-12; }
.nl13 { margin-left: -$spacing-13; }
.nl14 { margin-left: -$spacing-14; }
.nl15 { margin-left: -$spacing-15; }
.nr0 { margin-right: -$spacing-0; }
.nr1 { margin-right: -$spacing-1; }
.nr2 { margin-right: -$spacing-2; }
.nr3 { margin-right: -$spacing-3; }
.nr4 { margin-right: -$spacing-4; }
.nr5 { margin-right: -$spacing-5; }
.nr6 { margin-right: -$spacing-6; }
.nr7 { margin-right: -$spacing-7; }
.nr8 { margin-right: -$spacing-8; }
.nr9 { margin-right: -$spacing-9; }
.nr10 { margin-right: -$spacing-10; }
.nr11 { margin-right: -$spacing-11; }
.nr12 { margin-right: -$spacing-12; }
.nr13 { margin-right: -$spacing-13; }
.nr14 { margin-right: -$spacing-14; }
.nr15 { margin-right: -$spacing-15; }
.nb0 { margin-bottom: -$spacing-0; }
.nb1 { margin-bottom: -$spacing-1; }
.nb2 { margin-bottom: -$spacing-2; }
.nb3 { margin-bottom: -$spacing-3; }
.nb4 { margin-bottom: -$spacing-4; }
.nb5 { margin-bottom: -$spacing-5; }
.nb6 { margin-bottom: -$spacing-6; }
.nb7 { margin-bottom: -$spacing-7; }
.nb8 { margin-bottom: -$spacing-8; }
.nb9 { margin-bottom: -$spacing-9; }
.nb10 { margin-bottom: -$spacing-10; }
.nb11 { margin-bottom: -$spacing-11; }
.nb12 { margin-bottom: -$spacing-12; }
.nb13 { margin-bottom: -$spacing-13; }
.nb14 { margin-bottom: -$spacing-14; }
.nb15 { margin-bottom: -$spacing-15; }
.nt0 { margin-top: -$spacing-0; }
.nt1 { margin-top: -$spacing-1; }
.nt2 { margin-top: -$spacing-2; }
.nt3 { margin-top: -$spacing-3; }
.nt4 { margin-top: -$spacing-4; }
.nt5 { margin-top: -$spacing-5; }
.nt6 { margin-top: -$spacing-6; }
.nt7 { margin-top: -$spacing-7; }
.nt8 { margin-top: -$spacing-8; }
.nt9 { margin-top: -$spacing-9; }
.nt10 { margin-top: -$spacing-10; }
.nt11 { margin-top: -$spacing-11; }
.nt12 { margin-top: -$spacing-12; }
.nt13 { margin-top: -$spacing-13; }
.nt14 { margin-top: -$spacing-14; }
.nt15 { margin-top: -$spacing-15; }
.nv0 { margin-top: -$spacing-0; margin-bottom: -$spacing-0; }
.nv1 { margin-top: -$spacing-1; margin-bottom: -$spacing-1; }
.nv2 { margin-top: -$spacing-2; margin-bottom: -$spacing-2; }
.nv3 { margin-top: -$spacing-3; margin-bottom: -$spacing-3; }
.nv4 { margin-top: -$spacing-4; margin-bottom: -$spacing-4; }
.nv5 { margin-top: -$spacing-5; margin-bottom: -$spacing-5; }
.nv6 { margin-top: -$spacing-6; margin-bottom: -$spacing-6; }
.nv7 { margin-top: -$spacing-7; margin-bottom: -$spacing-7; }
.nv8 { margin-top: -$spacing-8; margin-bottom: -$spacing-8; }
.nv9 { margin-top: -$spacing-9; margin-bottom: -$spacing-9; }
.nv10 { margin-top: -$spacing-10; margin-bottom: -$spacing-10; }
.nv11 { margin-top: -$spacing-11; margin-bottom: -$spacing-11; }
.nv12 { margin-top: -$spacing-12; margin-bottom: -$spacing-12; }
.nv13 { margin-top: -$spacing-13; margin-bottom: -$spacing-13; }
.nv14 { margin-top: -$spacing-14; margin-bottom: -$spacing-14; }
.nv15 { margin-top: -$spacing-15; margin-bottom: -$spacing-15; }
.nh0 { margin-left: -$spacing-0; margin-right: -$spacing-0; }
.nh1 { margin-left: -$spacing-1; margin-right: -$spacing-1; }
.nh2 { margin-left: -$spacing-2; margin-right: -$spacing-2; }
.nh3 { margin-left: -$spacing-3; margin-right: -$spacing-3; }
.nh4 { margin-left: -$spacing-4; margin-right: -$spacing-4; }
.nh5 { margin-left: -$spacing-5; margin-right: -$spacing-5; }
.nh6 { margin-left: -$spacing-6; margin-right: -$spacing-6; }
.nh7 { margin-left: -$spacing-7; margin-right: -$spacing-7; }
.nh8 { margin-left: -$spacing-8; margin-right: -$spacing-8; }
.nh9 { margin-left: -$spacing-9; margin-right: -$spacing-9; }
.nh10 { margin-left: -$spacing-10; margin-right: -$spacing-10; }
.nh11 { margin-left: -$spacing-11; margin-right: -$spacing-11; }
.nh12 { margin-left: -$spacing-12; margin-right: -$spacing-12; }
.nh13 { margin-left: -$spacing-13; margin-right: -$spacing-13; }
.nh14 { margin-left: -$spacing-14; margin-right: -$spacing-14; }
.nh15 { margin-left: -$spacing-15; margin-right: -$spacing-15; }

/*

   TEXT DECORATION

*/
.strike { text-decoration: line-through; }
.underline { text-decoration: underline; }
.no-underline { text-decoration: none; }

/*

  TEXT ALIGN

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

*/
.tl { text-align: left; }
.tr { text-align: right; }
.tc { text-align: center; }
.tj { text-align: justify; }

/*

   TEXT TRANSFORM

   Base:
     tt = text-transform

   Modifiers
     c = capitalize
     l = lowercase
     u = uppercase
     n = none

*/
.ttc { text-transform: capitalize; }
.ttl { text-transform: lowercase; }
.ttu { text-transform: uppercase; }
.ttn { text-transform: none; }

/*

   TYPE SCALE

   Base:
    f = font-size
*/
.f1, .f-heading-1 { font-size: $font-size-1; }
.f2, .f-heading-2 { font-size: $font-size-2; }
.f3, .f-heading-3 { font-size: $font-size-3; }
.f4, .f-heading-4 { font-size: $font-size-4; }
.f5, .f-heading-5 { font-size: $font-size-5; }
.f6, .f-heading-6 { font-size: $font-size-6; }
.f7, .f-body-large { font-size: $font-size-7; }
.f8, .f-body-medium { font-size: $font-size-8; }
.f9, .f-body-small { font-size: $font-size-9; }
.f10, .f-caption { font-size: $font-size-10; }
.f11 { font-size: $font-size-11; }
.f12 { font-size: $font-size-12; }
.f13 { font-size: $font-size-13; }
.f14 { font-size: $font-size-14; }
.f15 { font-size: $font-size-15; }
/*

   FONT STYLE

*/
.i { font-style: italic; }
.fs-normal { font-style: normal; }
/*

   FONT WEIGHT

   Base
     fw = font-weight

   Modifiers:
     1 = literal value 100
     2 = literal value 200
     3 = literal value 300
     4 = literal value 400
     5 = literal value 500
     6 = literal value 600
     7 = literal value 700
     8 = literal value 800
     9 = literal value 900

*/
.fw1 { font-weight: 100; }
.fw2 { font-weight: 200; }
.fw3 { font-weight: 300; }
.fw4, .normal { font-weight: 400; }
.fw5 { font-weight: 500; }
.fw6, .semi-bold { font-weight: 600; }
.fw7, .bold { font-weight: 700; }
.fw8 { font-weight: 800; }
.fw9, .bolder { font-weight: 900; }

/*

  FLEXBOX

*/
.flex { display: flex; }
.inline-flex { display: inline-flex; }
.flex-auto { flex: 1 1 auto; min-width: 0; /* 1 */ min-height: 0; /* 1 */ }
.flex-none { flex: none; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }
.flex-column-reverse { flex-direction: column-reverse; }
.flex-row-reverse { flex-direction: row-reverse; }
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }
.items-center { align-items: center; }
.items-baseline { align-items: baseline; }
.items-stretch { align-items: stretch; }
.self-start { align-self: flex-start; }
.self-end { align-self: flex-end; }
.self-center { align-self: center; }
.self-baseline { align-self: baseline; }
.self-stretch { align-self: stretch; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }
.content-start { align-content: flex-start; }
.content-end { align-content: flex-end; }
.content-center { align-content: center; }
.content-between { align-content: space-between; }
.content-around { align-content: space-around; }
.content-stretch { align-content: stretch; }
.order-0 { order: 0; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }
.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }
.order-last { order: 99999; }
.flex-grow-0 { flex-grow: 0; }
.flex-grow-1 { flex-grow: 1; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-shrink-1 { flex-shrink: 1; }

/*

   HEIGHTS

   Base:
     h = height
     min-h = min-height
     min-vh = min-height vertical screen height
     vh = vertical screen height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale

     -25   = literal value 25%
     -50   = literal value 50%
     -75   = literal value 75%
     -100  = literal value 100%

     -auto = string value of auto
     -inherit = string value of inherit

*/
/* Height Scale */
.h1 { height: 1rem; }
.h2 { height: 1.5rem; }
.h3 { height: 2rem; }
.h4 { height: 3rem; }
.h5 { height: 4rem; }
.h6 { height: 6rem; }
.h7 { height: 8rem; }
.h8 { height: 12rem; }
.h9 { height: 16rem; }
.h10 { height: 24rem; }
.h11 { height: 32rem; }
.h12 { height: 48rem; }
.h13 { height: 64rem; }
.h14 { height: 96rem; }
.h15 { height: 128rem; }
/* Height Percentages - Based off of height of parent */
.h-25 { height: 25%; }
.h-50 { height: 50%; }
.h-60 { height: 60%; }
.h-65 { height: 65%; }
.h-75 { height: 75%; }
.h-80 { height: 80%; }
.h-90 { height: 90%; }
.h-100 { height: 100%; }
.min-h-100 { min-height: 100%; }
/* Screen Height Percentage */
.vh-25 { height: 25vh; }
.vh-50 { height: 50vh; }
.vh-75 { height: 75vh; }
.vh-100 { height: 100vh; }
.min-vh-100 { min-height: 100vh; }
/* String Properties */
.h-auto { height: auto; }
.h-inherit { height: inherit; }


/*

   WIDTHS

   Base:
     w = width
   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     -10  = literal value 10%
     -20  = literal value 20%
     -25  = literal value 25%
     -30  = literal value 30%
     -33  = literal value 33%
     -34  = literal value 34%
     -40  = literal value 40%
     -50  = literal value 50%
     -60  = literal value 60%
     -70  = literal value 70%
     -75  = literal value 75%
     -80  = literal value 80%
     -90  = literal value 90%
     -100 = literal value 100%
     -third      = 100% / 3 (Not supported in opera mini or IE8)
     -two-thirds = 100% / 1.5 (Not supported in opera mini or IE8)
     -auto       = string value auto

*/
/* Width Scale */
.w1 { width: 1rem; }
.w2 { width: 1.5rem; }
.w3 { width: 2rem; }
.w4 { width: 3rem; }
.w5 { width: 4rem; }
.w6 { width: 6rem; }
.w7 { width: 8rem; }
.w8 { width: 12rem; }
.w9 { width: 16rem; }
.w10 { width: 24rem; }
.w11 { width: 32rem; }
.w12 { width: 48rem; }
.w13 { width: 64rem; }
.w14 { width: 96rem; }
.w15 { width: 128rem; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-48 { width: 48%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }
.w-third { width: 33.33333%; }
.w-two-thirds { width: 66.66667%; }
.w-auto { width: auto; }

/*

    OVERFLOW

 */
.overflow-visible { overflow: visible; }
.overflow-hidden { overflow: hidden; }
.overflow-scroll { overflow: scroll; }
.overflow-auto { overflow: auto; }
.overflow-x-visible { overflow-x: visible; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-scroll { overflow-x: scroll; }
.overflow-x-auto { overflow-x: auto; }
.overflow-y-visible { overflow-y: visible; }
.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-scroll { overflow-y: scroll; }
.overflow-y-auto { overflow-y: auto; }

/*

   LINE HEIGHT / LEADING

*/
.lh-1 { line-height: 1; }
.lh-2 { line-height: 1.25; }
.lh-3 { line-height: 1.5; }
.lh-4 { line-height: 2; }

/*

   POSITIONING

*/
.static { position: static; }
.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }

/*
    OPACITY
*/
.o-100 { opacity: 1; }
.o-90 { opacity: .9; }
.o-80 { opacity: .8; }
.o-70 { opacity: .7; }
.o-60 { opacity: .6; }
.o-50 { opacity: .5; }
.o-40 { opacity: .4; }
.o-30 { opacity: .3; }
.o-20 { opacity: .2; }
.o-10 { opacity: .1; }
.o-05 { opacity: .05; }
.o-025 { opacity: .025; }
.o-0 { opacity: 0; }

/*

   DISPLAY

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    t-row          = table-row
    t-columm       = table-column
    t-column-group = table-column-group

*/
.dn { display: none; }
.di { display: inline; }
.db { display: block; }
.dib { display: inline-block; }
.dit { display: inline-table; }
.dt { display: table; }
.dtc { display: table-cell; }
.dt-row { display: table-row; }
.dt-row-group { display: table-row-group; }
.dt-column { display: table-column; }
.dt-column-group { display: table-column-group; }

/*

   BORDER RADIUS

   Base:
     br   = border-radius

   Modifiers:
     0    = 0/none
     1    = 1st step in scale
     2    = 2nd step in scale
     3    = 3rd step in scale
     4    = 4th step in scale

   Literal values:
     -100 = 100%
     -pill = 9999px

*/
.br0 { border-radius: 0; }
.br1 { border-radius: .125rem; }
.br2 { border-radius: .25rem; }
.br3 { border-radius: .5rem; }
.br4 { border-radius: 1rem; }
.br5 { border-radius: 1.25rem; }
.br-100 { border-radius: 100%; }
.br-pill { border-radius: 9999px; }
.br--bottom { border-top-left-radius: 0; border-top-right-radius: 0; }
.br--top { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
.br--right { border-top-left-radius: 0; border-bottom-left-radius: 0; }
.br--left { border-top-right-radius: 0; border-bottom-right-radius: 0; }

/*

   BORDER STYLES

   Depends on base border module in _borders.css

   Base:
     b = border-style

   Modifiers:
     --none   = none
     --dotted = dotted
     --dashed = dashed
     --solid  = solid

 */
.b--dotted { border-style: dotted; }
.b--dashed { border-style: dashed; }
.b--solid { border-style: solid; }
.b--none { border-style: none; }

/*

   BORDER WIDTHS

   Base:
     bw = border-width

   Modifiers:
     0 = 0 width border
     1 = 1st step in border-width scale
     2 = 2nd step in border-width scale
     3 = 3rd step in border-width scale
     4 = 4th step in border-width scale
     5 = 5th step in border-width scale

*/
.bw0 { border-width: 0; }
.bw1 { border-width: .0626rem; }
.bw2 { border-width: .125rem; }
.bw3 { border-width: .25rem; }
.bw4 { border-width: .5rem; }
.bw5 { border-width: 1rem; }
.bw6 { border-width: 2rem; }
/* Resets */
.bt-0 { border-top-width: 0; }
.br-0 { border-right-width: 0; }
.bb-0 { border-bottom-width: 0; }
.bl-0 { border-left-width: 0; }

/*

  BOX-SHADOW

 */
.shadow-1 {
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 );
  -moz-box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 );
  box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 );
}
.shadow-2 {
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 );
  -moz-box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 );
  box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 );
}
.shadow-3 {
  -webkit-appearance: none;
  -webkit-box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 );
  -moz-box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 );
  box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 );
}
.shadow-4 {
  -webkit-appearance: none;
  -webkit-box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 );
  -moz-box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 );
  box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 );
}
.shadow-5 {
  -webkit-appearance: none;
  -webkit-box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 );
  -moz-box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 );
  box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 );
}

/*

   SKINS

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will
   be set to the current text color.

*/
/* Text colors */

.black { color: $color-black-100; }
.black-80 { color: $color-black-o80; }
.black-60 { color: $color-black-o60; }
.black-40 { color: $color-black-o40; }
.black-20 { color: $color-black-o20; }
.blue { color: $color-blue-100; }
.blue-80 { color: $color-blue-o80; }
.blue-60 { color: $color-blue-o60; }
.blue-40 { color: $color-blue-o40; }
.blue-20 { color: $color-blue-o20; }
.red { color: $color-red-100; }
.red-80 { color: $color-red-o80; }
.red-60 { color: $color-red-o60; }
.red-40 { color: $color-red-o40; }
.red-20 { color: $color-red-o20; }
.orange { color: $color-orange-100; }
.orange-80 { color: $color-orange-o80; }
.orange-60 { color: $color-orange-o60; }
.orange-40 { color: $color-orange-o40; }
.orange-20 { color: $color-orange-o20; }
.green { color: $color-green-100; }
.green-80 { color: $color-green-o80; }
.green-60 { color: $color-green-o60; }
.green-40 { color: $color-green-o40; }
.green-20 { color: $color-green-o20; }
.gray { color: $color-gray-100; }
.gray-80 { color: $color-gray-o80; }
.gray-60 { color: $color-gray-o60; }
.gray-40 { color: $color-gray-o40; }
.gray-20 { color: $color-gray-o20; }
.gray-t80 { color: $color-gray-t80; }
.gray-t60 { color: $color-gray-t60; }
.gray-t40 { color: $color-gray-t40; }
.gray-t20 { color: $color-gray-t20; }
.white { color: $color-white-100; }
.white-t80 { color: $color-white-t80; }
.white-t60 { color: $color-white-t60; }
.white-t40 { color: $color-white-t40; }
.white-t20 { color: $color-white-t20; }

.b-black { border-color: $color-black-100; }
.b-black-80 { border-color: $color-black-o80; }
.b-black-60 { border-color: $color-black-o60; }
.b-black-40 { border-color: $color-black-o40; }
.b-black-20 { border-color: $color-black-o20; }
.b-blue { border-color: $color-blue-100; }
.b-blue-80 { border-color: $color-blue-o80; }
.b-blue-60 { border-color: $color-blue-o60; }
.b-blue-40 { border-color: $color-blue-o40; }
.b-blue-20 { border-color: $color-blue-o20; }
.b-red { border-color: $color-red-100; }
.b-red-80 { border-color: $color-red-o80; }
.b-red-60 { border-color: $color-red-o60; }
.b-red-40 { border-color: $color-red-o40; }
.b-red-20 { border-color: $color-red-o20; }
.b-orange { border-color: $color-orange-100; }
.b-orange-80 { border-color: $color-orange-o80; }
.b-orange-60 { border-color: $color-orange-o60; }
.b-orange-40 { border-color: $color-orange-o40; }
.b-orange-20 { border-color: $color-orange-o20; }
.b-green { border-color: $color-green-100; }
.b-green-80 { border-color: $color-green-o80; }
.b-green-60 { border-color: $color-green-o60; }
.b-green-40 { border-color: $color-green-o40; }
.b-green-20 { border-color: $color-green-o20; }
.b-gray { border-color: $color-gray-100; }
.b-gray-80 { border-color: $color-gray-o80; }
.b-gray-60 { border-color: $color-gray-o60; }
.b-gray-40 { border-color: $color-gray-o40; }
.b-gray-20 { border-color: $color-gray-o20; }
.b-gray-t80 { border-color: $color-gray-t80; }
.b-gray-t60 { border-color: $color-gray-t60; }
.b-gray-t40 { border-color: $color-gray-t40; }
.b-gray-t20 { border-color: $color-gray-t20; }
.b-white { border-color: $color-white-100; }
.b-transparent { border-color: #FFFFFF00; }

.hover-black:hover { color: $color-black-100; }
.hover-black-80:hover { color: $color-black-o80; }
.hover-black-60:hover { color: $color-black-o60; }
.hover-black-40:hover { color: $color-black-o40; }
.hover-black-20:hover { color: $color-black-o20; }
.hover-blue:hover{ color: $color-blue-100; }
.hover-blue-80:hover { color: $color-blue-o80; }
.hover-blue-60:hover { color: $color-blue-o60; }
.hover-blue-40:hover { color: $color-blue-o40; }
.hover-blue-20:hover { color: $color-blue-o20; }
.hover-red:hover{ color: $color-red-100; }
.hover-red-80:hover { color: $color-red-o80; }
.hover-red-60:hover { color: $color-red-o60; }
.hover-red-40:hover { color: $color-red-o40; }
.hover-red-20:hover { color: $color-red-o20; }
.hover-orange:hover{ color: $color-orange-100; }
.hover-orange-80:hover { color: $color-orange-o80; }
.hover-orange-60:hover { color: $color-orange-o60; }
.hover-orange-40:hover { color: $color-orange-o40; }
.hover-orange-20:hover { color: $color-orange-o20; }
.hover-green:hover { color: $color-green-100; }
.hover-green-80:hover { color: $color-green-o80; }
.hover-green-60:hover { color: $color-green-o60; }
.hover-green-40:hover { color: $color-green-o40; }
.hover-green-20:hover { color: $color-green-o20; }
.hover-gray:hover { color: $color-gray-100; }
.hover-gray-80:hover { color: $color-gray-o80; }
.hover-gray-60:hover { color: $color-gray-o60; }
.hover-gray-40:hover { color: $color-gray-o40; }
.hover-gray-20:hover { color: $color-gray-o20; }
.hover-white:hover { color: $color-white-100; }

.focus-black:focus { color: $color-black-100; }
.focus-black-80:focus { color: $color-black-o80; }
.focus-black-60:focus { color: $color-black-o60; }
.focus-black-40:focus { color: $color-black-o40; }
.focus-black-20:focus { color: $color-black-o20; }
.focus-blue:focus { color: $color-blue-100; }
.focus-blue-80:focus { color: $color-blue-o80; }
.focus-blue-60:focus { color: $color-blue-o60; }
.focus-blue-40:focus { color: $color-blue-o40; }
.focus-blue-20:focus { color: $color-blue-o20; }
.focus-red:focus { color: $color-red-100; }
.focus-red-80:focus { color: $color-red-o80; }
.focus-red-60:focus { color: $color-red-o60; }
.focus-red-40:focus { color: $color-red-o40; }
.focus-red-20:focus { color: $color-red-o20; }
.focus-orange:focus { color: $color-orange-100; }
.focus-orange-80:focus { color: $color-orange-o80; }
.focus-orange-60:focus { color: $color-orange-o60; }
.focus-orange-40:focus { color: $color-orange-o40; }
.focus-orange-20:focus { color: $color-orange-o20; }
.focus-green:focus { color: $color-green-100; }
.focus-green-80:focus { color: $color-green-o80; }
.focus-green-60:focus { color: $color-green-o60; }
.focus-green-40:focus { color: $color-green-o40; }
.focus-green-20:focus { color: $color-green-o20; }
.focus-gray:focus { color: $color-gray-100; }
.focus-gray-80:focus { color: $color-gray-o80; }
.focus-gray-60:focus { color: $color-gray-o60; }
.focus-gray-40:focus { color: $color-gray-o40; }
.focus-gray-20:focus { color: $color-gray-o20; }
.focus-white:focus { color: $color-white-100; }

.bg-unset { background: unset; }
.bg-none { background: none; }
.bg-black { background-color: $color-black-100; }
.bg-black-80 { background-color: $color-black-o80; }
.bg-black-60 { background-color: $color-black-o60; }
.bg-black-40 { background-color: $color-black-o40; }
.bg-black-20 { background-color: $color-black-o20; }
.bg-black-t80 { background-color: $color-black-t80; }
.bg-black-t60 { background-color: $color-black-t60; }
.bg-black-t40 { background-color: $color-black-t40; }
.bg-black-t20 { background-color: $color-black-t20; }
.bg-blue { background-color: $color-blue-100; }
.bg-blue-80 { background-color: $color-blue-o80; }
.bg-blue-60 { background-color: $color-blue-o60; }
.bg-blue-40 { background-color: $color-blue-o40; }
.bg-blue-20 { background-color: $color-blue-o20; }
.bg-blue-t80 { background-color: $color-blue-t80; }
.bg-blue-t60 { background-color: $color-blue-t60; }
.bg-blue-t40 { background-color: $color-blue-t40; }
.bg-blue-t20 { background-color: $color-blue-t20; }
.bg-red { background-color: $color-red-100; }
.bg-red-80 { background-color: $color-red-o80; }
.bg-red-60 { background-color: $color-red-o60; }
.bg-red-40 { background-color: $color-red-o40; }
.bg-red-20 { background-color: $color-red-o20; }
.bg-orange { background-color: $color-orange-100; }
.bg-orange-80 { background-color: $color-orange-o80; }
.bg-orange-60 { background-color: $color-orange-o60; }
.bg-orange-40 { background-color: $color-orange-o40; }
.bg-orange-20 { background-color: $color-orange-o20; }
.bg-green { background-color: $color-green-100; }
.bg-green-80 { background-color: $color-green-o80; }
.bg-green-60 { background-color: $color-green-o60; }
.bg-green-40 { background-color: $color-green-o40; }
.bg-green-20 { background-color: $color-green-o20; }
.bg-gray { background-color: $color-gray-100; }
.bg-gray-80 { background-color: $color-gray-o80; }
.bg-gray-60 { background-color: $color-gray-o60; }
.bg-gray-40 { background-color: $color-gray-o40; }
.bg-gray-20 { background-color: $color-gray-o20; }
.bg-gray-t80 { background-color: $color-gray-t80; }
.bg-gray-t60 { background-color: $color-gray-t60; }
.bg-gray-t40 { background-color: $color-gray-t40; }
.bg-gray-t20 { background-color: $color-gray-t20; }
.bg-white { background-color: $color-white-100; }
.bg-white-t80 { background-color: $color-white-t80; }
.bg-white-t60 { background-color: $color-white-t60; }
.bg-white-t40 { background-color: $color-white-t40; }
.bg-white-t20 { background-color: $color-white-t20; }

.hover-bg-black:hover { background-color: $color-black-100; }
.hover-bg-black-80:hover { background-color: $color-black-o80; }
.hover-bg-black-60:hover { background-color: $color-black-o60; }
.hover-bg-black-40:hover { background-color: $color-black-o40; }
.hover-bg-black-20:hover { background-color: $color-black-o20; }
.hover-bg-blue:hover { background-color: $color-blue-100; }
.hover-bg-blue-80:hover { background-color: $color-blue-o80; }
.hover-bg-blue-60:hover { background-color: $color-blue-o60; }
.hover-bg-blue-40:hover { background-color: $color-blue-o40; }
.hover-bg-blue-20:hover { background-color: $color-blue-o20; }
.hover-bg-red:hover { background-color: $color-red-100; }
.hover-bg-red-80:hover { background-color: $color-red-o80; }
.hover-bg-red-60:hover { background-color: $color-red-o60; }
.hover-bg-red-40:hover { background-color: $color-red-o40; }
.hover-bg-red-20:hover { background-color: $color-red-o20; }
.hover-bg-orange:hover { background-color: $color-orange-100; }
.hover-bg-orange-80:hover { background-color: $color-orange-o80; }
.hover-bg-orange-60:hover { background-color: $color-orange-o60; }
.hover-bg-orange-40:hover { background-color: $color-orange-o40; }
.hover-bg-orange-20:hover { background-color: $color-orange-o20; }
.hover-bg-green:hover { background-color: $color-green-100; }
.hover-bg-green-80:hover { background-color: $color-green-o80; }
.hover-bg-green-60:hover { background-color: $color-green-o60; }
.hover-bg-green-40:hover { background-color: $color-green-o40; }
.hover-bg-green-20:hover { background-color: $color-green-o20; }
.hover-bg-gray:hover { background-color: $color-gray-100; }
.hover-bg-gray-80:hover { background-color: $color-gray-o80; }
.hover-bg-gray-60:hover { background-color: $color-gray-o60; }
.hover-bg-gray-40:hover { background-color: $color-gray-o40; }
.hover-bg-gray-20:hover { background-color: $color-gray-o20; }
.hover-bg-white:hover { background-color: $color-white-100; }

.focus-bg-black:focus { background-color: $color-black-100; }
.focus-bg-black-80:focus { background-color: $color-black-o80; }
.focus-bg-black-60:focus { background-color: $color-black-o60; }
.focus-bg-black-40:focus { background-color: $color-black-o40; }
.focus-bg-black-20:focus { background-color: $color-black-o20; }
.focus-bg-blue:focus { background-color: $color-blue-100; }
.focus-bg-blue-80:focus { background-color: $color-blue-o80; }
.focus-bg-blue-60:focus { background-color: $color-blue-o60; }
.focus-bg-blue-40:focus { background-color: $color-blue-o40; }
.focus-bg-blue-20:focus { background-color: $color-blue-o20; }
.focus-bg-red:focus { background-color: $color-red-100; }
.focus-bg-red-80:focus { background-color: $color-red-o80; }
.focus-bg-red-60:focus { background-color: $color-red-o60; }
.focus-bg-red-40:focus { background-color: $color-red-o40; }
.focus-bg-red-20:focus { background-color: $color-red-o20; }
.focus-bg-orange:focus { background-color: $color-orange-100; }
.focus-bg-orange-80:focus { background-color: $color-orange-o80; }
.focus-bg-orange-60:focus { background-color: $color-orange-o60; }
.focus-bg-orange-40:focus { background-color: $color-orange-o40; }
.focus-bg-orange-20:focus { background-color: $color-orange-o20; }
.focus-bg-green:focus { background-color: $color-green-100; }
.focus-bg-green-80:focus { background-color: $color-green-o80; }
.focus-bg-green-60:focus { background-color: $color-green-o60; }
.focus-bg-green-40:focus { background-color: $color-green-o40; }
.focus-bg-green-20:focus { background-color: $color-green-o20; }
.focus-bg-gray:focus { background-color: $color-gray-100; }
.focus-bg-gray-80:focus { background-color: $color-gray-o80; }
.focus-bg-gray-60:focus { background-color: $color-gray-o60; }
.focus-bg-gray-40:focus { background-color: $color-gray-o40; }
.focus-bg-gray-20:focus { background-color: $color-gray-o20; }
.focus-bg-white:focus { background-color: $color-white-100; }

/*
  Z-INDEX
  Base
    z = z-index
  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
    -4 = literal value 4
    -5 = literal value 5
    -999 = literal value 999
    -9999 = literal value 9999
    -max = largest accepted z-index value as integer
    -inherit = string value inherit
    -initial = string value initial
    -unset = string value unset
  MDN: https://developer.mozilla.org/en/docs/Web/CSS/z-index
  Spec: http://www.w3.org/TR/CSS2/zindex.html
  Articles:
    https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
  Tips on extending:
  There might be a time worth using negative z-index values.
  Or if you are using tachyons with another project, you might need to
  adjust these values to suit your needs.
*/
.z--1 { z-index: -1; }
.z-0 { z-index: 0; }
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }
.z-3 { z-index: 3; }
.z-4 { z-index: 4; }
.z-5 { z-index: 5; }
.z-999 { z-index: 999; }
.z-1000 { z-index: 1000; }
.z-9999 { z-index: 9999; }
.z-max { z-index: 2147483647; }
.z-inherit { z-index: inherit; }
.z-initial { z-index: initial; }
.z-unset { z-index: unset; }

/*

   COORDINATES

   Use in combination with the position module.

   Base:
     top
     bottom
     right
     left

   Modifiers:
     -0  = literal value 0
     -1  = literal value 1
     -2  = literal value 2
     --1 = literal value -1
     --2 = literal value -2

*/
.top-0 { top: 0; }
.right-0 { right: 0; }
.bottom-0 { bottom: 0; }
.left-0 { left: 0; }
.top-1 { top: .5rem; }
.right-1 { right: .5rem; }
.bottom-1 { bottom: .5rem; }
.left-1 { left: .5rem; }
.top-2 { top: 1rem; }
.right-2 { right: 1rem; }
.bottom-2 { bottom: 1rem; }
.left-2 { left: 1rem; }
.top-3 { top: 1.5rem; }
.right-3 { right: 1.5rem; }
.bottom-3 { bottom: 1.5rem; }
.left-3 { left: 1.5rem; }
.top-4 { top: 2rem; }
.right-4 { right: 2rem; }
.bottom-4 { bottom: 2rem; }
.left-4 { left: 2rem; }
.top--1 { top: -.5rem; }
.right--1 { right: -.5rem; }
.bottom--1 { bottom: -.5rem; }
.left--1 { left: -.5rem; }
.top--2 { top: -1rem; }
.right--2 { right: -1rem; }
.bottom--2 { bottom: -1rem; }
.left--2 { left: -1rem; }
.top--3 { top: -1.5rem; }
.right--3 { right: -1.5rem; }
.bottom--3 { bottom: -1.5rem; }
.left--3 { left: -1.5rem; }
.top--4 { top: -2rem; }
.right--4 { right: -2rem; }
.bottom--4 { bottom: -2rem; }
.left--4 { left: -2rem; }
.absolute--fill { top: 0; right: 0; bottom: 0; left: 0; }

/*

   VERTICAL ALIGN

*/
.v-base { vertical-align: baseline; }
.v-mid { vertical-align: middle; }
.v-top { vertical-align: top; }
.v-btm { vertical-align: bottom; }

/* Add pointer on hover */
.pointer:hover { cursor: pointer; }
.row-resize:hover {cursor: row-resize; }
.col-resize:hover {cursor: col-resize; }
.cursor-default:hover { cursor: default; }

.hide-cursor {cursor: none !important;}
.show-cursor {cursor: auto !important;}

.select-disable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-enable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.pointer-none { pointer-events: none; }

.pointer-auto { pointer-events: auto; }

.pointer-all { pointer-events: all; }

.fit-cover { object-fit: cover; }
.fit-contain { object-fit: contain; }
.fit-none { object-fit: none; }

.no-wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.visible { visibility: visible; }

.hidden { visibility: hidden; }

@media only screen and (max-width: 30em) {
  .aspect-ratio-ns { height: 0; position: relative; }
  .aspect-ratio--16x9-ns { padding-bottom: 56.25%; }
  .aspect-ratio--9x16-ns { padding-bottom: 177.77%; }
  .aspect-ratio--4x3-ns { padding-bottom: 75%; }
  .aspect-ratio--3x4-ns { padding-bottom: 133.33%; }
  .aspect-ratio--6x4-ns { padding-bottom: 66.6%; }
  .aspect-ratio--4x6-ns { padding-bottom: 150%; }
  .aspect-ratio--8x5-ns { padding-bottom: 62.5%; }
  .aspect-ratio--5x8-ns { padding-bottom: 160%; }
  .aspect-ratio--7x5-ns { padding-bottom: 71.42%; }
  .aspect-ratio--5x7-ns { padding-bottom: 140%; }
  .aspect-ratio--1x1-ns { padding-bottom: 100%; }
  .aspect-ratio--object-ns { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; z-index: 100; }
  .cover-ns { background-size: cover !important; }
  .contain-ns { background-size: contain !important; }
  .bg-center-ns { background-repeat: no-repeat; background-position: center center; }
  .bg-top-ns { background-repeat: no-repeat; background-position: top center; }
  .bg-right-ns { background-repeat: no-repeat; background-position: center right; }
  .bg-bottom-ns { background-repeat: no-repeat; background-position: bottom center; }
  .bg-left-ns { background-repeat: no-repeat; background-position: center left; }
  .outline-ns { outline: 1px solid; }
  .outline-transparent-ns { outline: 1px solid transparent; }
  .outline-0-ns { outline: 0; }
  .ba-ns { border-style: solid; }
  .bt-ns { border-top-style: solid; }
  .br-ns { border-right-style: solid; }
  .bb-ns { border-bottom-style: solid; }
  .bl-ns { border-left-style: solid; }
  .bn-ns { border-style: none; border-width: 0; }
  .br0-ns { border-radius: 0; }
  .br1-ns { border-radius: .125rem; }
  .br2-ns { border-radius: .25rem; }
  .br3-ns { border-radius: .5rem; }
  .br4-ns { border-radius: 1rem; }
  .br-100-ns { border-radius: 100%; }
  .br-pill-ns { border-radius: 9999px; }
  .br--bottom-ns { border-top-left-radius: 0; border-top-right-radius: 0; }
  .br--top-ns { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  .br--right-ns { border-top-left-radius: 0; border-bottom-left-radius: 0; }
  .br--left-ns { border-top-right-radius: 0; border-bottom-right-radius: 0; }
  .b--dotted-ns { border-style: dotted; }
  .b--dashed-ns { border-style: dashed; }
  .b--solid-ns { border-style: solid; }
  .b--none-ns { border-style: none; }
  .bw0-ns { border-width: 0; }
  .bw1-ns { border-width: 1px; }
  .bw2-ns { border-width: .25rem; }
  .bw3-ns { border-width: .5rem; }
  .bw4-ns { border-width: 1rem; }
  .bw5-ns { border-width: 2rem; }
  .bt-0-ns { border-top-width: 0; }
  .br-0-ns { border-right-width: 0; }
  .bb-0-ns { border-bottom-width: 0; }
  .bl-0-ns { border-left-width: 0; }
  .shadow-1-ns { box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-2-ns { box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-3-ns { box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-4-ns { box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 ); }
  .shadow-5-ns { box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 ); }
  .top-0-ns { top: 0; }
  .right-0-ns { right: 0; }
  .bottom-0-ns { bottom: 0; }
  .left-0-ns { left: 0; }
  .top-1-ns { top: .5rem; }
  .right-1-ns { right: .5rem; }
  .bottom-1-ns { bottom: .5rem; }
  .left-1-ns { left: .5rem; }
  .top-2-ns { top: 1rem; }
  .right-2-ns { right: 1rem; }
  .bottom-2-ns { bottom: 1rem; }
  .left-2-ns { left: 1rem; }
  .top-3-ns { top: 1.5rem; }
  .right-3-ns { right: 1.5rem; }
  .bottom-3-ns { bottom: 1.5rem; }
  .left-3-ns { left: 1.5rem; }
  .top-4-ns { top: 2rem; }
  .right-4-ns { right: 2rem; }
  .bottom-4-ns { bottom: 2rem; }
  .left-4-ns { left: 2rem; }
  .top--1-ns { top: -.5rem; }
  .right--1-ns { right: -.5rem; }
  .bottom--1-ns { bottom: -.5rem; }
  .left--1-ns { left: -.5rem; }
  .top--2-ns { top: -1rem; }
  .right--2-ns { right: -1rem; }
  .bottom--2-ns { bottom: -1rem; }
  .left--2-ns { left: -1rem; }
  .top--3-ns { top: -1.5rem; }
  .right--3-ns { right: -1.5rem; }
  .bottom--3-ns { bottom: -1.5rem; }
  .left--3-ns { left: -1.5rem; }
  .top--4-ns { top: -2rem; }
  .right--4-ns { right: -2rem; }
  .bottom--4-ns { bottom: -2rem; }
  .absolute--fill-ns { top: 0; right: 0; bottom: 0; left: 0; }
  .cl-ns { clear: left; }
  .cr-ns { clear: right; }
  .cb-ns { clear: both; }
  .cn-ns { clear: none; }
  .dn-ns { display: none; }
  .di-ns { display: inline; }
  .db-ns { display: block; }
  .dib-ns { display: inline-block; }
  .dit-ns { display: inline-table; }
  .dt-ns { display: table; }
  .dtc-ns { display: table-cell; }
  .dt-row-ns { display: table-row; }
  .dt-row-group-ns { display: table-row-group; }
  .dt-column-ns { display: table-column; }
  .dt-column-group-ns { display: table-column-group; }
  .dt--fixed-ns { table-layout: fixed; width: 100%; }
  .flex-ns { display: flex; }
  .inline-flex-ns { display: inline-flex; }
  .flex-auto-ns { flex: 1 1 auto; min-width: 0; /* 1 */ min-height: 0; /* 1 */ }
  .flex-none-ns { flex: none; }
  .flex-column-ns { flex-direction: column; }
  .flex-row-ns { flex-direction: row; }
  .flex-wrap-ns { flex-wrap: wrap; }
  .flex-nowrap-ns { flex-wrap: nowrap; }
  .flex-wrap-reverse-ns { flex-wrap: wrap-reverse; }
  .flex-column-reverse-ns { flex-direction: column-reverse; }
  .flex-row-reverse-ns { flex-direction: row-reverse; }
  .items-start-ns { align-items: flex-start; }
  .items-end-ns { align-items: flex-end; }
  .items-center-ns { align-items: center; }
  .items-baseline-ns { align-items: baseline; }
  .items-stretch-ns { align-items: stretch; }
  .self-start-ns { align-self: flex-start; }
  .self-end-ns { align-self: flex-end; }
  .self-center-ns { align-self: center; }
  .self-baseline-ns { align-self: baseline; }
  .self-stretch-ns { align-self: stretch; }
  .justify-start-ns { justify-content: flex-start; }
  .justify-end-ns { justify-content: flex-end; }
  .justify-center-ns { justify-content: center; }
  .justify-between-ns { justify-content: space-between; }
  .justify-around-ns { justify-content: space-around; }
  .content-start-ns { align-content: flex-start; }
  .content-end-ns { align-content: flex-end; }
  .content-center-ns { align-content: center; }
  .content-between-ns { align-content: space-between; }
  .content-around-ns { align-content: space-around; }
  .content-stretch-ns { align-content: stretch; }
  .order-0-ns { order: 0; }
  .order-1-ns { order: 1; }
  .order-2-ns { order: 2; }
  .order-3-ns { order: 3; }
  .order-4-ns { order: 4; }
  .order-5-ns { order: 5; }
  .order-6-ns { order: 6; }
  .order-7-ns { order: 7; }
  .order-8-ns { order: 8; }
  .order-last-ns { order: 99999; }
  .flex-grow-0-ns { flex-grow: 0; }
  .flex-grow-1-ns { flex-grow: 1; }
  .flex-shrink-0-ns { flex-shrink: 0; }
  .flex-shrink-1-ns { flex-shrink: 1; }
  .fl-ns { float: left; _display: inline; }
  .fr-ns { float: right; _display: inline; }
  .fn-ns { float: none; }
  .i-ns { font-style: italic; }
  .fs-normal-ns { font-style: normal; }
  .fw1-ns { font-weight: 100; }
  .fw2-ns { font-weight: 200; }
  .fw3-ns { font-weight: 300; }
  .fw4-ns, .normal-ns { font-weight: 400; }
  .fw5-ns { font-weight: 500; }
  .fw6-ns, .semi-bold-ns { font-weight: 600; }
  .fw7-ns, .bold-ns { font-weight: 700; }
  .fw8-ns { font-weight: 800; }
  .fw9-ns, .bolder-ns { font-weight: 900; }
  .h1-ns { height: 1rem; }
  .h2-ns { height: 1.5rem; }
  .h3-ns { height: 2rem; }
  .h4-ns { height: 3rem; }
  .h5-ns { height: 4rem; }
  .h6-ns { height: 6rem; }
  .h7-ns { height: 8rem; }
  .h8-ns { height: 12rem; }
  .h9-ns { height: 16rem; }
  .h10-ns { height: 24rem; }
  .h11-ns { height: 32rem; }
  .h12-ns { height: 48rem; }
  .h13-ns { height: 64rem; }
  .h14-ns { height: 96rem; }
  .h15-ns { height: 128rem; }
  .h-25-ns { height: 25%; }
  .h-50-ns { height: 50%; }
  .h-60-ns { height: 60%; }
  .h-65-ns { height: 65%; }
  .h-75-ns { height: 75%; }
  .h-80-ns { height: 80%; }
  .h-90-ns { height: 90%; }
  .h-100-ns { height: 100%; }
  .min-h-100-ns { min-height: 100%; }
  .vh-25-ns { height: 25vh; }
  .vh-50-ns { height: 50vh; }
  .vh-75-ns { height: 75vh; }
  .vh-100-ns { height: 100vh; }
  .min-vh-100-ns { min-height: 100vh; }
  .h-auto-ns { height: auto; }
  .h-inherit-ns { height: inherit; }
  .lh-1-ns { line-height: 1; }
  .lh-2-ns { line-height: 1.25; }
  .lh-3-ns { line-height: 1.5; }
  .lh-4-ns { line-height: 2; }
  .w1-ns { width: 1rem; }
  .w2-ns { width: 1.5rem; }
  .w3-ns { width: 2rem; }
  .w4-ns { width: 3rem; }
  .w5-ns { width: 4rem; }
  .w6-ns { width: 6rem; }
  .w7-ns { width: 8rem; }
  .w8-ns { width: 12rem; }
  .w9-ns { width: 16rem; }
  .w10-ns { width: 24rem; }
  .w11-ns { width: 32rem; }
  .w12-ns { width: 48rem; }
  .w13-ns { width: 64rem; }
  .w14-ns { width: 96rem; }
  .w15-ns { width: 128rem; }
  .w-10-ns { width: 10%; }
  .w-15-ns { width: 15%; }
  .w-20-ns { width: 20%; }
  .w-25-ns { width: 25%; }
  .w-30-ns { width: 30%; }
  .w-35-ns { width: 35%; }
  .w-40-ns { width: 40%; }
  .w-45-ns { width: 45%; }
  .w-48-ns { width: 48%; }
  .w-50-ns { width: 50%; }
  .w-55-ns { width: 55%; }
  .w-60-ns { width: 60%; }
  .w-65-ns { width: 65%; }
  .w-70-ns { width: 70%; }
  .w-75-ns { width: 75%; }
  .w-80-ns { width: 80%; }
  .w-85-ns { width: 85%; }
  .w-90-ns { width: 90%; }
  .w-95-ns { width: 95%; }
  .w-100-ns { width: 100%; }
  .w-third-ns { width: 33.33333%; }
  .w-two-thirds-ns { width: 66.66667%; }
  .w-auto-ns { width: auto; }
  .overflow-visible-ns { overflow: visible; }
  .overflow-hidden-ns { overflow: hidden; }
  .overflow-scroll-ns { overflow: scroll; }
  .overflow-auto-ns { overflow: auto; }
  .overflow-x-visible-ns { overflow-x: visible; }
  .overflow-x-hidden-ns { overflow-x: hidden; }
  .overflow-x-scroll-ns { overflow-x: scroll; }
  .overflow-x-auto-ns { overflow-x: auto; }
  .overflow-y-visible-ns { overflow-y: visible; }
  .overflow-y-hidden-ns { overflow-y: hidden; }
  .overflow-y-scroll-ns { overflow-y: scroll; }
  .overflow-y-auto-ns { overflow-y: auto; }
  .static-ns { position: static; }
  .relative-ns { position: relative; }
  .absolute-ns { position: absolute; }
  .fixed-ns { position: fixed; }
  .pa0-ns { padding: $spacing-0; }
  .pa1-ns { padding: $spacing-1; }
  .pa2-ns { padding: $spacing-2; }
  .pa3-ns { padding: $spacing-3; }
  .pa4-ns { padding: $spacing-4; }
  .pa5-ns { padding: $spacing-5; }
  .pa6-ns { padding: $spacing-6; }
  .pa7-ns { padding: $spacing-7; }
  .pa8-ns { padding: $spacing-8; }
  .pa9-ns { padding: $spacing-9; }
  .pa10-ns { padding: $spacing-10; }
  .pa11-ns { padding: $spacing-11; }
  .pa12-ns { padding: $spacing-12; }
  .pa13-ns { padding: $spacing-13; }
  .pa14-ns { padding: $spacing-14; }
  .pa15-ns { padding: $spacing-15; }
  .pl0-ns { padding-left: $spacing-0; }
  .pl1-ns { padding-left: $spacing-1; }
  .pl2-ns { padding-left: $spacing-2; }
  .pl3-ns { padding-left: $spacing-3; }
  .pl4-ns { padding-left: $spacing-4; }
  .pl5-ns { padding-left: $spacing-5; }
  .pl6-ns { padding-left: $spacing-6; }
  .pl7-ns { padding-left: $spacing-7; }
  .pl8-ns { padding-left: $spacing-8; }
  .pl9-ns { padding-left: $spacing-9; }
  .pl10-ns { padding-left: $spacing-10; }
  .pl11-ns { padding-left: $spacing-11; }
  .pl12-ns { padding-left: $spacing-12; }
  .pl13-ns { padding-left: $spacing-13; }
  .pl14-ns { padding-left: $spacing-14; }
  .pl15-ns { padding-left: $spacing-15; }
  .pr0-ns { padding-right: $spacing-0; }
  .pr1-ns { padding-right: $spacing-1; }
  .pr2-ns { padding-right: $spacing-2; }
  .pr3-ns { padding-right: $spacing-3; }
  .pr4-ns { padding-right: $spacing-4; }
  .pr5-ns { padding-right: $spacing-5; }
  .pr6-ns { padding-right: $spacing-6; }
  .pr7-ns { padding-right: $spacing-7; }
  .pr8-ns { padding-right: $spacing-8; }
  .pr9-ns { padding-right: $spacing-9; }
  .pr10-ns { padding-right: $spacing-10; }
  .pr11-ns { padding-right: $spacing-11; }
  .pr12-ns { padding-right: $spacing-12; }
  .pr13-ns { padding-right: $spacing-13; }
  .pr14-ns { padding-right: $spacing-14; }
  .pr15-ns { padding-right: $spacing-15; }
  .pb0-ns { padding-bottom: $spacing-0; }
  .pb1-ns { padding-bottom: $spacing-1; }
  .pb2-ns { padding-bottom: $spacing-2; }
  .pb3-ns { padding-bottom: $spacing-3; }
  .pb4-ns { padding-bottom: $spacing-4; }
  .pb5-ns { padding-bottom: $spacing-5; }
  .pb6-ns { padding-bottom: $spacing-6; }
  .pb7-ns { padding-bottom: $spacing-7; }
  .pb8-ns { padding-bottom: $spacing-8; }
  .pb9-ns { padding-bottom: $spacing-9; }
  .pb10-ns { padding-bottom: $spacing-10; }
  .pb11-ns { padding-bottom: $spacing-11; }
  .pb12-ns { padding-bottom: $spacing-12; }
  .pb13-ns { padding-bottom: $spacing-13; }
  .pb14-ns { padding-bottom: $spacing-14; }
  .pb15-ns { padding-bottom: $spacing-15; }
  .pt0-ns { padding-top: $spacing-0; }
  .pt1-ns { padding-top: $spacing-1; }
  .pt2-ns { padding-top: $spacing-2; }
  .pt3-ns { padding-top: $spacing-3; }
  .pt4-ns { padding-top: $spacing-4; }
  .pt5-ns { padding-top: $spacing-5; }
  .pt6-ns { padding-top: $spacing-6; }
  .pt7-ns { padding-top: $spacing-7; }
  .pt8-ns { padding-top: $spacing-8; }
  .pt9-ns { padding-top: $spacing-9; }
  .pt10-ns { padding-top: $spacing-10; }
  .pt11-ns { padding-top: $spacing-11; }
  .pt12-ns { padding-top: $spacing-12; }
  .pt13-ns { padding-top: $spacing-13; }
  .pt14-ns { padding-top: $spacing-14; }
  .pt15-ns { padding-top: $spacing-15; }
  .pv0-ns { padding-top: $spacing-0; padding-bottom: $spacing-0; }
  .pv1-ns { padding-top: $spacing-1; padding-bottom: $spacing-1; }
  .pv2-ns { padding-top: $spacing-2; padding-bottom: $spacing-2; }
  .pv3-ns { padding-top: $spacing-3; padding-bottom: $spacing-3; }
  .pv4-ns { padding-top: $spacing-4; padding-bottom: $spacing-4; }
  .pv5-ns { padding-top: $spacing-5; padding-bottom: $spacing-5; }
  .pv6-ns { padding-top: $spacing-6; padding-bottom: $spacing-6; }
  .pv7-ns { padding-top: $spacing-7; padding-bottom: $spacing-7; }
  .pv8-ns { padding-top: $spacing-8; padding-bottom: $spacing-8; }
  .pv9-ns { padding-top: $spacing-9; padding-bottom: $spacing-9; }
  .pv10-ns { padding-top: $spacing-10; padding-bottom: $spacing-10; }
  .pv11-ns { padding-top: $spacing-11; padding-bottom: $spacing-11; }
  .pv12-ns { padding-top: $spacing-12; padding-bottom: $spacing-12; }
  .pv13-ns { padding-top: $spacing-13; padding-bottom: $spacing-13; }
  .pv14-ns { padding-top: $spacing-14; padding-bottom: $spacing-14; }
  .pv15-ns { padding-top: $spacing-15; padding-bottom: $spacing-15; }
  .ph0-ns { padding-left: $spacing-0; padding-right: $spacing-0; }
  .ph1-ns { padding-left: $spacing-1; padding-right: $spacing-1; }
  .ph2-ns { padding-left: $spacing-2; padding-right: $spacing-2; }
  .ph3-ns { padding-left: $spacing-3; padding-right: $spacing-3; }
  .ph4-ns { padding-left: $spacing-4; padding-right: $spacing-4; }
  .ph5-ns { padding-left: $spacing-5; padding-right: $spacing-5; }
  .ph6-ns { padding-left: $spacing-6; padding-right: $spacing-6; }
  .ph7-ns { padding-left: $spacing-7; padding-right: $spacing-7; }
  .ph8-ns { padding-left: $spacing-8; padding-right: $spacing-8; }
  .ph9-ns { padding-left: $spacing-9; padding-right: $spacing-9; }
  .ph10-ns { padding-left: $spacing-10; padding-right: $spacing-10; }
  .ph11-ns { padding-left: $spacing-11; padding-right: $spacing-11; }
  .ph12-ns { padding-left: $spacing-12; padding-right: $spacing-12; }
  .ph13-ns { padding-left: $spacing-13; padding-right: $spacing-13; }
  .ph14-ns { padding-left: $spacing-14; padding-right: $spacing-14; }
  .ph15-ns { padding-left: $spacing-15; padding-right: $spacing-15; }
  .ma-auto-ns { margin: auto; }
  .ma0-ns { margin: $spacing-0; }
  .ma1-ns { margin: $spacing-1; }
  .ma2-ns { margin: $spacing-2; }
  .ma3-ns { margin: $spacing-3; }
  .ma4-ns { margin: $spacing-4; }
  .ma5-ns { margin: $spacing-5; }
  .ma6-ns { margin: $spacing-6; }
  .ma7-ns { margin: $spacing-7; }
  .ma8-ns { margin: $spacing-8; }
  .ma9-ns { margin: $spacing-9; }
  .ma10-ns { margin: $spacing-10; }
  .ma11-ns { margin: $spacing-11; }
  .ma12-ns { margin: $spacing-12; }
  .ma13-ns { margin: $spacing-13; }
  .ma14-ns { margin: $spacing-14; }
  .ma15-ns { margin: $spacing-15; }
  .ml-auto-ns { margin-left: auto; }
  .ml0-ns { margin-left: $spacing-0; }
  .ml1-ns { margin-left: $spacing-1; }
  .ml2-ns { margin-left: $spacing-2; }
  .ml3-ns { margin-left: $spacing-3; }
  .ml4-ns { margin-left: $spacing-4; }
  .ml5-ns { margin-left: $spacing-5; }
  .ml6-ns { margin-left: $spacing-6; }
  .ml7-ns { margin-left: $spacing-7; }
  .ml8-ns { margin-left: $spacing-8; }
  .ml9-ns { margin-left: $spacing-9; }
  .ml10-ns { margin-left: $spacing-10; }
  .ml11-ns { margin-left: $spacing-11; }
  .ml12-ns { margin-left: $spacing-12; }
  .ml13-ns { margin-left: $spacing-13; }
  .ml14-ns { margin-left: $spacing-14; }
  .ml15-ns { margin-left: $spacing-15; }
  .mr-auto-ns { margin-right: auto; }
  .mr0-ns { margin-right: $spacing-0; }
  .mr1-ns { margin-right: $spacing-1; }
  .mr2-ns { margin-right: $spacing-2; }
  .mr3-ns { margin-right: $spacing-3; }
  .mr4-ns { margin-right: $spacing-4; }
  .mr5-ns { margin-right: $spacing-5; }
  .mr6-ns { margin-right: $spacing-6; }
  .mr7-ns { margin-right: $spacing-7; }
  .mr8-ns { margin-right: $spacing-8; }
  .mr9-ns { margin-right: $spacing-9; }
  .mr10-ns { margin-right: $spacing-10; }
  .mr11-ns { margin-right: $spacing-11; }
  .mr12-ns { margin-right: $spacing-12; }
  .mr13-ns { margin-right: $spacing-13; }
  .mr14-ns { margin-right: $spacing-14; }
  .mr15-ns { margin-right: $spacing-15; }
  .mb-auto-ns { margin-bottom: auto; }
  .mb0-ns { margin-bottom: $spacing-0; }
  .mb1-ns { margin-bottom: $spacing-1; }
  .mb2-ns { margin-bottom: $spacing-2; }
  .mb3-ns { margin-bottom: $spacing-3; }
  .mb4-ns { margin-bottom: $spacing-4; }
  .mb5-ns { margin-bottom: $spacing-5; }
  .mb6-ns { margin-bottom: $spacing-6; }
  .mb7-ns { margin-bottom: $spacing-7; }
  .mb8-ns { margin-bottom: $spacing-8; }
  .mb9-ns { margin-bottom: $spacing-9; }
  .mb10-ns { margin-bottom: $spacing-10; }
  .mb11-ns { margin-bottom: $spacing-11; }
  .mb12-ns { margin-bottom: $spacing-12; }
  .mb13-ns { margin-bottom: $spacing-13; }
  .mb14-ns { margin-bottom: $spacing-14; }
  .mb15-ns { margin-bottom: $spacing-15; }
  .mt-auto-ns { margin-top: auto; }
  .mt0-ns { margin-top: $spacing-0; }
  .mt1-ns { margin-top: $spacing-1; }
  .mt2-ns { margin-top: $spacing-2; }
  .mt3-ns { margin-top: $spacing-3; }
  .mt4-ns { margin-top: $spacing-4; }
  .mt5-ns { margin-top: $spacing-5; }
  .mt6-ns { margin-top: $spacing-6; }
  .mt7-ns { margin-top: $spacing-7; }
  .mt8-ns { margin-top: $spacing-8; }
  .mt9-ns { margin-top: $spacing-9; }
  .mt10-ns { margin-top: $spacing-10; }
  .mt11-ns { margin-top: $spacing-11; }
  .mt12-ns { margin-top: $spacing-12; }
  .mt13-ns { margin-top: $spacing-13; }
  .mt14-ns { margin-top: $spacing-14; }
  .mt15-ns { margin-top: $spacing-15; }
  .mv-auto-ns { margin-top: auto; margin-bottom: auto; }
  .mv0-ns { margin-top: $spacing-0; margin-bottom: $spacing-0; }
  .mv1-ns { margin-top: $spacing-1; margin-bottom: $spacing-1; }
  .mv2-ns { margin-top: $spacing-2; margin-bottom: $spacing-2; }
  .mv3-ns { margin-top: $spacing-3; margin-bottom: $spacing-3; }
  .mv4-ns { margin-top: $spacing-4; margin-bottom: $spacing-4; }
  .mv5-ns { margin-top: $spacing-5; margin-bottom: $spacing-5; }
  .mv6-ns { margin-top: $spacing-6; margin-bottom: $spacing-6; }
  .mv7-ns { margin-top: $spacing-7; margin-bottom: $spacing-7; }
  .mv8-ns { margin-top: $spacing-8; margin-bottom: $spacing-8; }
  .mv9-ns { margin-top: $spacing-9; margin-bottom: $spacing-9; }
  .mv10-ns { margin-top: $spacing-10; margin-bottom: $spacing-10; }
  .mv11-ns { margin-top: $spacing-11; margin-bottom: $spacing-11; }
  .mv12-ns { margin-top: $spacing-12; margin-bottom: $spacing-12; }
  .mv13-ns { margin-top: $spacing-13; margin-bottom: $spacing-13; }
  .mv14-ns { margin-top: $spacing-14; margin-bottom: $spacing-14; }
  .mv15-ns { margin-top: $spacing-15; margin-bottom: $spacing-15; }
  .mh-auto-ns { margin-left: auto; margin-right: auto; }
  .mh0-ns { margin-left: $spacing-0; margin-right: $spacing-0; }
  .mh1-ns { margin-left: $spacing-1; margin-right: $spacing-1; }
  .mh2-ns { margin-left: $spacing-2; margin-right: $spacing-2; }
  .mh3-ns { margin-left: $spacing-3; margin-right: $spacing-3; }
  .mh4-ns { margin-left: $spacing-4; margin-right: $spacing-4; }
  .mh5-ns { margin-left: $spacing-5; margin-right: $spacing-5; }
  .mh6-ns { margin-left: $spacing-6; margin-right: $spacing-6; }
  .mh7-ns { margin-left: $spacing-7; margin-right: $spacing-7; }
  .mh8-ns { margin-left: $spacing-8; margin-right: $spacing-8; }
  .mh9-ns { margin-left: $spacing-9; margin-right: $spacing-9; }
  .mh10-ns { margin-left: $spacing-10; margin-right: $spacing-10; }
  .mh11-ns { margin-left: $spacing-11; margin-right: $spacing-11; }
  .mh12-ns { margin-left: $spacing-12; margin-right: $spacing-12; }
  .mh13-ns { margin-left: $spacing-13; margin-right: $spacing-13; }
  .mh14-ns { margin-left: $spacing-14; margin-right: $spacing-14; }
  .mh15-ns { margin-left: $spacing-15; margin-right: $spacing-15; }
  .na0-ns { margin: -$spacing-0; }
  .na1-ns { margin: -$spacing-1; }
  .na2-ns { margin: -$spacing-2; }
  .na3-ns { margin: -$spacing-3; }
  .na4-ns { margin: -$spacing-4; }
  .na5-ns { margin: -$spacing-5; }
  .na6-ns { margin: -$spacing-6; }
  .na7-ns { margin: -$spacing-7; }
  .na8-ns { margin: -$spacing-8; }
  .na9-ns { margin: -$spacing-9; }
  .na10-ns { margin: -$spacing-10; }
  .na11-ns { margin: -$spacing-11; }
  .na12-ns { margin: -$spacing-12; }
  .na13-ns { margin: -$spacing-13; }
  .na14-ns { margin: -$spacing-14; }
  .na15-ns { margin: -$spacing-15; }
  .nl0-ns { margin-left: -$spacing-0; }
  .nl1-ns { margin-left: -$spacing-1; }
  .nl2-ns { margin-left: -$spacing-2; }
  .nl3-ns { margin-left: -$spacing-3; }
  .nl4-ns { margin-left: -$spacing-4; }
  .nl5-ns { margin-left: -$spacing-5; }
  .nl6-ns { margin-left: -$spacing-6; }
  .nl7-ns { margin-left: -$spacing-7; }
  .nl8-ns { margin-left: -$spacing-8; }
  .nl9-ns { margin-left: -$spacing-9; }
  .nl10-ns { margin-left: -$spacing-10; }
  .nl11-ns { margin-left: -$spacing-11; }
  .nl12-ns { margin-left: -$spacing-12; }
  .nl13-ns { margin-left: -$spacing-13; }
  .nl14-ns { margin-left: -$spacing-14; }
  .nl15-ns { margin-left: -$spacing-15; }
  .nr0-ns { margin-right: -$spacing-0; }
  .nr1-ns { margin-right: -$spacing-1; }
  .nr2-ns { margin-right: -$spacing-2; }
  .nr3-ns { margin-right: -$spacing-3; }
  .nr4-ns { margin-right: -$spacing-4; }
  .nr5-ns { margin-right: -$spacing-5; }
  .nr6-ns { margin-right: -$spacing-6; }
  .nr7-ns { margin-right: -$spacing-7; }
  .nr8-ns { margin-right: -$spacing-8; }
  .nr9-ns { margin-right: -$spacing-9; }
  .nr10-ns { margin-right: -$spacing-10; }
  .nr11-ns { margin-right: -$spacing-11; }
  .nr12-ns { margin-right: -$spacing-12; }
  .nr13-ns { margin-right: -$spacing-13; }
  .nr14-ns { margin-right: -$spacing-14; }
  .nr15-ns { margin-right: -$spacing-15; }
  .nb0-ns { margin-bottom: -$spacing-0; }
  .nb1-ns { margin-bottom: -$spacing-1; }
  .nb2-ns { margin-bottom: -$spacing-2; }
  .nb3-ns { margin-bottom: -$spacing-3; }
  .nb4-ns { margin-bottom: -$spacing-4; }
  .nb5-ns { margin-bottom: -$spacing-5; }
  .nb6-ns { margin-bottom: -$spacing-6; }
  .nb7-ns { margin-bottom: -$spacing-7; }
  .nb8-ns { margin-bottom: -$spacing-8; }
  .nb9-ns { margin-bottom: -$spacing-9; }
  .nb10-ns { margin-bottom: -$spacing-10; }
  .nb11-ns { margin-bottom: -$spacing-11; }
  .nb12-ns { margin-bottom: -$spacing-12; }
  .nb13-ns { margin-bottom: -$spacing-13; }
  .nb14-ns { margin-bottom: -$spacing-14; }
  .nb15-ns { margin-bottom: -$spacing-15; }
  .nt0-ns { margin-top: -$spacing-0; }
  .nt1-ns { margin-top: -$spacing-1; }
  .nt2-ns { margin-top: -$spacing-2; }
  .nt3-ns { margin-top: -$spacing-3; }
  .nt4-ns { margin-top: -$spacing-4; }
  .nt5-ns { margin-top: -$spacing-5; }
  .nt6-ns { margin-top: -$spacing-6; }
  .nt7-ns { margin-top: -$spacing-7; }
  .nt8-ns { margin-top: -$spacing-8; }
  .nt9-ns { margin-top: -$spacing-9; }
  .nt10-ns { margin-top: -$spacing-10; }
  .nt11-ns { margin-top: -$spacing-11; }
  .nt12-ns { margin-top: -$spacing-12; }
  .nt13-ns { margin-top: -$spacing-13; }
  .nt14-ns { margin-top: -$spacing-14; }
  .nt15-ns { margin-top: -$spacing-15; }
  .nv0-ns { margin-top: -$spacing-0; margin-bottom: -$spacing-0; }
  .nv1-ns { margin-top: -$spacing-1; margin-bottom: -$spacing-1; }
  .nv2-ns { margin-top: -$spacing-2; margin-bottom: -$spacing-2; }
  .nv3-ns { margin-top: -$spacing-3; margin-bottom: -$spacing-3; }
  .nv4-ns { margin-top: -$spacing-4; margin-bottom: -$spacing-4; }
  .nv5-ns { margin-top: -$spacing-5; margin-bottom: -$spacing-5; }
  .nv6-ns { margin-top: -$spacing-6; margin-bottom: -$spacing-6; }
  .nv7-ns { margin-top: -$spacing-7; margin-bottom: -$spacing-7; }
  .nv8-ns { margin-top: -$spacing-8; margin-bottom: -$spacing-8; }
  .nv9-ns { margin-top: -$spacing-9; margin-bottom: -$spacing-9; }
  .nv10-ns { margin-top: -$spacing-10; margin-bottom: -$spacing-10; }
  .nv11-ns { margin-top: -$spacing-11; margin-bottom: -$spacing-11; }
  .nv12-ns { margin-top: -$spacing-12; margin-bottom: -$spacing-12; }
  .nv13-ns { margin-top: -$spacing-13; margin-bottom: -$spacing-13; }
  .nv14-ns { margin-top: -$spacing-14; margin-bottom: -$spacing-14; }
  .nv15-ns { margin-top: -$spacing-15; margin-bottom: -$spacing-15; }
  .nh0-ns { margin-left: -$spacing-0; margin-right: -$spacing-0; }
  .nh1-ns { margin-left: -$spacing-1; margin-right: -$spacing-1; }
  .nh2-ns { margin-left: -$spacing-2; margin-right: -$spacing-2; }
  .nh3-ns { margin-left: -$spacing-3; margin-right: -$spacing-3; }
  .nh4-ns { margin-left: -$spacing-4; margin-right: -$spacing-4; }
  .nh5-ns { margin-left: -$spacing-5; margin-right: -$spacing-5; }
  .nh6-ns { margin-left: -$spacing-6; margin-right: -$spacing-6; }
  .nh7-ns { margin-left: -$spacing-7; margin-right: -$spacing-7; }
  .nh8-ns { margin-left: -$spacing-8; margin-right: -$spacing-8; }
  .nh9-ns { margin-left: -$spacing-9; margin-right: -$spacing-9; }
  .nh10-ns { margin-left: -$spacing-10; margin-right: -$spacing-10; }
  .nh11-ns { margin-left: -$spacing-11; margin-right: -$spacing-11; }
  .nh12-ns { margin-left: -$spacing-12; margin-right: -$spacing-12; }
  .nh13-ns { margin-left: -$spacing-13; margin-right: -$spacing-13; }
  .nh14-ns { margin-left: -$spacing-14; margin-right: -$spacing-14; }
  .nh15-ns { margin-left: -$spacing-15; margin-right: -$spacing-15; }
  .strike-ns { text-decoration: line-through; }
  .underline-ns { text-decoration: underline; }
  .no-underline-ns { text-decoration: none; }
  .tl-ns { text-align: left; }
  .tr-ns { text-align: right; }
  .tc-ns { text-align: center; }
  .tj-ns { text-align: justify; }
  .ttc-ns { text-transform: capitalize; }
  .ttl-ns { text-transform: lowercase; }
  .ttu-ns { text-transform: uppercase; }
  .ttn-ns { text-transform: none; }
  .f1-ns, .f-heading-1-ns { font-size: $font-size-1; }
  .f2-ns, .f-heading-2-ns { font-size: $font-size-2; }
  .f3-ns, .f-heading-3-ns { font-size: $font-size-3; }
  .f4-ns, .f-heading-4-ns { font-size: $font-size-4; }
  .f5-ns, .f-heading-5-ns { font-size: $font-size-5; }
  .f6-ns, .f-heading-6-ns { font-size: $font-size-6; }
  .f7-ns, .f-body-large-ns { font-size: $font-size-7; }
  .f8-ns, .f-body-medium-ns { font-size: $font-size-8; }
  .f9-ns, .f-body-small-ns { font-size: $font-size-9; }
  .f10-ns, .f-caption-ns { font-size: $font-size-10; }
  .f11-ns { font-size: $font-size-11; }
  .f12-ns { font-size: $font-size-12; }
  .f13-ns { font-size: $font-size-13; }
  .f14-ns { font-size: $font-size-14; }
  .f15-ns { font-size: $font-size-15; }
  .v-base-ns { vertical-align: baseline; }
  .v-mid-ns { vertical-align: middle; }
  .v-top-ns { vertical-align: top; }
  .v-btm-ns { vertical-align: bottom; }
}

@media only screen and (min-width: 30em) and (max-width: 60em) {
  .aspect-ratio-m { height: 0; position: relative; }
  .aspect-ratio--16x9-m { padding-bottom: 56.25%; }
  .aspect-ratio--9x16-m { padding-bottom: 177.77%; }
  .aspect-ratio--4x3-m { padding-bottom: 75%; }
  .aspect-ratio--3x4-m { padding-bottom: 133.33%; }
  .aspect-ratio--6x4-m { padding-bottom: 66.6%; }
  .aspect-ratio--4x6-m { padding-bottom: 150%; }
  .aspect-ratio--8x5-m { padding-bottom: 62.5%; }
  .aspect-ratio--5x8-m { padding-bottom: 160%; }
  .aspect-ratio--7x5-m { padding-bottom: 71.42%; }
  .aspect-ratio--5x7-m { padding-bottom: 140%; }
  .aspect-ratio--1x1-m { padding-bottom: 100%; }
  .aspect-ratio--object-m { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; z-index: 100; }
  .cover-m { background-size: cover !important; }
  .contain-m { background-size: contain !important; }
  .bg-center-m { background-repeat: no-repeat; background-position: center center; }
  .bg-top-m { background-repeat: no-repeat; background-position: top center; }
  .bg-right-m { background-repeat: no-repeat; background-position: center right; }
  .bg-bottom-m { background-repeat: no-repeat; background-position: bottom center; }
  .bg-left-m { background-repeat: no-repeat; background-position: center left; }
  .outline-m { outline: 1px solid; }
  .outline-transparent-m { outline: 1px solid transparent; }
  .outline-0-m { outline: 0; }
  .ba-m { border-style: solid;}
  .bt-m { border-top-style: solid; }
  .br-m { border-right-style: solid; }
  .bb-m { border-bottom-style: solid; }
  .bl-m { border-left-style: solid; }
  .bn-m { border-style: none; border-width: 0; }
  .br0-m { border-radius: 0; }
  .br1-m { border-radius: .125rem; }
  .br2-m { border-radius: .25rem; }
  .br3-m { border-radius: .5rem; }
  .br4-m { border-radius: 1rem; }
  .br-100-m { border-radius: 100%; }
  .br-pill-m { border-radius: 9999px; }
  .br--bottom-m { border-top-left-radius: 0; border-top-right-radius: 0; }
  .br--top-m { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  .br--right-m { border-top-left-radius: 0; border-bottom-left-radius: 0; }
  .br--left-m { border-top-right-radius: 0; border-bottom-right-radius: 0; }
  .b--dotted-m { border-style: dotted; }
  .b--dashed-m { border-style: dashed; }
  .b--solid-m { border-style: solid; }
  .b--none-m { border-style: none; }
  .bw0-m { border-width: 0; }
  .bw1-m { border-width: 1px; }
  .bw2-m { border-width: .25rem; }
  .bw3-m { border-width: .5rem; }
  .bw4-m { border-width: 1rem; }
  .bw5-m { border-width: 2rem; }
  .bt-0-m { border-top-width: 0; }
  .br-0-m { border-right-width: 0; }
  .bb-0-m { border-bottom-width: 0; }
  .bl-0-m { border-left-width: 0; }
  .shadow-1-m { box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-2-m { box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-3-m { box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-4-m { box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 ); }
  .shadow-5-m { box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 ); }
  .top-0-m { top: 0; }
  .right-0-m { right: 0; }
  .bottom-0-m { bottom: 0; }
  .left-0-m { left: 0; }
  .top-1-m { top: .5rem; }
  .right-1-m { right: .5rem; }
  .bottom-1-m { bottom: .5rem; }
  .left-1-m { left: .5rem; }
  .top-2-m { top: 1rem; }
  .right-2-m { right: 1rem; }
  .bottom-2-m { bottom: 1rem; }
  .left-2-m { left: 1rem; }
  .top-3-m { top: 1.5rem; }
  .right-3-m { right: 1.5rem; }
  .bottom-3-m { bottom: 1.5rem; }
  .left-3-m { left: 1.5rem; }
  .top-4-m { top: 2rem; }
  .right-4-m { right: 2rem; }
  .bottom-4-m { bottom: 2rem; }
  .left-4-m { left: 2rem; }
  .top--1-m { top: -.5rem; }
  .right--1-m { right: -.5rem; }
  .bottom--1-m { bottom: -.5rem; }
  .left--1-m { left: -.5rem; }
  .top--2-m { top: -1rem; }
  .right--2-m { right: -1rem; }
  .bottom--2-m { bottom: -1rem; }
  .left--2-m { left: -1rem; }
  .top--3-m { top: -1.5rem; }
  .right--3-m { right: -1.5rem; }
  .bottom--3-m { bottom: -1.5rem; }
  .left--3-m { left: -1.5rem; }
  .top--4-m { top: -2rem; }
  .right--4-m { right: -2rem; }
  .bottom--4-m { bottom: -2rem; }
  .absolute--fill-m { top: 0; right: 0; bottom: 0; left: 0; }
  .cl-m { clear: left; }
  .cr-m { clear: right; }
  .cb-m { clear: both; }
  .cn-m { clear: none; }
  .dn-m { display: none; }
  .di-m { display: inline; }
  .db-m { display: block; }
  .dib-m { display: inline-block; }
  .dit-m { display: inline-table; }
  .dt-m { display: table; }
  .dtc-m { display: table-cell; }
  .dt-row-m { display: table-row; }
  .dt-row-group-m { display: table-row-group; }
  .dt-column-m { display: table-column; }
  .dt-column-group-m { display: table-column-group; }
  .dt--fixed-m { table-layout: fixed; width: 100%; }
  .flex-m { display: flex; }
  .inline-flex-m { display: inline-flex; }
  .flex-auto-m { flex: 1 1 auto; min-width: 0; /* 1 */ min-height: 0; /* 1 */ }
  .flex-none-m { flex: none; }
  .flex-column-m { flex-direction: column; }
  .flex-row-m { flex-direction: row; }
  .flex-wrap-m { flex-wrap: wrap; }
  .flex-nowrap-m { flex-wrap: nowrap; }
  .flex-wrap-reverse-m { flex-wrap: wrap-reverse; }
  .flex-column-reverse-m { flex-direction: column-reverse; }
  .flex-row-reverse-m { flex-direction: row-reverse; }
  .items-start-m { align-items: flex-start; }
  .items-end-m { align-items: flex-end; }
  .items-center-m { align-items: center; }
  .items-baseline-m { align-items: baseline; }
  .items-stretch-m { align-items: stretch; }
  .self-start-m { align-self: flex-start; }
  .self-end-m { align-self: flex-end; }
  .self-center-m { align-self: center; }
  .self-baseline-m { align-self: baseline; }
  .self-stretch-m { align-self: stretch; }
  .justify-start-m { justify-content: flex-start; }
  .justify-end-m { justify-content: flex-end; }
  .justify-center-m { justify-content: center; }
  .justify-between-m { justify-content: space-between; }
  .justify-around-m { justify-content: space-around; }
  .content-start-m { align-content: flex-start; }
  .content-end-m { align-content: flex-end; }
  .content-center-m { align-content: center; }
  .content-between-m { align-content: space-between; }
  .content-around-m { align-content: space-around; }
  .content-stretch-m { align-content: stretch; }
  .order-0-m { order: 0; }
  .order-1-m { order: 1; }
  .order-2-m { order: 2; }
  .order-3-m { order: 3; }
  .order-4-m { order: 4; }
  .order-5-m { order: 5; }
  .order-6-m { order: 6; }
  .order-7-m { order: 7; }
  .order-8-m { order: 8; }
  .order-last-m { order: 99999; }
  .flex-grow-0-m { flex-grow: 0; }
  .flex-grow-1-m { flex-grow: 1; }
  .flex-shrink-0-m { flex-shrink: 0; }
  .flex-shrink-1-m { flex-shrink: 1; }
  .fl-m { float: left; _display: inline; }
  .fr-m { float: right; _display: inline; }
  .fn-m { float: none; }
  .i-m { font-style: italic; }
  .fs-normal-m { font-style: normal; }
  .fw1-m { font-weight: 100; }
  .fw2-m { font-weight: 200; }
  .fw3-m { font-weight: 300; }
  .fw4-m, .normal-m { font-weight: 400; }
  .fw5-m { font-weight: 500; }
  .fw6-m, .semi-bold-m { font-weight: 600; }
  .fw7-m, .bold-m { font-weight: 700; }
  .fw8-m { font-weight: 800; }
  .fw9-m, .bolder-m { font-weight: 900; }
  .h1-m { height: 1rem; }
  .h2-m { height: 1.5rem; }
  .h3-m { height: 2rem; }
  .h4-m { height: 3rem; }
  .h5-m { height: 4rem; }
  .h6-m { height: 6rem; }
  .h7-m { height: 8rem; }
  .h8-m { height: 12rem; }
  .h9-m { height: 16rem; }
  .h10-m { height: 24rem; }
  .h11-m { height: 32rem; }
  .h12-m { height: 48rem; }
  .h13-m { height: 64rem; }
  .h14-m { height: 96rem; }
  .h15-m { height: 128rem; }
  .h-25-m { height: 25%; }
  .h-50-m { height: 50%; }
  .h-60-m { height: 60%; }
  .h-65-m { height: 65%; }
  .h-75-m { height: 75%; }
  .h-80-m { height: 80%; }
  .h-90-m { height: 90%; }
  .h-100-m { height: 100%; }
  .min-h-100-m { min-height: 100%; }
  .vh-25-m { height: 25vh; }
  .vh-50-m { height: 50vh; }
  .vh-75-m { height: 75vh; }
  .vh-100-m { height: 100vh; }
  .min-vh-100-m { min-height: 100vh; }
  .h-auto-m { height: auto; }
  .h-inherit-m { height: inherit; }
  .lh-1-m { line-height: 1; }
  .lh-2-m { line-height: 1.25; }
  .lh-3-m { line-height: 1.5; }
  .lh-4-m { line-height: 2; }
  .w1-m { width: 1rem; }
  .w2-m { width: 1.5rem; }
  .w3-m { width: 2rem; }
  .w4-m { width: 3rem; }
  .w5-m { width: 4rem; }
  .w6-m { width: 6rem; }
  .w7-m { width: 8rem; }
  .w8-m { width: 12rem; }
  .w9-m { width: 16rem; }
  .w10-m { width: 24rem; }
  .w11-m { width: 32rem; }
  .w12-m { width: 48rem; }
  .w13-m { width: 64rem; }
  .w14-m { width: 96rem; }
  .w15-m { width: 128rem; }
  .w-10-m { width: 10%; }
  .w-15-m { width: 15%; }
  .w-20-m { width: 20%; }
  .w-25-m { width: 25%; }
  .w-30-m { width: 30%; }
  .w-35-m { width: 35%; }
  .w-40-m { width: 40%; }
  .w-45-m { width: 45%; }
  .w-48-m { width: 48%; }
  .w-50-m { width: 50%; }
  .w-55-m { width: 55%; }
  .w-60-m { width: 60%; }
  .w-65-m { width: 65%; }
  .w-70-m { width: 70%; }
  .w-75-m { width: 75%; }
  .w-80-m { width: 80%; }
  .w-85-m { width: 85%; }
  .w-90-m { width: 90%; }
  .w-95-m { width: 95%; }
  .w-100-m { width: 100%; }
  .w-third-m { width: 33.33333%; }
  .w-two-thirds-m { width: 66.66667%; }
  .w-auto-m { width: auto; }
  .overflow-visible-m { overflow: visible; }
  .overflow-hidden-m { overflow: hidden; }
  .overflow-scroll-m { overflow: scroll; }
  .overflow-auto-m { overflow: auto; }
  .overflow-x-visible-m { overflow-x: visible; }
  .overflow-x-hidden-m { overflow-x: hidden; }
  .overflow-x-scroll-m { overflow-x: scroll; }
  .overflow-x-auto-m { overflow-x: auto; }
  .overflow-y-visible-m { overflow-y: visible; }
  .overflow-y-hidden-m { overflow-y: hidden; }
  .overflow-y-scroll-m { overflow-y: scroll; }
  .overflow-y-auto-m { overflow-y: auto; }
  .static-m { position: static; }
  .relative-m { position: relative; }
  .absolute-m { position: absolute; }
  .fixed-m { position: fixed; }
  .pa0-m { padding: $spacing-0; }
  .pa1-m { padding: $spacing-1; }
  .pa2-m { padding: $spacing-2; }
  .pa3-m { padding: $spacing-3; }
  .pa4-m { padding: $spacing-4; }
  .pa5-m { padding: $spacing-5; }
  .pa6-m { padding: $spacing-6; }
  .pa7-m { padding: $spacing-7; }
  .pa8-m { padding: $spacing-8; }
  .pa9-m { padding: $spacing-9; }
  .pa10-m { padding: $spacing-10; }
  .pa11-m { padding: $spacing-11; }
  .pa12-m { padding: $spacing-12; }
  .pa13-m { padding: $spacing-13; }
  .pa14-m { padding: $spacing-14; }
  .pa15-m { padding: $spacing-15; }
  .pl0-m { padding-left: $spacing-0; }
  .pl1-m { padding-left: $spacing-1; }
  .pl2-m { padding-left: $spacing-2; }
  .pl3-m { padding-left: $spacing-3; }
  .pl4-m { padding-left: $spacing-4; }
  .pl5-m { padding-left: $spacing-5; }
  .pl6-m { padding-left: $spacing-6; }
  .pl7-m { padding-left: $spacing-7; }
  .pl8-m { padding-left: $spacing-8; }
  .pl9-m { padding-left: $spacing-9; }
  .pl10-m { padding-left: $spacing-10; }
  .pl11-m { padding-left: $spacing-11; }
  .pl12-m { padding-left: $spacing-12; }
  .pl13-m { padding-left: $spacing-13; }
  .pl14-m { padding-left: $spacing-14; }
  .pl15-m { padding-left: $spacing-15; }
  .pr0-m { padding-right: $spacing-0; }
  .pr1-m { padding-right: $spacing-1; }
  .pr2-m { padding-right: $spacing-2; }
  .pr3-m { padding-right: $spacing-3; }
  .pr4-m { padding-right: $spacing-4; }
  .pr5-m { padding-right: $spacing-5; }
  .pr6-m { padding-right: $spacing-6; }
  .pr7-m { padding-right: $spacing-7; }
  .pr8-m { padding-right: $spacing-8; }
  .pr9-m { padding-right: $spacing-9; }
  .pr10-m { padding-right: $spacing-10; }
  .pr11-m { padding-right: $spacing-11; }
  .pr12-m { padding-right: $spacing-12; }
  .pr13-m { padding-right: $spacing-13; }
  .pr14-m { padding-right: $spacing-14; }
  .pr15-m { padding-right: $spacing-15; }
  .pb0-m { padding-bottom: $spacing-0; }
  .pb1-m { padding-bottom: $spacing-1; }
  .pb2-m { padding-bottom: $spacing-2; }
  .pb3-m { padding-bottom: $spacing-3; }
  .pb4-m { padding-bottom: $spacing-4; }
  .pb5-m { padding-bottom: $spacing-5; }
  .pb6-m { padding-bottom: $spacing-6; }
  .pb7-m { padding-bottom: $spacing-7; }
  .pb8-m { padding-bottom: $spacing-8; }
  .pb9-m { padding-bottom: $spacing-9; }
  .pb10-m { padding-bottom: $spacing-10; }
  .pb11-m { padding-bottom: $spacing-11; }
  .pb12-m { padding-bottom: $spacing-12; }
  .pb13-m { padding-bottom: $spacing-13; }
  .pb14-m { padding-bottom: $spacing-14; }
  .pb15-m { padding-bottom: $spacing-15; }
  .pt0-m { padding-top: $spacing-0; }
  .pt1-m { padding-top: $spacing-1; }
  .pt2-m { padding-top: $spacing-2; }
  .pt3-m { padding-top: $spacing-3; }
  .pt4-m { padding-top: $spacing-4; }
  .pt5-m { padding-top: $spacing-5; }
  .pt6-m { padding-top: $spacing-6; }
  .pt7-m { padding-top: $spacing-7; }
  .pt8-m { padding-top: $spacing-8; }
  .pt9-m { padding-top: $spacing-9; }
  .pt10-m { padding-top: $spacing-10; }
  .pt11-m { padding-top: $spacing-11; }
  .pt12-m { padding-top: $spacing-12; }
  .pt13-m { padding-top: $spacing-13; }
  .pt14-m { padding-top: $spacing-14; }
  .pt15-m { padding-top: $spacing-15; }
  .pv0-m { padding-top: $spacing-0; padding-bottom: $spacing-0; }
  .pv1-m { padding-top: $spacing-1; padding-bottom: $spacing-1; }
  .pv2-m { padding-top: $spacing-2; padding-bottom: $spacing-2; }
  .pv3-m { padding-top: $spacing-3; padding-bottom: $spacing-3; }
  .pv4-m { padding-top: $spacing-4; padding-bottom: $spacing-4; }
  .pv5-m { padding-top: $spacing-5; padding-bottom: $spacing-5; }
  .pv6-m { padding-top: $spacing-6; padding-bottom: $spacing-6; }
  .pv7-m { padding-top: $spacing-7; padding-bottom: $spacing-7; }
  .pv8-m { padding-top: $spacing-8; padding-bottom: $spacing-8; }
  .pv9-m { padding-top: $spacing-9; padding-bottom: $spacing-9; }
  .pv10-m { padding-top: $spacing-10; padding-bottom: $spacing-10; }
  .pv11-m { padding-top: $spacing-11; padding-bottom: $spacing-11; }
  .pv12-m { padding-top: $spacing-12; padding-bottom: $spacing-12; }
  .pv13-m { padding-top: $spacing-13; padding-bottom: $spacing-13; }
  .pv14-m { padding-top: $spacing-14; padding-bottom: $spacing-14; }
  .pv15-m { padding-top: $spacing-15; padding-bottom: $spacing-15; }
  .ph0-m { padding-left: $spacing-0; padding-right: $spacing-0; }
  .ph1-m { padding-left: $spacing-1; padding-right: $spacing-1; }
  .ph2-m { padding-left: $spacing-2; padding-right: $spacing-2; }
  .ph3-m { padding-left: $spacing-3; padding-right: $spacing-3; }
  .ph4-m { padding-left: $spacing-4; padding-right: $spacing-4; }
  .ph5-m { padding-left: $spacing-5; padding-right: $spacing-5; }
  .ph6-m { padding-left: $spacing-6; padding-right: $spacing-6; }
  .ph7-m { padding-left: $spacing-7; padding-right: $spacing-7; }
  .ph8-m { padding-left: $spacing-8; padding-right: $spacing-8; }
  .ph9-m { padding-left: $spacing-9; padding-right: $spacing-9; }
  .ph10-m { padding-left: $spacing-10; padding-right: $spacing-10; }
  .ph11-m { padding-left: $spacing-11; padding-right: $spacing-11; }
  .ph12-m { padding-left: $spacing-12; padding-right: $spacing-12; }
  .ph13-m { padding-left: $spacing-13; padding-right: $spacing-13; }
  .ph14-m { padding-left: $spacing-14; padding-right: $spacing-14; }
  .ph15-m { padding-left: $spacing-15; padding-right: $spacing-15; }
  .ma-auto-m { margin: auto; }
  .ma0-m { margin: $spacing-0; }
  .ma1-m { margin: $spacing-1; }
  .ma2-m { margin: $spacing-2; }
  .ma3-m { margin: $spacing-3; }
  .ma4-m { margin: $spacing-4; }
  .ma5-m { margin: $spacing-5; }
  .ma6-m { margin: $spacing-6; }
  .ma7-m { margin: $spacing-7; }
  .ma8-m { margin: $spacing-8; }
  .ma9-m { margin: $spacing-9; }
  .ma10-m { margin: $spacing-10; }
  .ma11-m { margin: $spacing-11; }
  .ma12-m { margin: $spacing-12; }
  .ma13-m { margin: $spacing-13; }
  .ma14-m { margin: $spacing-14; }
  .ma15-m { margin: $spacing-15; }
  .ml-auto-m { margin-left: auto; }
  .ml0-m { margin-left: $spacing-0; }
  .ml1-m { margin-left: $spacing-1; }
  .ml2-m { margin-left: $spacing-2; }
  .ml3-m { margin-left: $spacing-3; }
  .ml4-m { margin-left: $spacing-4; }
  .ml5-m { margin-left: $spacing-5; }
  .ml6-m { margin-left: $spacing-6; }
  .ml7-m { margin-left: $spacing-7; }
  .ml8-m { margin-left: $spacing-8; }
  .ml9-m { margin-left: $spacing-9; }
  .ml10-m { margin-left: $spacing-10; }
  .ml11-m { margin-left: $spacing-11; }
  .ml12-m { margin-left: $spacing-12; }
  .ml13-m { margin-left: $spacing-13; }
  .ml14-m { margin-left: $spacing-14; }
  .ml15-m { margin-left: $spacing-15; }
  .mr-auto-m { margin-right: auto; }
  .mr0-m { margin-right: $spacing-0; }
  .mr1-m { margin-right: $spacing-1; }
  .mr2-m { margin-right: $spacing-2; }
  .mr3-m { margin-right: $spacing-3; }
  .mr4-m { margin-right: $spacing-4; }
  .mr5-m { margin-right: $spacing-5; }
  .mr6-m { margin-right: $spacing-6; }
  .mr7-m { margin-right: $spacing-7; }
  .mr8-m { margin-right: $spacing-8; }
  .mr9-m { margin-right: $spacing-9; }
  .mr10-m { margin-right: $spacing-10; }
  .mr11-m { margin-right: $spacing-11; }
  .mr12-m { margin-right: $spacing-12; }
  .mr13-m { margin-right: $spacing-13; }
  .mr14-m { margin-right: $spacing-14; }
  .mr15-m { margin-right: $spacing-15; }
  .mb-auto-m { margin-bottom: auto; }
  .mb0-m { margin-bottom: $spacing-0; }
  .mb1-m { margin-bottom: $spacing-1; }
  .mb2-m { margin-bottom: $spacing-2; }
  .mb3-m { margin-bottom: $spacing-3; }
  .mb4-m { margin-bottom: $spacing-4; }
  .mb5-m { margin-bottom: $spacing-5; }
  .mb6-m { margin-bottom: $spacing-6; }
  .mb7-m { margin-bottom: $spacing-7; }
  .mb8-m { margin-bottom: $spacing-8; }
  .mb9-m { margin-bottom: $spacing-9; }
  .mb10-m { margin-bottom: $spacing-10; }
  .mb11-m { margin-bottom: $spacing-11; }
  .mb12-m { margin-bottom: $spacing-12; }
  .mb13-m { margin-bottom: $spacing-13; }
  .mb14-m { margin-bottom: $spacing-14; }
  .mb15-m { margin-bottom: $spacing-15; }
  .mt-auto-m { margin-top: auto; }
  .mt0-m { margin-top: $spacing-0; }
  .mt1-m { margin-top: $spacing-1; }
  .mt2-m { margin-top: $spacing-2; }
  .mt3-m { margin-top: $spacing-3; }
  .mt4-m { margin-top: $spacing-4; }
  .mt5-m { margin-top: $spacing-5; }
  .mt6-m { margin-top: $spacing-6; }
  .mt7-m { margin-top: $spacing-7; }
  .mt8-m { margin-top: $spacing-8; }
  .mt9-m { margin-top: $spacing-9; }
  .mt10-m { margin-top: $spacing-10; }
  .mt11-m { margin-top: $spacing-11; }
  .mt12-m { margin-top: $spacing-12; }
  .mt13-m { margin-top: $spacing-13; }
  .mt14-m { margin-top: $spacing-14; }
  .mt15-m { margin-top: $spacing-15; }
  .mv-auto-m { margin-top: auto; margin-bottom: auto; }
  .mv0-m { margin-top: $spacing-0; margin-bottom: $spacing-0; }
  .mv1-m { margin-top: $spacing-1; margin-bottom: $spacing-1; }
  .mv2-m { margin-top: $spacing-2; margin-bottom: $spacing-2; }
  .mv3-m { margin-top: $spacing-3; margin-bottom: $spacing-3; }
  .mv4-m { margin-top: $spacing-4; margin-bottom: $spacing-4; }
  .mv5-m { margin-top: $spacing-5; margin-bottom: $spacing-5; }
  .mv6-m { margin-top: $spacing-6; margin-bottom: $spacing-6; }
  .mv7-m { margin-top: $spacing-7; margin-bottom: $spacing-7; }
  .mv8-m { margin-top: $spacing-8; margin-bottom: $spacing-8; }
  .mv9-m { margin-top: $spacing-9; margin-bottom: $spacing-9; }
  .mv10-m { margin-top: $spacing-10; margin-bottom: $spacing-10; }
  .mv11-m { margin-top: $spacing-11; margin-bottom: $spacing-11; }
  .mv12-m { margin-top: $spacing-12; margin-bottom: $spacing-12; }
  .mv13-m { margin-top: $spacing-13; margin-bottom: $spacing-13; }
  .mv14-m { margin-top: $spacing-14; margin-bottom: $spacing-14; }
  .mv15-m { margin-top: $spacing-15; margin-bottom: $spacing-15; }
  .mh-auto-m { margin-left: auto; margin-right: auto; }
  .mh0-m { margin-left: $spacing-0; margin-right: $spacing-0; }
  .mh1-m { margin-left: $spacing-1; margin-right: $spacing-1; }
  .mh2-m { margin-left: $spacing-2; margin-right: $spacing-2; }
  .mh3-m { margin-left: $spacing-3; margin-right: $spacing-3; }
  .mh4-m { margin-left: $spacing-4; margin-right: $spacing-4; }
  .mh5-m { margin-left: $spacing-5; margin-right: $spacing-5; }
  .mh6-m { margin-left: $spacing-6; margin-right: $spacing-6; }
  .mh7-m { margin-left: $spacing-7; margin-right: $spacing-7; }
  .mh8-m { margin-left: $spacing-8; margin-right: $spacing-8; }
  .mh9-m { margin-left: $spacing-9; margin-right: $spacing-9; }
  .mh10-m { margin-left: $spacing-10; margin-right: $spacing-10; }
  .mh11-m { margin-left: $spacing-11; margin-right: $spacing-11; }
  .mh12-m { margin-left: $spacing-12; margin-right: $spacing-12; }
  .mh13-m { margin-left: $spacing-13; margin-right: $spacing-13; }
  .mh14-m { margin-left: $spacing-14; margin-right: $spacing-14; }
  .mh15-m { margin-left: $spacing-15; margin-right: $spacing-15; }
  .na0-m { margin: -$spacing-0; }
  .na1-m { margin: -$spacing-1; }
  .na2-m { margin: -$spacing-2; }
  .na3-m { margin: -$spacing-3; }
  .na4-m { margin: -$spacing-4; }
  .na5-m { margin: -$spacing-5; }
  .na6-m { margin: -$spacing-6; }
  .na7-m { margin: -$spacing-7; }
  .na8-m { margin: -$spacing-8; }
  .na9-m { margin: -$spacing-9; }
  .na10-m { margin: -$spacing-10; }
  .na11-m { margin: -$spacing-11; }
  .na12-m { margin: -$spacing-12; }
  .na13-m { margin: -$spacing-13; }
  .na14-m { margin: -$spacing-14; }
  .na15-m { margin: -$spacing-15; }
  .nl0-m { margin-left: -$spacing-0; }
  .nl1-m { margin-left: -$spacing-1; }
  .nl2-m { margin-left: -$spacing-2; }
  .nl3-m { margin-left: -$spacing-3; }
  .nl4-m { margin-left: -$spacing-4; }
  .nl5-m { margin-left: -$spacing-5; }
  .nl6-m { margin-left: -$spacing-6; }
  .nl7-m { margin-left: -$spacing-7; }
  .nl8-m { margin-left: -$spacing-8; }
  .nl9-m { margin-left: -$spacing-9; }
  .nl10-m { margin-left: -$spacing-10; }
  .nl11-m { margin-left: -$spacing-11; }
  .nl12-m { margin-left: -$spacing-12; }
  .nl13-m { margin-left: -$spacing-13; }
  .nl14-m { margin-left: -$spacing-14; }
  .nl15-m { margin-left: -$spacing-15; }
  .nr0-m { margin-right: -$spacing-0; }
  .nr1-m { margin-right: -$spacing-1; }
  .nr2-m { margin-right: -$spacing-2; }
  .nr3-m { margin-right: -$spacing-3; }
  .nr4-m { margin-right: -$spacing-4; }
  .nr5-m { margin-right: -$spacing-5; }
  .nr6-m { margin-right: -$spacing-6; }
  .nr7-m { margin-right: -$spacing-7; }
  .nr8-m { margin-right: -$spacing-8; }
  .nr9-m { margin-right: -$spacing-9; }
  .nr10-m { margin-right: -$spacing-10; }
  .nr11-m { margin-right: -$spacing-11; }
  .nr12-m { margin-right: -$spacing-12; }
  .nr13-m { margin-right: -$spacing-13; }
  .nr14-m { margin-right: -$spacing-14; }
  .nr15-m { margin-right: -$spacing-15; }
  .nb0-m { margin-bottom: -$spacing-0; }
  .nb1-m { margin-bottom: -$spacing-1; }
  .nb2-m { margin-bottom: -$spacing-2; }
  .nb3-m { margin-bottom: -$spacing-3; }
  .nb4-m { margin-bottom: -$spacing-4; }
  .nb5-m { margin-bottom: -$spacing-5; }
  .nb6-m { margin-bottom: -$spacing-6; }
  .nb7-m { margin-bottom: -$spacing-7; }
  .nb8-m { margin-bottom: -$spacing-8; }
  .nb9-m { margin-bottom: -$spacing-9; }
  .nb10-m { margin-bottom: -$spacing-10; }
  .nb11-m { margin-bottom: -$spacing-11; }
  .nb12-m { margin-bottom: -$spacing-12; }
  .nb13-m { margin-bottom: -$spacing-13; }
  .nb14-m { margin-bottom: -$spacing-14; }
  .nb15-m { margin-bottom: -$spacing-15; }
  .nt0-m { margin-top: -$spacing-0; }
  .nt1-m { margin-top: -$spacing-1; }
  .nt2-m { margin-top: -$spacing-2; }
  .nt3-m { margin-top: -$spacing-3; }
  .nt4-m { margin-top: -$spacing-4; }
  .nt5-m { margin-top: -$spacing-5; }
  .nt6-m { margin-top: -$spacing-6; }
  .nt7-m { margin-top: -$spacing-7; }
  .nt8-m { margin-top: -$spacing-8; }
  .nt9-m { margin-top: -$spacing-9; }
  .nt10-m { margin-top: -$spacing-10; }
  .nt11-m { margin-top: -$spacing-11; }
  .nt12-m { margin-top: -$spacing-12; }
  .nt13-m { margin-top: -$spacing-13; }
  .nt14-m { margin-top: -$spacing-14; }
  .nt15-m { margin-top: -$spacing-15; }
  .nv0-m { margin-top: -$spacing-0; margin-bottom: -$spacing-0; }
  .nv1-m { margin-top: -$spacing-1; margin-bottom: -$spacing-1; }
  .nv2-m { margin-top: -$spacing-2; margin-bottom: -$spacing-2; }
  .nv3-m { margin-top: -$spacing-3; margin-bottom: -$spacing-3; }
  .nv4-m { margin-top: -$spacing-4; margin-bottom: -$spacing-4; }
  .nv5-m { margin-top: -$spacing-5; margin-bottom: -$spacing-5; }
  .nv6-m { margin-top: -$spacing-6; margin-bottom: -$spacing-6; }
  .nv7-m { margin-top: -$spacing-7; margin-bottom: -$spacing-7; }
  .nv8-m { margin-top: -$spacing-8; margin-bottom: -$spacing-8; }
  .nv9-m { margin-top: -$spacing-9; margin-bottom: -$spacing-9; }
  .nv10-m { margin-top: -$spacing-10; margin-bottom: -$spacing-10; }
  .nv11-m { margin-top: -$spacing-11; margin-bottom: -$spacing-11; }
  .nv12-m { margin-top: -$spacing-12; margin-bottom: -$spacing-12; }
  .nv13-m { margin-top: -$spacing-13; margin-bottom: -$spacing-13; }
  .nv14-m { margin-top: -$spacing-14; margin-bottom: -$spacing-14; }
  .nv15-m { margin-top: -$spacing-15; margin-bottom: -$spacing-15; }
  .nh0-m { margin-left: -$spacing-0; margin-right: -$spacing-0; }
  .nh1-m { margin-left: -$spacing-1; margin-right: -$spacing-1; }
  .nh2-m { margin-left: -$spacing-2; margin-right: -$spacing-2; }
  .nh3-m { margin-left: -$spacing-3; margin-right: -$spacing-3; }
  .nh4-m { margin-left: -$spacing-4; margin-right: -$spacing-4; }
  .nh5-m { margin-left: -$spacing-5; margin-right: -$spacing-5; }
  .nh6-m { margin-left: -$spacing-6; margin-right: -$spacing-6; }
  .nh7-m { margin-left: -$spacing-7; margin-right: -$spacing-7; }
  .nh8-m { margin-left: -$spacing-8; margin-right: -$spacing-8; }
  .nh9-m { margin-left: -$spacing-9; margin-right: -$spacing-9; }
  .nh10-m { margin-left: -$spacing-10; margin-right: -$spacing-10; }
  .nh11-m { margin-left: -$spacing-11; margin-right: -$spacing-11; }
  .nh12-m { margin-left: -$spacing-12; margin-right: -$spacing-12; }
  .nh13-m { margin-left: -$spacing-13; margin-right: -$spacing-13; }
  .nh14-m { margin-left: -$spacing-14; margin-right: -$spacing-14; }
  .nh15-m { margin-left: -$spacing-15; margin-right: -$spacing-15; }
  .strike-m { text-decoration: line-through; }
  .underline-m { text-decoration: underline; }
  .no-underline-m { text-decoration: none; }
  .tl-m { text-align: left; }
  .tr-m { text-align: right; }
  .tc-m { text-align: center; }
  .tj-m { text-align: justify; }
  .ttc-m { text-transform: capitalize; }
  .ttl-m { text-transform: lowercase; }
  .ttu-m { text-transform: uppercase; }
  .ttn-m { text-transform: none; }
  .f1-m, .f-heading-1-m { font-size: $font-size-1; }
  .f2-m, .f-heading-2-m { font-size: $font-size-2; }
  .f3-m, .f-heading-3-m { font-size: $font-size-3; }
  .f4-m, .f-heading-4-m { font-size: $font-size-4; }
  .f5-m, .f-heading-5-m { font-size: $font-size-5; }
  .f6-m, .f-heading-6-m { font-size: $font-size-6; }
  .f7-m, .f-body-large-m { font-size: $font-size-7; }
  .f8-m, .f-body-medium-m { font-size: $font-size-8; }
  .f9-m, .f-body-small-m { font-size: $font-size-9; }
  .f10-m, .f-caption-m { font-size: $font-size-10; }
  .f11-m { font-size: $font-size-11; }
  .f12-m { font-size: $font-size-12; }
  .f13-m { font-size: $font-size-13; }
  .f14-m { font-size: $font-size-14; }
  .f15-m { font-size: $font-size-15; }
  .v-base-m { vertical-align: baseline; }
  .v-mid-m { vertical-align: middle; }
  .v-top-m { vertical-align: top; }
  .v-btm-m { vertical-align: bottom; }
}

@media only screen and (min-width: 60em) {
  .aspect-ratio-l { height: 0; position: relative; }
  .aspect-ratio--16x9-l { padding-bottom: 56.25%; }
  .aspect-ratio--9x16-l { padding-bottom: 177.77%; }
  .aspect-ratio--4x3-l { padding-bottom: 75%; }
  .aspect-ratio--3x4-l { padding-bottom: 133.33%; }
  .aspect-ratio--6x4-l { padding-bottom: 66.6%; }
  .aspect-ratio--4x6-l { padding-bottom: 150%; }
  .aspect-ratio--8x5-l { padding-bottom: 62.5%; }
  .aspect-ratio--5x8-l { padding-bottom: 160%; }
  .aspect-ratio--7x5-l { padding-bottom: 71.42%; }
  .aspect-ratio--5x7-l { padding-bottom: 140%; }
  .aspect-ratio--1x1-l { padding-bottom: 100%; }
  .aspect-ratio--object-l { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; height: 100%; z-index: 100; }
  .cover-l { background-size: cover !important; }
  .contain-l { background-size: contain !important; }
  .bg-center-l { background-repeat: no-repeat; background-position: center center; }
  .bg-top-l { background-repeat: no-repeat; background-position: top center; }
  .bg-right-l { background-repeat: no-repeat; background-position: center right; }
  .bg-bottom-l { background-repeat: no-repeat; background-position: bottom center; }
  .bg-left-l { background-repeat: no-repeat; background-position: center left; }
  .outline-l { outline: 1px solid; }
  .outline-transparent-l { outline: 1px solid transparent; }
  .outline-0-l { outline: 0; }
  .ba-l { border-style: solid; }
  .bt-l { border-top-style: solid; }
  .br-l { border-right-style: solid; }
  .bb-l { border-bottom-style: solid; }
  .bl-l { border-left-style: solid; }
  .bn-l { border-style: none; border-width: 0; }
  .br0-l { border-radius: 0; }
  .br1-l { border-radius: .125rem; }
  .br2-l { border-radius: .25rem; }
  .br3-l { border-radius: .5rem; }
  .br4-l { border-radius: 1rem; }
  .br-100-l { border-radius: 100%; }
  .br-pill-l { border-radius: 9999px; }
  .br--bottom-l { border-top-left-radius: 0; border-top-right-radius: 0; }
  .br--top-l { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  .br--right-l { border-top-left-radius: 0; border-bottom-left-radius: 0; }
  .br--left-l { border-top-right-radius: 0; border-bottom-right-radius: 0; }
  .b--dotted-l { border-style: dotted; }
  .b--dashed-l { border-style: dashed; }
  .b--solid-l { border-style: solid; }
  .b--none-l { border-style: none; }
  .bw0-l { border-width: 0; }
  .bw1-l { border-width: 1px; }
  .bw2-l { border-width: .25rem; }
  .bw3-l { border-width: .5rem; }
  .bw4-l { border-width: 1rem; }
  .bw5-l { border-width: 2rem; }
  .bt-0-l { border-top-width: 0; }
  .br-0-l { border-right-width: 0; }
  .bb-0-l { border-bottom-width: 0; }
  .bl-0-l { border-left-width: 0; }
  .shadow-1-l { box-shadow: 0 0 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-2-l { box-shadow: 0 0 8px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-3-l { box-shadow: 2px 2px 4px 2px rgba( 0, 0, 0, .2 ); }
  .shadow-4-l { box-shadow: 2px 2px 8px 0 rgba( 0, 0, 0, .2 ); }
  .shadow-5-l { box-shadow: 4px 4px 8px 0 rgba( 0, 0, 0, .2 ); }
  .top-0-l { top: 0; }
  .right-0-l { right: 0; }
  .bottom-0-l { bottom: 0; }
  .left-0-l { left: 0; }
  .top-1-l { top: .5rem; }
  .right-1-l { right: .5rem; }
  .bottom-1-l { bottom: .5rem; }
  .left-1-l { left: .5rem; }
  .top-2-l { top: 1rem; }
  .right-2-l { right: 1rem; }
  .bottom-2-l { bottom: 1rem; }
  .left-2-l { left: 1rem; }
  .top-3-l { top: 1.5rem; }
  .right-3-l { right: 1.5rem; }
  .bottom-3-l { bottom: 1.5rem; }
  .left-3-l { left: 1.5rem; }
  .top-4-l { top: 2rem; }
  .right-4-l { right: 2rem; }
  .bottom-4-l { bottom: 2rem; }
  .left-4-l { left: 2rem; }
  .top--1-l { top: -.5rem; }
  .right--1-l { right: -.5rem; }
  .bottom--1-l { bottom: -.5rem; }
  .left--1-l { left: -.5rem; }
  .top--2-l { top: -1rem; }
  .right--2-l { right: -1rem; }
  .bottom--2-l { bottom: -1rem; }
  .left--2-l { left: -1rem; }
  .top--3-l { top: -1.5rem; }
  .right--3-l { right: -1.5rem; }
  .bottom--3-l { bottom: -1.5rem; }
  .left--3-l { left: -1.5rem; }
  .top--4-l { top: -2rem; }
  .right--4-l { right: -2rem; }
  .bottom--4-l { bottom: -2rem; }
  .absolute--fill-l { top: 0; right: 0; bottom: 0; left: 0; }
  .cl-l { clear: left; }
  .cr-l { clear: right; }
  .cb-l { clear: both; }
  .cn-l { clear: none; }
  .dn-l { display: none; }
  .di-l { display: inline; }
  .db-l { display: block; }
  .dib-l { display: inline-block; }
  .dit-l { display: inline-table; }
  .dt-l { display: table; }
  .header-flex { display: flex; }
  .dtc-l { display: table-cell; }
  .dt-row-l { display: table-row; }
  .dt-row-group-l { display: table-row-group; }
  .dt-column-l { display: table-column; }
  .dt-column-group-l { display: table-column-group; }
  .dt--fixed-l { table-layout: fixed; width: 100%; }
  .flex-l { display: flex; }
  .inline-flex-l { display: inline-flex; }
  .flex-auto-l { flex: 1 1 auto; min-width: 0; /* 1 */ min-height: 0; /* 1 */ }
  .flex-09 { flex: 0.9; }
  .flex-none-l { flex: none; }
  .flex-column-l { flex-direction: column; }
  .flex-row-l { flex-direction: row; }
  .flex-wrap-l { flex-wrap: wrap; }
  .flex-nowrap-l { flex-wrap: nowrap; }
  .flex-wrap-reverse-l { flex-wrap: wrap-reverse; }
  .flex-column-reverse-l { flex-direction: column-reverse; }
  .flex-row-reverse-l { flex-direction: row-reverse; }
  .items-start-l { align-items: flex-start; }
  .items-end-l { align-items: flex-end; }
  .items-center-l { align-items: center; }
  .items-baseline-l { align-items: baseline; }
  .items-stretch-l { align-items: stretch; }
  .self-start-l { align-self: flex-start; }
  .self-end-l { align-self: flex-end; }
  .self-center-l { align-self: center; }
  .self-baseline-l { align-self: baseline; }
  .self-stretch-l { align-self: stretch; }
  .justify-start-l { justify-content: flex-start; }
  .justify-end-l { justify-content: flex-end; }
  .justify-center-l { justify-content: center; }
  .justify-between-l { justify-content: space-between; }
  .justify-around-l { justify-content: space-around; }
  .content-start-l { align-content: flex-start; }
  .content-end-l { align-content: flex-end; }
  .content-center-l { align-content: center; }
  .content-between-l { align-content: space-between; }
  .content-around-l { align-content: space-around; }
  .content-stretch-l { align-content: stretch; }
  .order-0-l { order: 0; }
  .order-1-l { order: 1; }
  .order-2-l { order: 2; }
  .order-3-l { order: 3; }
  .order-4-l { order: 4; }
  .order-5-l { order: 5; }
  .order-6-l { order: 6; }
  .order-7-l { order: 7; }
  .order-8-l { order: 8; }
  .order-last-l { order: 99999; }
  .flex-grow-0-l { flex-grow: 0; }
  .flex-grow-1-l { flex-grow: 1; }
  .flex-shrink-0-l { flex-shrink: 0; }
  .flex-shrink-1-l { flex-shrink: 1; }
  .fl-l { float: left; _display: inline; }
  .fr-l { float: right; _display: inline; }
  .fn-l { float: none; }
  .i-l { font-style: italic; }
  .fs-normal-l { font-style: normal; }
  .fw1-l { font-weight: 100; }
  .fw2-l { font-weight: 200; }
  .fw3-l { font-weight: 300; }
  .fw4-l, .normal-l { font-weight: 400; }
  .fw5-l { font-weight: 500; }
  .fw6-l, .semi-bold-l { font-weight: 600; }
  .fw7-l, .bold-l { font-weight: 700; }
  .fw8-l { font-weight: 800; }
  .fw9-l, .bolder-l { font-weight: 900; }
  .h1-l { height: 1rem; }
  .h2-l { height: 1.5rem; }
  .h3-l { height: 2rem; }
  .h4-l { height: 3rem; }
  .h5-l { height: 4rem; }
  .h6-l { height: 6rem; }
  .h7-l { height: 8rem; }
  .h8-l { height: 12rem; }
  .h9-l { height: 16rem; }
  .h10-l { height: 24rem; }
  .h11-l { height: 32rem; }
  .h12-l { height: 48rem; }
  .h13-l { height: 64rem; }
  .h14-l { height: 96rem; }
  .h15-l { height: 128rem; }
  .h-25-l { height: 25%; }
  .h-50-l { height: 50%; }
  .h-60-l { height: 60%; }
  .h-65-l { height: 65%; }
  .h-75-l { height: 75%; }
  .h-80-l { height: 80%; }
  .h-90-l { height: 90%; }
  .h-100-l { height: 100%; }
  .min-h-100-l { min-height: 100%; }
  .vh-25-l { height: 25vh; }
  .vh-50-l { height: 50vh; }
  .vh-75-l { height: 75vh; }
  .vh-100-l { height: 100vh; }
  .min-vh-100-l { min-height: 100vh; }
  .h-auto-l { height: auto; }
  .h-inherit-l { height: inherit; }
  .lh-1-l { line-height: 1; }
  .lh-2-l { line-height: 1.25; }
  .lh-3-l { line-height: 1.5; }
  .lh-4-l { line-height: 2; }
  .w1-l { width: 1rem; }
  .w2-l { width: 1.5rem; }
  .w3-l { width: 2rem; }
  .w4-l { width: 3rem; }
  .w5-l { width: 4rem; }
  .w6-l { width: 6rem; }
  .w7-l { width: 8rem; }
  .w8-l { width: 12rem; }
  .w9-l { width: 16rem; }
  .w10-l { width: 24rem; }
  .w11-l { width: 32rem; }
  .w12-l { width: 48rem; }
  .w13-l { width: 64rem; }
  .w14-l { width: 96rem; }
  .w15-l { width: 128rem; }
  .w-10-l { width: 10%; }
  .w-15-l { width: 15%; }
  .w-20-l { width: 20%; }
  .w-25-l { width: 25%; }
  .w-30-l { width: 30%; }
  .w-35-l { width: 35%; }
  .w-40-l { width: 40%; }
  .w-45-l { width: 45%; }
  .w-48-l { width: 48%; }
  .w-50-l { width: 50%; }
  .w-55-l { width: 55%; }
  .w-60-l { width: 60%; }
  .w-65-l { width: 65%; }
  .w-70-l { width: 70%; }
  .w-75-l { width: 75%; }
  .w-80-l { width: 80%; }
  .w-85-l { width: 85%; }
  .w-90-l { width: 90%; }
  .w-95-l { width: 95%; }
  .w-100-l { width: 100%; }
  .w-third-l { width: 33.33333%; }
  .w-two-thirds-l { width: 66.66667%; }
  .w-auto-l { width: auto; }
  .overflow-visible-l { overflow: visible; }
  .overflow-hidden-l { overflow: hidden; }
  .overflow-scroll-l { overflow: scroll; }
  .overflow-auto-l { overflow: auto; }
  .overflow-x-visible-l { overflow-x: visible; }
  .overflow-x-hidden-l { overflow-x: hidden; }
  .overflow-x-scroll-l { overflow-x: scroll; }
  .overflow-x-auto-l { overflow-x: auto; }
  .overflow-y-visible-l { overflow-y: visible; }
  .overflow-y-hidden-l { overflow-y: hidden; }
  .overflow-y-scroll-l { overflow-y: scroll; }
  .overflow-y-auto-l { overflow-y: auto; }
  .static-l { position: static; }
  .relative-l { position: relative; }
  .absolute-l { position: absolute; }
  .fixed-l { position: fixed; }
  .pa0-l { padding: $spacing-0; }
  .pa1-l { padding: $spacing-1; }
  .pa2-l { padding: $spacing-2; }
  .pa3-l { padding: $spacing-3; }
  .pa4-l { padding: $spacing-4; }
  .pa5-l { padding: $spacing-5; }
  .pa6-l { padding: $spacing-6; }
  .pa7-l { padding: $spacing-7; }
  .pa8-l { padding: $spacing-8; }
  .pa9-l { padding: $spacing-9; }
  .pa10-l { padding: $spacing-10; }
  .pa11-l { padding: $spacing-11; }
  .pa12-l { padding: $spacing-12; }
  .pa13-l { padding: $spacing-13; }
  .pa14-l { padding: $spacing-14; }
  .pa15-l { padding: $spacing-15; }
  .pl0-l { padding-left: $spacing-0; }
  .pl1-l { padding-left: $spacing-1; }
  .pl2-l { padding-left: $spacing-2; }
  .pl3-l { padding-left: $spacing-3; }
  .pl4-l { padding-left: $spacing-4; }
  .pl5-l { padding-left: $spacing-5; }
  .pl6-l { padding-left: $spacing-6; }
  .pl7-l { padding-left: $spacing-7; }
  .pl8-l { padding-left: $spacing-8; }
  .pl9-l { padding-left: $spacing-9; }
  .pl10-l { padding-left: $spacing-10; }
  .pl11-l { padding-left: $spacing-11; }
  .pl12-l { padding-left: $spacing-12; }
  .pl13-l { padding-left: $spacing-13; }
  .pl14-l { padding-left: $spacing-14; }
  .pl15-l { padding-left: $spacing-15; }
  .pr0-l { padding-right: $spacing-0; }
  .pr1-l { padding-right: $spacing-1; }
  .pr2-l { padding-right: $spacing-2; }
  .pr3-l { padding-right: $spacing-3; }
  .pr4-l { padding-right: $spacing-4; }
  .pr5-l { padding-right: $spacing-5; }
  .pr6-l { padding-right: $spacing-6; }
  .pr7-l { padding-right: $spacing-7; }
  .pr8-l { padding-right: $spacing-8; }
  .pr9-l { padding-right: $spacing-9; }
  .pr10-l { padding-right: $spacing-10; }
  .pr11-l { padding-right: $spacing-11; }
  .pr12-l { padding-right: $spacing-12; }
  .pr13-l { padding-right: $spacing-13; }
  .pr14-l { padding-right: $spacing-14; }
  .pr15-l { padding-right: $spacing-15; }
  .pb0-l { padding-bottom: $spacing-0; }
  .pb1-l { padding-bottom: $spacing-1; }
  .pb2-l { padding-bottom: $spacing-2; }
  .pb3-l { padding-bottom: $spacing-3; }
  .pb4-l { padding-bottom: $spacing-4; }
  .pb5-l { padding-bottom: $spacing-5; }
  .pb6-l { padding-bottom: $spacing-6; }
  .pb7-l { padding-bottom: $spacing-7; }
  .pb8-l { padding-bottom: $spacing-8; }
  .pb9-l { padding-bottom: $spacing-9; }
  .pb10-l { padding-bottom: $spacing-10; }
  .pb11-l { padding-bottom: $spacing-11; }
  .pb12-l { padding-bottom: $spacing-12; }
  .pb13-l { padding-bottom: $spacing-13; }
  .pb14-l { padding-bottom: $spacing-14; }
  .pb15-l { padding-bottom: $spacing-15; }
  .pt0-l { padding-top: $spacing-0; }
  .pt1-l { padding-top: $spacing-1; }
  .pt2-l { padding-top: $spacing-2; }
  .pt3-l { padding-top: $spacing-3; }
  .pt4-l { padding-top: $spacing-4; }
  .pt5-l { padding-top: $spacing-5; }
  .pt6-l { padding-top: $spacing-6; }
  .pt7-l { padding-top: $spacing-7; }
  .pt8-l { padding-top: $spacing-8; }
  .pt9-l { padding-top: $spacing-9; }
  .pt10-l { padding-top: $spacing-10; }
  .pt11-l { padding-top: $spacing-11; }
  .pt12-l { padding-top: $spacing-12; }
  .pt13-l { padding-top: $spacing-13; }
  .pt14-l { padding-top: $spacing-14; }
  .pt15-l { padding-top: $spacing-15; }
  .pv0-l { padding-top: $spacing-0; padding-bottom: $spacing-0; }
  .pv1-l { padding-top: $spacing-1; padding-bottom: $spacing-1; }
  .pv2-l { padding-top: $spacing-2; padding-bottom: $spacing-2; }
  .pv3-l { padding-top: $spacing-3; padding-bottom: $spacing-3; }
  .pv4-l { padding-top: $spacing-4; padding-bottom: $spacing-4; }
  .pv5-l { padding-top: $spacing-5; padding-bottom: $spacing-5; }
  .pv6-l { padding-top: $spacing-6; padding-bottom: $spacing-6; }
  .pv7-l { padding-top: $spacing-7; padding-bottom: $spacing-7; }
  .pv8-l { padding-top: $spacing-8; padding-bottom: $spacing-8; }
  .pv9-l { padding-top: $spacing-9; padding-bottom: $spacing-9; }
  .pv10-l { padding-top: $spacing-10; padding-bottom: $spacing-10; }
  .pv11-l { padding-top: $spacing-11; padding-bottom: $spacing-11; }
  .pv12-l { padding-top: $spacing-12; padding-bottom: $spacing-12; }
  .pv13-l { padding-top: $spacing-13; padding-bottom: $spacing-13; }
  .pv14-l { padding-top: $spacing-14; padding-bottom: $spacing-14; }
  .pv15-l { padding-top: $spacing-15; padding-bottom: $spacing-15; }
  .ph0-l { padding-left: $spacing-0; padding-right: $spacing-0; }
  .ph1-l { padding-left: $spacing-1; padding-right: $spacing-1; }
  .ph2-l { padding-left: $spacing-2; padding-right: $spacing-2; }
  .ph3-l { padding-left: $spacing-3; padding-right: $spacing-3; }
  .ph4-l { padding-left: $spacing-4; padding-right: $spacing-4; }
  .ph5-l { padding-left: $spacing-5; padding-right: $spacing-5; }
  .ph6-l { padding-left: $spacing-6; padding-right: $spacing-6; }
  .ph7-l { padding-left: $spacing-7; padding-right: $spacing-7; }
  .ph8-l { padding-left: $spacing-8; padding-right: $spacing-8; }
  .ph9-l { padding-left: $spacing-9; padding-right: $spacing-9; }
  .ph10-l { padding-left: $spacing-10; padding-right: $spacing-10; }
  .ph11-l { padding-left: $spacing-11; padding-right: $spacing-11; }
  .ph12-l { padding-left: $spacing-12; padding-right: $spacing-12; }
  .ph13-l { padding-left: $spacing-13; padding-right: $spacing-13; }
  .ph14-l { padding-left: $spacing-14; padding-right: $spacing-14; }
  .ph15-l { padding-left: $spacing-15; padding-right: $spacing-15; }
  .ma-auto-l { margin: auto; }
  .ma0-l { margin: $spacing-0; }
  .ma1-l { margin: $spacing-1; }
  .ma2-l { margin: $spacing-2; }
  .ma3-l { margin: $spacing-3; }
  .ma4-l { margin: $spacing-4; }
  .ma5-l { margin: $spacing-5; }
  .ma6-l { margin: $spacing-6; }
  .ma7-l { margin: $spacing-7; }
  .ma8-l { margin: $spacing-8; }
  .ma9-l { margin: $spacing-9; }
  .ma10-l { margin: $spacing-10; }
  .ma11-l { margin: $spacing-11; }
  .ma12-l { margin: $spacing-12; }
  .ma13-l { margin: $spacing-13; }
  .ma14-l { margin: $spacing-14; }
  .ma15-l { margin: $spacing-15; }
  .ml-auto-l { margin-left: auto; }
  .ml0-l { margin-left: $spacing-0; }
  .ml1-l { margin-left: $spacing-1; }
  .ml2-l { margin-left: $spacing-2; }
  .ml3-l { margin-left: $spacing-3; }
  .ml4-l { margin-left: $spacing-4; }
  .ml5-l { margin-left: $spacing-5; }
  .ml6-l { margin-left: $spacing-6; }
  .ml7-l { margin-left: $spacing-7; }
  .ml8-l { margin-left: $spacing-8; }
  .ml9-l { margin-left: $spacing-9; }
  .ml10-l { margin-left: $spacing-10; }
  .ml11-l { margin-left: $spacing-11; }
  .ml12-l { margin-left: $spacing-12; }
  .ml13-l { margin-left: $spacing-13; }
  .ml14-l { margin-left: $spacing-14; }
  .ml15-l { margin-left: $spacing-15; }
  .mr-auto-l { margin-right: auto; }
  .mr0-l { margin-right: $spacing-0; }
  .mr1-l { margin-right: $spacing-1; }
  .mr2-l { margin-right: $spacing-2; }
  .mr3-l { margin-right: $spacing-3; }
  .mr4-l { margin-right: $spacing-4; }
  .mr5-l { margin-right: $spacing-5; }
  .mr6-l { margin-right: $spacing-6; }
  .mr7-l { margin-right: $spacing-7; }
  .mr8-l { margin-right: $spacing-8; }
  .mr9-l { margin-right: $spacing-9; }
  .mr10-l { margin-right: $spacing-10; }
  .mr11-l { margin-right: $spacing-11; }
  .mr12-l { margin-right: $spacing-12; }
  .mr13-l { margin-right: $spacing-13; }
  .mr14-l { margin-right: $spacing-14; }
  .mr15-l { margin-right: $spacing-15; }
  .mb-auto-l { margin-bottom: auto; }
  .mb0-l { margin-bottom: $spacing-0; }
  .mb1-l { margin-bottom: $spacing-1; }
  .mb2-l { margin-bottom: $spacing-2; }
  .mb3-l { margin-bottom: $spacing-3; }
  .mb4-l { margin-bottom: $spacing-4; }
  .mb5-l { margin-bottom: $spacing-5; }
  .mb6-l { margin-bottom: $spacing-6; }
  .mb7-l { margin-bottom: $spacing-7; }
  .mb8-l { margin-bottom: $spacing-8; }
  .mb9-l { margin-bottom: $spacing-9; }
  .mb10-l { margin-bottom: $spacing-10; }
  .mb11-l { margin-bottom: $spacing-11; }
  .mb12-l { margin-bottom: $spacing-12; }
  .mb13-l { margin-bottom: $spacing-13; }
  .mb14-l { margin-bottom: $spacing-14; }
  .mb15-l { margin-bottom: $spacing-15; }
  .mt-auto-l { margin-top: auto; }
  .mt0-l { margin-top: $spacing-0; }
  .mt1-l { margin-top: $spacing-1; }
  .mt2-l { margin-top: $spacing-2; }
  .mt3-l { margin-top: $spacing-3; }
  .mt4-l { margin-top: $spacing-4; }
  .mt5-l { margin-top: $spacing-5; }
  .mt6-l { margin-top: $spacing-6; }
  .mt7-l { margin-top: $spacing-7; }
  .mt8-l { margin-top: $spacing-8; }
  .mt9-l { margin-top: $spacing-9; }
  .mt10-l { margin-top: $spacing-10; }
  .mt11-l { margin-top: $spacing-11; }
  .mt12-l { margin-top: $spacing-12; }
  .mt13-l { margin-top: $spacing-13; }
  .mt14-l { margin-top: $spacing-14; }
  .mt15-l { margin-top: $spacing-15; }
  .mv-auto-l { margin-top: auto; margin-bottom: auto; }
  .mv0-l { margin-top: $spacing-0; margin-bottom: $spacing-0; }
  .mv1-l { margin-top: $spacing-1; margin-bottom: $spacing-1; }
  .mv2-l { margin-top: $spacing-2; margin-bottom: $spacing-2; }
  .mv3-l { margin-top: $spacing-3; margin-bottom: $spacing-3; }
  .mv4-l { margin-top: $spacing-4; margin-bottom: $spacing-4; }
  .mv5-l { margin-top: $spacing-5; margin-bottom: $spacing-5; }
  .mv6-l { margin-top: $spacing-6; margin-bottom: $spacing-6; }
  .mv7-l { margin-top: $spacing-7; margin-bottom: $spacing-7; }
  .mv8-l { margin-top: $spacing-8; margin-bottom: $spacing-8; }
  .mv9-l { margin-top: $spacing-9; margin-bottom: $spacing-9; }
  .mv10-l { margin-top: $spacing-10; margin-bottom: $spacing-10; }
  .mv11-l { margin-top: $spacing-11; margin-bottom: $spacing-11; }
  .mv12-l { margin-top: $spacing-12; margin-bottom: $spacing-12; }
  .mv13-l { margin-top: $spacing-13; margin-bottom: $spacing-13; }
  .mv14-l { margin-top: $spacing-14; margin-bottom: $spacing-14; }
  .mv15-l { margin-top: $spacing-15; margin-bottom: $spacing-15; }
  .mh-auto-l { margin-left: auto; margin-right: auto; }
  .mh0-l { margin-left: $spacing-0; margin-right: $spacing-0; }
  .mh1-l { margin-left: $spacing-1; margin-right: $spacing-1; }
  .mh2-l { margin-left: $spacing-2; margin-right: $spacing-2; }
  .mh3-l { margin-left: $spacing-3; margin-right: $spacing-3; }
  .mh4-l { margin-left: $spacing-4; margin-right: $spacing-4; }
  .mh5-l { margin-left: $spacing-5; margin-right: $spacing-5; }
  .mh6-l { margin-left: $spacing-6; margin-right: $spacing-6; }
  .mh7-l { margin-left: $spacing-7; margin-right: $spacing-7; }
  .mh8-l { margin-left: $spacing-8; margin-right: $spacing-8; }
  .mh9-l { margin-left: $spacing-9; margin-right: $spacing-9; }
  .mh10-l { margin-left: $spacing-10; margin-right: $spacing-10; }
  .mh11-l { margin-left: $spacing-11; margin-right: $spacing-11; }
  .mh12-l { margin-left: $spacing-12; margin-right: $spacing-12; }
  .mh13-l { margin-left: $spacing-13; margin-right: $spacing-13; }
  .mh14-l { margin-left: $spacing-14; margin-right: $spacing-14; }
  .mh15-l { margin-left: $spacing-15; margin-right: $spacing-15; }
  .na0-l { margin: -$spacing-0; }
  .na1-l { margin: -$spacing-1; }
  .na2-l { margin: -$spacing-2; }
  .na3-l { margin: -$spacing-3; }
  .na4-l { margin: -$spacing-4; }
  .na5-l { margin: -$spacing-5; }
  .na6-l { margin: -$spacing-6; }
  .na7-l { margin: -$spacing-7; }
  .na8-l { margin: -$spacing-8; }
  .na9-l { margin: -$spacing-9; }
  .na10-l { margin: -$spacing-10; }
  .na11-l { margin: -$spacing-11; }
  .na12-l { margin: -$spacing-12; }
  .na13-l { margin: -$spacing-13; }
  .na14-l { margin: -$spacing-14; }
  .na15-l { margin: -$spacing-15; }
  .nl0-l { margin-left: -$spacing-0; }
  .nl1-l { margin-left: -$spacing-1; }
  .nl2-l { margin-left: -$spacing-2; }
  .nl3-l { margin-left: -$spacing-3; }
  .nl4-l { margin-left: -$spacing-4; }
  .nl5-l { margin-left: -$spacing-5; }
  .nl6-l { margin-left: -$spacing-6; }
  .nl7-l { margin-left: -$spacing-7; }
  .nl8-l { margin-left: -$spacing-8; }
  .nl9-l { margin-left: -$spacing-9; }
  .nl10-l { margin-left: -$spacing-10; }
  .nl11-l { margin-left: -$spacing-11; }
  .nl12-l { margin-left: -$spacing-12; }
  .nl13-l { margin-left: -$spacing-13; }
  .nl14-l { margin-left: -$spacing-14; }
  .nl15-l { margin-left: -$spacing-15; }
  .nr0-l { margin-right: -$spacing-0; }
  .nr1-l { margin-right: -$spacing-1; }
  .nr2-l { margin-right: -$spacing-2; }
  .nr3-l { margin-right: -$spacing-3; }
  .nr4-l { margin-right: -$spacing-4; }
  .nr5-l { margin-right: -$spacing-5; }
  .nr6-l { margin-right: -$spacing-6; }
  .nr7-l { margin-right: -$spacing-7; }
  .nr8-l { margin-right: -$spacing-8; }
  .nr9-l { margin-right: -$spacing-9; }
  .nr10-l { margin-right: -$spacing-10; }
  .nr11-l { margin-right: -$spacing-11; }
  .nr12-l { margin-right: -$spacing-12; }
  .nr13-l { margin-right: -$spacing-13; }
  .nr14-l { margin-right: -$spacing-14; }
  .nr15-l { margin-right: -$spacing-15; }
  .nb0-l { margin-bottom: -$spacing-0; }
  .nb1-l { margin-bottom: -$spacing-1; }
  .nb2-l { margin-bottom: -$spacing-2; }
  .nb3-l { margin-bottom: -$spacing-3; }
  .nb4-l { margin-bottom: -$spacing-4; }
  .nb5-l { margin-bottom: -$spacing-5; }
  .nb6-l { margin-bottom: -$spacing-6; }
  .nb7-l { margin-bottom: -$spacing-7; }
  .nb8-l { margin-bottom: -$spacing-8; }
  .nb9-l { margin-bottom: -$spacing-9; }
  .nb10-l { margin-bottom: -$spacing-10; }
  .nb11-l { margin-bottom: -$spacing-11; }
  .nb12-l { margin-bottom: -$spacing-12; }
  .nb13-l { margin-bottom: -$spacing-13; }
  .nb14-l { margin-bottom: -$spacing-14; }
  .nb15-l { margin-bottom: -$spacing-15; }
  .nt0-l { margin-top: -$spacing-0; }
  .nt1-l { margin-top: -$spacing-1; }
  .nt2-l { margin-top: -$spacing-2; }
  .nt3-l { margin-top: -$spacing-3; }
  .nt4-l { margin-top: -$spacing-4; }
  .nt5-l { margin-top: -$spacing-5; }
  .nt6-l { margin-top: -$spacing-6; }
  .nt7-l { margin-top: -$spacing-7; }
  .nt8-l { margin-top: -$spacing-8; }
  .nt9-l { margin-top: -$spacing-9; }
  .nt10-l { margin-top: -$spacing-10; }
  .nt11-l { margin-top: -$spacing-11; }
  .nt12-l { margin-top: -$spacing-12; }
  .nt13-l { margin-top: -$spacing-13; }
  .nt14-l { margin-top: -$spacing-14; }
  .nt15-l { margin-top: -$spacing-15; }
  .nv0-l { margin-top: -$spacing-0; margin-bottom: -$spacing-0; }
  .nv1-l { margin-top: -$spacing-1; margin-bottom: -$spacing-1; }
  .nv2-l { margin-top: -$spacing-2; margin-bottom: -$spacing-2; }
  .nv3-l { margin-top: -$spacing-3; margin-bottom: -$spacing-3; }
  .nv4-l { margin-top: -$spacing-4; margin-bottom: -$spacing-4; }
  .nv5-l { margin-top: -$spacing-5; margin-bottom: -$spacing-5; }
  .nv6-l { margin-top: -$spacing-6; margin-bottom: -$spacing-6; }
  .nv7-l { margin-top: -$spacing-7; margin-bottom: -$spacing-7; }
  .nv8-l { margin-top: -$spacing-8; margin-bottom: -$spacing-8; }
  .nv9-l { margin-top: -$spacing-9; margin-bottom: -$spacing-9; }
  .nv10-l { margin-top: -$spacing-10; margin-bottom: -$spacing-10; }
  .nv11-l { margin-top: -$spacing-11; margin-bottom: -$spacing-11; }
  .nv12-l { margin-top: -$spacing-12; margin-bottom: -$spacing-12; }
  .nv13-l { margin-top: -$spacing-13; margin-bottom: -$spacing-13; }
  .nv14-l { margin-top: -$spacing-14; margin-bottom: -$spacing-14; }
  .nv15-l { margin-top: -$spacing-15; margin-bottom: -$spacing-15; }
  .nh0-l { margin-left: -$spacing-0; margin-right: -$spacing-0; }
  .nh1-l { margin-left: -$spacing-1; margin-right: -$spacing-1; }
  .nh2-l { margin-left: -$spacing-2; margin-right: -$spacing-2; }
  .nh3-l { margin-left: -$spacing-3; margin-right: -$spacing-3; }
  .nh4-l { margin-left: -$spacing-4; margin-right: -$spacing-4; }
  .nh5-l { margin-left: -$spacing-5; margin-right: -$spacing-5; }
  .nh6-l { margin-left: -$spacing-6; margin-right: -$spacing-6; }
  .nh7-l { margin-left: -$spacing-7; margin-right: -$spacing-7; }
  .nh8-l { margin-left: -$spacing-8; margin-right: -$spacing-8; }
  .nh9-l { margin-left: -$spacing-9; margin-right: -$spacing-9; }
  .nh10-l { margin-left: -$spacing-10; margin-right: -$spacing-10; }
  .nh11-l { margin-left: -$spacing-11; margin-right: -$spacing-11; }
  .nh12-l { margin-left: -$spacing-12; margin-right: -$spacing-12; }
  .nh13-l { margin-left: -$spacing-13; margin-right: -$spacing-13; }
  .nh14-l { margin-left: -$spacing-14; margin-right: -$spacing-14; }
  .nh15-l { margin-left: -$spacing-15; margin-right: -$spacing-15; }
  .strike-l { text-decoration: line-through; }
  .underline-l { text-decoration: underline; }
  .no-underline-l { text-decoration: none; }
  .tl-l { text-align: left; }
  .tr-l { text-align: right; }
  .tc-l { text-align: center; }
  .tj-l { text-align: justify; }
  .ttc-l { text-transform: capitalize; }
  .ttl-l { text-transform: lowercase; }
  .ttu-l { text-transform: uppercase; }
  .ttn-l { text-transform: none; }
  .f1-l, .f-heading-1-l { font-size: $font-size-1; }
  .f2-l, .f-heading-2-l { font-size: $font-size-2; }
  .f3-l, .f-heading-3-l { font-size: $font-size-3; }
  .f4-l, .f-heading-4-l { font-size: $font-size-4; }
  .f5-l, .f-heading-5-l { font-size: $font-size-5; }
  .f6-l, .f-heading-6-l { font-size: $font-size-6; }
  .f7-l, .f-body-large-l { font-size: $font-size-7; }
  .f8-l, .f-body-medium-l { font-size: $font-size-8; }
  .f9-l, .f-body-small-l { font-size: $font-size-9; }
  .f10-l, .f-caption-l { font-size: $font-size-10; }
  .f11-l { font-size: $font-size-11; }
  .f12-l { font-size: $font-size-12; }
  .f13-l { font-size: $font-size-13; }
  .f14-l { font-size: $font-size-14; }
  .f15-l { font-size: $font-size-15; }
  .v-base-l { vertical-align: baseline; }
  .v-mid-l { vertical-align: middle; }
  .v-top-l { vertical-align: top; }
  .v-btm-l { vertical-align: bottom; }
}
