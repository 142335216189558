.chat-pane {
  @extend .h-100, .w-100, .flex, .flex-column, .f9, .pa3;

  .combo-box {
    @extend .bg-white;

    > .options {
      @extend .bg-white;
      height: 4.5rem;
    }
  }

  .chat-container {
    @extend .flex, .flex-column, .nh2, .mt2, .nb3, .flex-grow-1;
  }

  .DraftEditor-root {
    max-width: calc(100% - 50px);
    min-width: calc(100% - 50px);
  }
}