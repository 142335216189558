.project-sort-option {
  @extend .flex, .items-center;

  > div {
    @extend .relative, .z-1, .flex, .items-center, .justify-center;
    height: 24px;
    width: 120px;

    button {
      @extend .button, .bg-none;
    }

    > div:first-child {
      @extend .absolute, .top-0, .br2, .bw1, .b--solid, .b-gray, .bg-white;
      width: 110px;
      min-height: 24px;

      button {
        @extend .w-100, .h-100, .pointer;

        p {
          @extend .normal, .f11, .lh-3, .tc;
        }
      }

      button:not(:last-child) {
        @extend .bw1, .b--solid, .b-gray-20, .bt-0, .bl-0, .br-0;
      }
    }

    > button:last-child {
      @extend .absolute;
      right: 8px;
      top: -1px;
    }
  }
}