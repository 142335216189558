.hero2-root {
  @extend .w-100, .flex, .flex-column, .items-center, .relative, .overflow-hidden;
  font-family: Poppins, sans-serif;

  > video {
    @extend .absolute, .left-0, .right-0, .w-100, .h-100, .fit-cover;
  }

  .hero-content {
    @extend .relative, .w-100, .h-100, .flex, .flex-column, .items-center, .overflow-hidden;

    .eye-viewer-container {
      @extend .w-90, .vh-100, .ma-auto, .relative;
      margin-left: 5%;
      margin-right: 5%;
      max-width: 80rem;

      .viewer-description {
        @extend .absolute, .absolute--fill, .ma-auto, .h-100;

        .animated {
          @extend .h-100;
        }

        > div > div {
          @extend .absolute, .ma-auto, .z-1;
          @extend .left-0-l, .top-0-l, .bottom-0-l;
          @extend .left-0-m, .top-0-m, .right-0-m;
          @extend .left-0-ns, .top-0-ns, .right-0-ns;
        }
      }

      .eye-viewer {
        @extend .absolute, .ma-auto, .h-100;
        @extend .right-0-l, .top-0-l, .bottom-0-l, .w-60-l;
        @extend .absolute--fill-m;
        @extend .absolute--fill-ns;
      }

      &.page-hero {
        @extend .w-100;
        margin-left: unset;
        margin-right: unset;
        max-width: unset;

        .eye-viewer {
          @extend .w-100;
        }

        .viewer-description {
          @extend .pointer-none;
        }
      }

      &.page-main {
        .eye-viewer {
          margin-left: 15%;
          margin-right: -15%;
        }
      }
    }
  }

  .loader {
    @extend .absolute, .absolute--fill, .flex, .items-center, .justify-center, .pointer-none, .z-1;
  }

  .blending-edit {
    @extend .absolute, .absolute--fill, .flex, .flex-column, .pointer-none, .z-1, .justify-between;
    mix-blend-mode: color-burn;

    > div:first-child {
      @extend .flex, .flex-column, .w-100, .items-center;

      @extend .ph7-l, .pv7-l;
      @extend .pa6-m;
      @extend .ph6-ns, .pt4-ns, .pb6-ns;
    }

    > div:nth-child(2) {
      @extend .flex, .flex-column, .items-center, .w-100;

      @extend .pb9-l;
      @extend .pb9-m;
      @extend .pb8-ns;
    }

    p, input {
      color: #444444;
    }

    .expand-button {
      @extend .absolute, .f9, .bold, .pointer-auto;
      @extend .right-4-l, .bottom-4-l;
      @extend .right-2-m, .bottom-0-m;
      @extend .right-2-ns, .bottom-0-ns;
    }
  }

  .edit {
    @extend .absolute, .absolute--fill, .flex, .flex-column, .pointer-none, .z-1, .justify-between;

    > div:first-child {
      @extend .flex, .flex-column, .w-100, .items-center;

      @extend .ph7-l, .pv7-l;
      @extend .pa6-m;
      @extend .ph6-ns, .pt11-ns, .pb6-ns;

      > div:last-child {
        @extend .pt10-l;
        @extend .pt5-m;
        @extend .pt5-ns;

        > p:first-child {
          @extend .semi-bold, .mb5;

          @extend .f4-l;
          @extend .f5-m;
          @extend .f5-ns;
        }

        > p:last-child {
          @extend .f8-l;
          @extend .f9-m;
          @extend .f9-ns;
        }
      }
    }

    > div:nth-child(2) {
      @extend .flex-column, .items-center, .w-100;

      @extend .flex-l, .pb11-l;
      @extend .dn-m;
      @extend .dn-ns;
    }
  }

  .blending-edit-control {
    mix-blend-mode: color-burn;
    @extend .dn-m;
    @extend .dn-ns;

    .top-controls {
      @extend .absolute, .top-4, .ma-auto, .pointer-none, .justify-center, .z-1, .flex;
      left: 4rem;
      right: 4rem;

      > div {
        @extend .mh4, .pointer-auto;
      }
    }

    .left-controls {
      @extend .absolute, .left-4, .ma-auto, .flex-column, .pointer-none, .justify-center, .z-1, .flex;
      top: 4rem;
      bottom: 4rem;

      > div {
        @extend .flex, .items-center, .mv3, .pointer-auto;

        > div:first-child {
          @extend .w6, .flex, .justify-center, .items-center;
        }

        > div:last-child {
          @extend .w3, .flex, .justify-center, .items-center;
        }
      }
    }

    .top-controls, .left-controls {
      .active img {
        filter: invert(1) brightness(0%);
        -webkit-filter: invert(1) brightness(0%);
        -moz-filter: invert(1) brightness(0%);
      }
    }
  }

  .controls {
    @extend .absolute, .left-0, .right-0, .bottom-0, .ma-auto, .pointer-none, .justify-center, .z-1, .mb11;

    @extend .flex, .flex-column;

    font-family: Poppins, sans-serif;
    touch-action: manipulation;

    button {
      @extend .mh2, .f10, .br-pill, .bg-white-t40, .bold, .pointer-auto, .relative, .overflow-hidden;
      width: 3.5rem;
      height: 3.5rem;

      &:before, &:after {
        @extend .absolute, .absolute, .br-pill, .bg-blue;
        width: 3.5rem;
        height: 3.5rem;
        content: '';
        opacity: 0;
        transition: transform 0.75s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
        z-index: -1;
        transform: scale(0.5);
      }

      &:active {
        &:before, &:after {
          opacity: 0.5;
          transition: transform 0.1s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
        }

        &:before {
          transform: scale(0.7);
        }

        &:after {
          transform: scale(1);
        }
      }

      &.active {
        @extend .bg-blue, .white;

        &:before, &:after {
          @extend .bg-white;
        }
      }
    }

    > div {
      @extend .flex, .justify-center, .mv4;
    }
  }

  .action-button {
    @extend .pointer;

    &:hover img {
      filter: invert(1) brightness(0%);
      -webkit-filter: invert(1) brightness(0%);
      -moz-filter: invert(1) brightness(0%);
    }
  }

  .record-controls {
    > div {
      @extend .pointer-auto;
    }

    .action-button {
      img {
        opacity: 0.4;
      }
    }

    .action-button {
      &:hover img {
        opacity: 1;
        filter: unset;
        -webkit-filter: unset;
        -moz-filter: unset;
      }
    }

    .record-active {
      img {
        opacity: 1;
      }
    }
  }

  .email-input-container {
    @extend .relative;

    .email-input {
      @extend .input, .mt2, .black, .b-black-40, .w10, .tc, .semi-bold, .bw2, .f8;

      &::placeholder {
        @extend .black-40;
      }
    }

    img {
      @extend .absolute, .right-0, .top-0, .bottom-0, .ma-auto;
    }
  }

  .hero-input {
    @extend .input, .f8, .mt2, .black, .b-black, .w9, .bw2;

    &::placeholder {
      @extend .black;
    }

    &.error {
      @extend .b-red;

      &::placeholder {
        @extend .red;
      }
    }
  }

  .cheat-code-input {
    @extend .hero-input, .tc, .b-black-40, .w10;

    &::placeholder {
      @extend .black-40;
    }
  }

  .tell-more-input {
    @extend .hero-input, .tc, .b-black-40;

    &::placeholder {
      @extend .black-40;
    }
  }

  .hero-button {
    @extend .focus-outline-0, .pointer, .bg-none, .pointer-auto, .bw1, .b--solid, .b-black, .normal, .pv1, .lh-3, .tc;
    min-width: 5rem;

    @extend .ph4-l, .mh4-l, .br3-l;
    @extend .ph2-m, .mh2-m, .br2-m;
  }

  .join-button {
    @extend .br3, .focus-outline-0, .pointer, .bg-none, .pointer-auto, .bw2, .b--solid, .b-black, .fw5, .pv2, .ph4, .w7;
  }

  .friend-email-input-container {
    @extend .flex, .justify-center, .relative, .pv2;

    @extend .mb5-l;
    @extend .mb5-m;

    .friend-email-input {
      @extend .hero-input, .tl, .w10, .black, .b-black;

      @extend .f8-l, .mh7-l;
      @extend .f9-m, .mh7-m;

      &::placeholder {
        @extend .black-60;
      }
    }

    .send-reminder-button {
      @extend .hero-button, .absolute, .top-0, .bottom-0, .right-0, .b-black, .tc;
      height: 26px;
      font-size: 14px !important;
      padding: 4px 4px !important;
      margin: auto 24px;
      min-width: unset;
    }

    .send-button {
      @extend .hero-button, .absolute, .top-0, .bottom-0, .right-0, .tc;
      height: 26px;
      padding: 4px 4px !important;
      margin: auto 24px;
      border-width: 0 !important;
      min-width: unset;
    }

    .bonus {
      @extend .absolute, .top-0, .bottom-0, .right-0, .ma-auto, .pointer-none, .flex, .items-center;
    }

    .badge-button {
      @extend .button, .bg-none, .absolute, .left-0, .top-0, .bottom-0, .ma-auto, .w2, .h2, .pa0, .flex, .items-center, .justify-center;
    }
  }

  .model-selection-box {
    @extend .dn;
  }

  .refer-friend-form {
    @extend .absolute, .ma-auto, .left-0, .right-0, .flex, .flex-column, .items-center, .justify-center, .bw1, .b-black, .b--solid, .br3, .pa5;
    top: 10%;

    @extend .w-30-l, .h-65-l;
    @extend .w-30-m, .h-65-m;
  }

  .video-replay, .tutorial-video {
    @extend .absolute, .ma-auto, .z-1, .br4, .overflow-hidden, .bg-black, .pointer;

    > video {
      @extend .w-100, .h-100;
    }

    > img {
      @extend .absolute, .absolute--fill, .ma-auto, .z-1;
    }

    .download-button {
      @extend .absolute, .right-2, .bottom-2, .z-1;
    }

    .download-loader {
      @extend .absolute, .absolute--fill, .ma-auto, .pointer-none, .flex, .flex-column, .items-center, .justify-center, .z-1;

      p {
        @extend .f8, .bold, .blue, .mt4;
      }
    }
  }

  .video-replay {
    @extend .left-0, .right-0;
    top: 10%;
    @extend .w-50-l, .h-50-l;
    @extend .w-50-m, .h-50-m;
  }

  .tutorial-video {
    @extend .absolute--fill;
    @extend .w-50-l, .h-50-l;
    @extend .w-50-m, .h-50-m;
  }

  .disabled {
    opacity: 0.4;
  }

  .points {
    @extend .f8, .semi-bold, .bg-gray, .br3, .pv3, .ph5, .flex, .items-center;
  }

  .sns-icons {
    @extend .flex, .justify-center, .items-center, .mb5, .pointer-auto;
  }

  @-webkit-keyframes to-top-right-transform {
    from {
      transform: translate(50vw, 50vh);
    }
    to {
      transform: translate(calc(100vw - 100px), 25px);
    }
  }

  @-moz-keyframes to-top-right-transform {
    from {
      transform: translate(50vw, 50vh);
    }
    to {
      transform: translate(calc(100vw - 100px), 25px);
    }
  }

  @keyframes to-top-right-transform {
    from {
      transform: translate(50vw, 50vh);
    }
    to {
      transform: translate(calc(100vw - 100px), 25px);
    }
  }

  .hero-point-animation {
    @extend .absolute, .f9, .bg-white-t60, .br-pill, .pa2, .z-1;
    -webkit-animation: to-top-right-transform 1.0s ease-out forwards;
    -moz-animation: to-top-right-transform 1.0s ease-out forwards;
    animation: to-top-right-transform 1.0s ease-out forwards;
  }

  .count-down-value {
    @extend .bg-white-t40, .br3, .h3, .w3, .flex, .items-center, .justify-center;
  }

  .count-down-progress-bar {
    @extend .w10;
    background: #44444419;
    height: 10px;

    > div {
      background: #44444460;
      height: 10px;
    }
  }

  .f-1 {
    @extend .f8-l;
    @extend .f9-m;
  }

  .f-2 {
    @extend .f9-l;
    @extend .f10-m;
  }

  .product-hunt {
    @extend .pointer-auto, .flex, .items-center, .justify-center;

    img {
      width: 250px;
      height: 54px;
    }

    @media only screen and (max-width: 30em) {
      img {
        width: 125px;
        height: 27px;
      }
    }
  }

  .product-hunt-small {
    @extend .pointer-none, .z-1, .flex, .items-center, .pa1;

    background: #FFFFFF4C;
    border-radius: 14px;
    font-family: Roboto, sans-serif;

    > a {
      @extend .link, .black, .flex, .pointer-auto, .flex, .items-center;

      @extend .pa1, .f11;

      border: 1px solid #DA552F;
      border-radius: 9px;

      > p {
        @extend .pr2;
      }
    }
  }

  .scroll-area {
    @extend .absolute, .absolute--fill, .pointer-none;

    > div:first-child {
      @extend .absolute, .right-0, .top-0, .bottom-0, .z-1, .w8, .pointer-auto;

      @extend .flex-l;
      @extend .dn-m;
    }

    > div:nth-child(2) {
      @extend .absolute, .right-0, .left-0, .bottom-0, .z-1, .pointer-auto, .flex;

      @extend .h7-l;
      @extend .h7-m;
    }
  }

  .hero2-header {
    @extend .w-100, .ph7, .flex, .absolute, .left-0, .right-0, .ma-auto, .z-1;
  }

  .main-content {
    @extend .flex, .flex-column, .items-center;
    width: 90%;
    max-width: 80rem;
  }

  //.eye-viewer-container {
  //  @extend .flex, .w-100, .relative;
  //  @extend .items-center-l;
  //  @extend .flex-column-m, .flex-column-ns;
  //
  //  > div:first-child {
  //    @extend .flex, .flex-column, .items-start, .self-start, .z-1, .pointer-none;
  //    @extend .vh-100-l, .justify-center-l;
  //    @extend .w-100-m, .w-100-ns, .pt11-m, .pt11-ns, .pb10-m, .pb10-ns, .items-center-m, .items-center-ns, .h-100-m, .h-100-ns, .justify-between-m, .justify-between-ns;
  //
  //    > p {
  //      @extend .tc-m, .tc-ns;
  //    }
  //
  //    > p:nth-child(2) {
  //      @extend .dn-m, .dn-ns;
  //    }
  //  }
  //
  //  > div:nth-child(2) {
  //    @extend .h-80-l, .flex-grow-1-l;
  //    @extend .absolute-m, .w-100-m, .absolute-ns, .w-100-ns;
  //    margin-left: 15%;
  //    margin-right: -15%;
  //
  //    >p:nth-child(2) {
  //      font-family: 'Open Sans', sans-serif;
  //    }
  //  }
  //}

  .trailer-container {
    @extend .flex, .justify-center, .items-center, .relative, .overflow-hidden;
    @extend .w-100-l, .pa10-l;
    @extend .w-90-m, .ph4-m, .pt10-m, .pb4-m, .w-90-ns, .ph4-ns, .pt10-ns, .pb4-ns;

    > div {
      @extend .bw1, .b--solid, .br4, .absolute, .w-100, .h-100;
      background: #B9B9B9;
      mix-blend-mode: color-burn;
      border-color: #2E2E2E;
    }

    > video {
      @extend .br4, .pointer-auto, .z-1;
      max-height: 35rem;
    }
  }

  .access-button {
    @extend .no-underline, .black, .br3, .focus-outline-0, .pointer, .bg-none, .pointer-auto, .bw1, .b--solid, .b-black, .pv2, .ph4;
    font-family: 'Open Sans', sans-serif;
  }

  .feature-cards {
    @extend .flex, .flex-column, .w-100;

    > div {
      @extend .flex, .justify-center;

      > div {
        @extend .flex, .items-center;
        @extend .flex-column-m, .flex-column-ns;
        font-family: 'Open Sans', sans-serif;

        > img {
          min-width: 180px;
          max-width: 180px;
          mix-blend-mode: color-burn;
        }

        > div {
          @extend .ml5-l;
          min-width: 22.5rem;
          max-width: 22.5rem;

          > p:first-child {
            @extend .f5-l, .mb5-l, .bold-l;
            @extend .f7-m, .f7-ns, .tc-m, .tc-ns, .mt4-m, .mt4-ns;
          }

          > p:last-child {
            @extend .f9, .lh-4;
            @extend .dn-m, .dn-ns;
          }
        }
      }

      > div:first-child {
        @extend .mr11-l;
        @extend .mr8-m, .mr8-ns;
      }
    }

    > div:not(:last-child) {
      @extend .mb10-l;
      @extend .mb6-m, .mb6-ns;
    }
  }

  .womp-logo {
    @extend .flex;

    img {
      @extend .pointer-auto, .pointer;
    }

    p {
      @extend .f9, .semi-bold, .ml2;
      font-family: Poppins, sans-serif;
    }
  }
}

.hero-point-icon {
  margin: 0 4px -4px 4px;
}

.hero-tooltip {
  font-family: Poppins, sans-serif !important;
}

@media only screen and (max-width: 60em) {
  .hero2-root {
    .refer-friend-form {
      top: 130px;
      width: 80%;
      height: calc(100% - 260px);
    }

    .hero-content {
      width: 100%;
      max-width: unset;

      .eye-viewer-container {
        &.page-main {
          .eye-viewer {
            left: 0;
            right: 0;
            top: 11rem;
            bottom: 6rem;
            height: unset;
            margin-left: unset;
            margin-right: unset;
          }
        }
      }
    }

    .blending-edit {
      .expand-button {
        img {
          transform: scale(0.5);
        }
      }
    }

    .feature-cards {
      > div {
        > div {
          > img {
            min-width: unset;
            max-width: unset;
            min-height: 5rem;
            max-height: 5rem;
          }

          > div {
            min-width: 10rem;
            max-width: 10rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 60em) and (min-width: 45em) {
  .hero2-root {
    .trailer-container {
      > video {
        max-height: 20rem;
      }
    }
  }
}

@media only screen and (max-width: 45em) {
  .hero2-root {
    .trailer-container {
      > video {
        max-height: 11rem;
      }
    }
  }
}

@media only screen and (max-width: 90em) and (min-width: 60em) {
  .hero2-root {
    .feature-cards {
      > div {
        > div {
          > img {
            min-width: 9rem;
            max-width: 9rem;
          }

          > div {
            margin-left: 1rem;
            min-width: 17.5rem;
            max-width: 17.5rem;

            > p:first-child {
              font-size: 1.25rem;
            }

            > p:last-child {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
