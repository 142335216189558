.sign-in-dialog-overlay {
  @extend .z-999, .absolute, .absolute--fill;
  backdrop-filter: blur(20px);
}

.sign-in-dialog {
  @extend .w-100, .h-100;
}

.sign-in-dialog-content {
  @extend .bg-white, .w-100, .h-100, .shadow-3, .br5, .overflow-hidden, .pa10;

  .form-text-input {
    > div {
      @extend .b-gray;
    }
  }
}

